<template>
   <div class="box">
     <p class="boxTime" :class="[date?'close_Color':'open_Color']"><img src="../assets/images/grayLeft.png" alt="" class="bracketLeft"> TIME: {{ dateDay }} <img src="../assets/images/grayRight.png" alt="" class="bracketRight"></p>
     <p class="boxDate" :class="[date?'close_Color':'open_Color']"><img src="../assets/images/grayLeft.png" alt="" class="bracketLeft"> DATE: {{ dateYear }} <img src="../assets/images/grayRight.png" alt="" class="bracketRight"></p>
   </div>
</template>
 
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      timer: null,
    };
  },
  props:['date'],
  mounted() {
    this.timer = setInterval(() => {
      const date = dayjs(new Date());
      this.dateDay = date.format("HH:mm:ss");
      this.dateYear = date.format("YYYY-MM-DD");
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style scoped>
.box{
   display: flex;
   align-items: center;
}
.boxDay{
   font-size: 24px;
}
.boxTime{
   font-size: 24px;
   margin-right: 25px;
   font-weight: 700;
}
.boxDate{
   font-size: 24px;
   font-weight: 700;

}
.open_Color{
  color: #fff;
}
.close_Color{
  color: #0B484D;
}
.bracketLeft {
  width: 5px;
  height: 36px;
  margin-right: 18px;
  vertical-align: middle;
}
.bracketRight {
  width: 5px;
  height: 36px;
  margin-left: 18px;
  vertical-align: middle;
}
</style>
