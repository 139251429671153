<template>
  <!-- 右下角功能选择模块 -->
  <div class="selection">
    <div class="selectTop">
      <div class="selectTitle">
        <span>百 姓 快 租 可 视 化 运 维 平 台</span>
        <div>
          <img
            src="../assets/images/selectLogo.png"
            alt=""
            style="width: 47px; height: 17px; margin-top: 5px"
          />
        </div>
      </div>
      <div class="progress">
        <!-- 功能进度条 -->
        <div class="progressTop">
          <div class="block">
            <el-slider
              v-model="value3"
              :show-tooltip="true"
              style="width: 496px"
              @change="slider(value3)"
            ></el-slider>
          </div>
        </div>
        <div class="progressBottom">
          <div style="height: 1px"></div>
          <div class="selectBorder"></div>
        </div>
      </div>
    </div>
    <div class="selectBottom">
      <!-- 骑行 -->
      <div class="selectImg position" v-on:click="riding">
        <img
          src="../../src/assets/images/borderLeft.png"
          alt=""
          style="width: 118px; height: 25px; display: inline-block"
        /><span class="selectFont" :class="[ridingActive ? 'free_active' : ' ']"
          >骑行</span
        >
      </div>
      <!-- 闲置 -->
      <div class="selectImg1 position" v-on:click="free">
        <img
          src="../../src/assets/images/border.png"
          alt=""
          style="width: 135px; height: 25px; display: inline-block"
        /><span class="selectFont1" :class="[freeActive ? 'free_active' : ' ']"
          >闲置</span
        >
      </div>
      <!-- 超区 -->
      <div class="selectImg position" v-on:click="malfunction">
        <img
          src="../../src/assets/images/border.png"
          alt=""
          style="width: 118px; height: 25px; display: inline-block"
        /><span
          class="selectFont"
          :class="[malfunctionActive ? 'free_active' : ' ']"
          >异常</span
        >
      </div>
      <!-- 亏电 -->
      <div class="selectImg1 position" v-on:click="feed">
        <img
          src="../../src/assets/images/border.png"
          alt=""
          style="width: 135px; height: 25px; display: inline-block"
        /><span class="selectFont1" :class="[feedActive ? 'free_active' : ' ']"
          >亏电</span
        >
      </div>
      <!-- 站点 -->
      <div class="selectImg position" v-on:click="park">
        <img
          src="../../src/assets/images/border.png"
          alt=""
          style="width: 118px; height: 25px; display: inline-block"
        /><span class="selectFont" :class="[parkActive ? 'free_active' : ' ']"
          >站点</span
        >
      </div>
      <!-- 运维 -->
      <div class="position" v-on:click="operation">
        <img
          src="../../src/assets/images/borderRight.png"
          alt=""
          style="width: 135px; height: 25px; display: inline-block"
        /><span
          class="selectFont1"
          :class="[operationActive ? 'free_active' : ' ']"
          >运维</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      value3: this.msg, //进度条
      value: 0,
      freeFlag: false,
      feedFlag: false,
      ridingActive: false,
      freeActive: false,
      malfunctionActive: false,
      feedActive: false,
      parkActive: false,
      operationActive: false,
    };
  },
  methods: {
    //点击骑行
    riding() {
      this.ridingActive = !this.ridingActive;
      this.freeActive = false;
      this.malfunctionActive = false;
      this.feedActive = false;
      this.operationActive = false;
      this.$emit("ridingChange", this.ridingActive);
      this.$emit("malfunctionChange", this.malfunctionActive);
      this.$emit("feedChange", this.feedActive);
      this.$emit("freeChange", this.freeActive);
      this.$emit("operationChange", this.operationActive);
    },
    // 点击闲置
    free() {
      this.ridingActive = false;
      this.freeActive = !this.freeActive;
      this.malfunctionActive = false;
      this.feedActive = false;
      this.operationActive = false;
      this.freeFlag = true; //是否受进度条控制
      this.$emit("freeChange", this.freeActive);
      this.$emit("ridingChange", this.ridingActive);
      this.$emit("malfunctionChange", this.malfunctionActive);
      this.$emit("feedChange", this.malfunctionActive);
      this.$emit("operationChange", this.operationActive);
    },
    //点击故障
    malfunction() {
      this.ridingActive = false;
      this.freeActive = false;
      this.malfunctionActive = !this.malfunctionActive;
      this.feedActive = false;
      this.operationActive = false;
      this.$emit("malfunctionChange", this.malfunctionActive);
      this.$emit("freeChange", this.freeActive);
      this.$emit("ridingChange", this.ridingActive);
      this.$emit("feedChange", this.feedActive);
      this.$emit("operationChange", this.operationActive);
    },
    //点击亏电
    feed() {
      this.ridingActive = false;
      this.freeActive = false;
      this.malfunctionActive = false;
      this.feedFlag = true;
      this.operationActive = false;
      this.feedActive = !this.feedActive;
      this.$emit("freeChange", this.freeActive);
      this.$emit("ridingChange", this.ridingActive);
      this.$emit("malfunctionChange", this.malfunctionActive);
      this.$emit("feedChange", this.feedActive);
      this.$emit("operationChange", this.operationActive);
    },
    //点击站点
    park() {
      this.parkActive = !this.parkActive;
      this.$emit("parkChange", this.parkActive);
    },
    //点击运维
    operation() {
      this.ridingActive = false;
      this.freeActive = false;
      this.malfunctionActive = false;
      this.feedActive = false;
      this.operationActive = !this.operationActive;
      this.$emit("ridingChange", this.ridingActive);
      this.$emit("freeChange", this.freeActive);
      this.$emit("malfunctionChange", this.malfunctionActive);
      this.$emit("feedChange", this.feedActive);
      this.$emit("operationChange", this.operationActive);
    },
    // 进度条
    slider(value, Num) {
      if (this.freeActive||this.feedActive) {
        if (Num) {
          this.value3 = value;
        }
        if (this.freeFlag) {
          this.$emit("sliderChange", value, "freeFlag", this.freeActive);
        }
        if (this.feedFlag) {
          this.$emit("sliderChange", value, "feedFlag", this.feedActive);
        }
      }else{
        this.value3 = 0;
      }
    },
  },
};
</script>

<style scoped>
.position {
  position: relative;
  cursor: pointer;
}
.free_active {
  color: #f5b946 !important;
  font-weight: 700;
}
.selectTop {
  width: 787px;
  height: 53px;
  display: flex;
}
.selectBottom {
  width: 787px;
  height: 31px;
  padding-top: 6px;
  border: 1px solid #2bb3af;
  background-color: #071f23;
  border-top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.selectImg::after {
  content: "";
  height: 0;
  width: 12px;
  display: inline-block;
  border-top: 2px solid #2bb3af;
  position: absolute;
  top: 13px;
  right: -8px;
}
.selectImg1::after {
  content: "";
  height: 0;
  width: 10px;
  display: inline-block;
  border-top: 2px solid #2bb3af;
  position: absolute;
  top: 13px;
  right: -7px;
}
.selectFont {
  position: absolute;
  top: 6px;
  left: 40px;
  color: #fff;
  font-size: 12px;
}
.selectFont1 {
  position: absolute;
  top: 6px;
  left: 50px;
  color: #fff;
  font-size: 12px;
}
.selectTitle {
  width: 287px;
  height: 32px;
  margin-top: 21px;
  color: #fff;
  border: 1px solid #2bb3af;
  border-right: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #08262d;
  font-size: 13px;
  padding: 0 20px;
}
.progress {
  padding-top: 12px;
  width: 500px;
  height: 38px;
}
.progressTop {
  width: 498px;
  height: 18px;
  border: 1px solid #2bb3af;
  margin-left: 2px;
  background-color: #143943;
}
el-slider__runway {
  width: 496px;
}
.progressBottom {
  width: 500px;
  height: 23px;
  border-right: 1px solid #2bb3af;
  /* border-top: 1px solid #2bb3af; */
  border-bottom: 4px solid #2bb3af;
  background-color: #071f23;
}
.selectBorder {
  width: 490px;
  height: 9px;
  border-top: 1px solid #2bb3af;
  border-right: 1px solid #2bb3af;
  margin-top: 9px;
}
</style>
