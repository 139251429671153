/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const com = $root.com = (() => {

    /**
     * Namespace com.
     * @exports com
     * @namespace
     */
    const com = {};

    com.bxkz = (function() {

        /**
         * Namespace bxkz.
         * @memberof com
         * @namespace
         */
        const bxkz = {};

        bxkz.OuterClass = (function() {

            /**
             * Namespace OuterClass.
             * @memberof com.bxkz
             * @namespace
             */
            const OuterClass = {};

            OuterClass.AbnormalBase = (function() {

                /**
                 * Properties of an AbnormalBase.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalBase
                 * @property {string|null} [userCode] AbnormalBase userCode
                 * @property {string|null} [lonC] AbnormalBase lonC
                 * @property {string|null} [latC] AbnormalBase latC
                 * @property {string|null} [soc] AbnormalBase soc
                 * @property {string|null} [lastUsedDateTime] AbnormalBase lastUsedDateTime
                 * @property {string|null} [posDt] AbnormalBase posDt
                 * @property {string|null} [batDt] AbnormalBase batDt
                 * @property {string|null} [abnormalTime] AbnormalBase abnormalTime
                 * @property {string|null} [lastParkPointName] AbnormalBase lastParkPointName
                 * @property {string|null} [typeStr] AbnormalBase typeStr
                 */

                /**
                 * Constructs a new AbnormalBase.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalBase.
                 * @implements IAbnormalBase
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalBase=} [properties] Properties to set
                 */
                function AbnormalBase(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalBase userCode.
                 * @member {string} userCode
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.userCode = "";

                /**
                 * AbnormalBase lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.lonC = "";

                /**
                 * AbnormalBase latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.latC = "";

                /**
                 * AbnormalBase soc.
                 * @member {string} soc
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.soc = "";

                /**
                 * AbnormalBase lastUsedDateTime.
                 * @member {string} lastUsedDateTime
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.lastUsedDateTime = "";

                /**
                 * AbnormalBase posDt.
                 * @member {string} posDt
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.posDt = "";

                /**
                 * AbnormalBase batDt.
                 * @member {string} batDt
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.batDt = "";

                /**
                 * AbnormalBase abnormalTime.
                 * @member {string} abnormalTime
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.abnormalTime = "";

                /**
                 * AbnormalBase lastParkPointName.
                 * @member {string} lastParkPointName
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.lastParkPointName = "";

                /**
                 * AbnormalBase typeStr.
                 * @member {string} typeStr
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 */
                AbnormalBase.prototype.typeStr = "";

                /**
                 * Creates a new AbnormalBase instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBase=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalBase} AbnormalBase instance
                 */
                AbnormalBase.create = function create(properties) {
                    return new AbnormalBase(properties);
                };

                /**
                 * Encodes the specified AbnormalBase message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalBase.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBase} message AbnormalBase message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalBase.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userCode != null && Object.hasOwnProperty.call(message, "userCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userCode);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.latC);
                    if (message.soc != null && Object.hasOwnProperty.call(message, "soc"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.soc);
                    if (message.lastUsedDateTime != null && Object.hasOwnProperty.call(message, "lastUsedDateTime"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastUsedDateTime);
                    if (message.posDt != null && Object.hasOwnProperty.call(message, "posDt"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.posDt);
                    if (message.batDt != null && Object.hasOwnProperty.call(message, "batDt"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.batDt);
                    if (message.abnormalTime != null && Object.hasOwnProperty.call(message, "abnormalTime"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.abnormalTime);
                    if (message.lastParkPointName != null && Object.hasOwnProperty.call(message, "lastParkPointName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.lastParkPointName);
                    if (message.typeStr != null && Object.hasOwnProperty.call(message, "typeStr"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.typeStr);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalBase message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalBase.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBase} message AbnormalBase message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalBase.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalBase message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalBase} AbnormalBase
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalBase.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalBase();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 3: {
                                message.latC = reader.string();
                                break;
                            }
                        case 4: {
                                message.soc = reader.string();
                                break;
                            }
                        case 5: {
                                message.lastUsedDateTime = reader.string();
                                break;
                            }
                        case 6: {
                                message.posDt = reader.string();
                                break;
                            }
                        case 7: {
                                message.batDt = reader.string();
                                break;
                            }
                        case 8: {
                                message.abnormalTime = reader.string();
                                break;
                            }
                        case 9: {
                                message.lastParkPointName = reader.string();
                                break;
                            }
                        case 10: {
                                message.typeStr = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalBase message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalBase} AbnormalBase
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalBase.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalBase message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalBase.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        if (!$util.isString(message.userCode))
                            return "userCode: string expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        if (!$util.isString(message.soc))
                            return "soc: string expected";
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        if (!$util.isString(message.lastUsedDateTime))
                            return "lastUsedDateTime: string expected";
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        if (!$util.isString(message.posDt))
                            return "posDt: string expected";
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        if (!$util.isString(message.batDt))
                            return "batDt: string expected";
                    if (message.abnormalTime != null && message.hasOwnProperty("abnormalTime"))
                        if (!$util.isString(message.abnormalTime))
                            return "abnormalTime: string expected";
                    if (message.lastParkPointName != null && message.hasOwnProperty("lastParkPointName"))
                        if (!$util.isString(message.lastParkPointName))
                            return "lastParkPointName: string expected";
                    if (message.typeStr != null && message.hasOwnProperty("typeStr"))
                        if (!$util.isString(message.typeStr))
                            return "typeStr: string expected";
                    return null;
                };

                /**
                 * Creates an AbnormalBase message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalBase} AbnormalBase
                 */
                AbnormalBase.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalBase)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalBase();
                    if (object.userCode != null)
                        message.userCode = String(object.userCode);
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    if (object.soc != null)
                        message.soc = String(object.soc);
                    if (object.lastUsedDateTime != null)
                        message.lastUsedDateTime = String(object.lastUsedDateTime);
                    if (object.posDt != null)
                        message.posDt = String(object.posDt);
                    if (object.batDt != null)
                        message.batDt = String(object.batDt);
                    if (object.abnormalTime != null)
                        message.abnormalTime = String(object.abnormalTime);
                    if (object.lastParkPointName != null)
                        message.lastParkPointName = String(object.lastParkPointName);
                    if (object.typeStr != null)
                        message.typeStr = String(object.typeStr);
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalBase message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalBase} message AbnormalBase
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalBase.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userCode = "";
                        object.lonC = "";
                        object.latC = "";
                        object.soc = "";
                        object.lastUsedDateTime = "";
                        object.posDt = "";
                        object.batDt = "";
                        object.abnormalTime = "";
                        object.lastParkPointName = "";
                        object.typeStr = "";
                    }
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        object.userCode = message.userCode;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        object.soc = message.soc;
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        object.lastUsedDateTime = message.lastUsedDateTime;
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        object.posDt = message.posDt;
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        object.batDt = message.batDt;
                    if (message.abnormalTime != null && message.hasOwnProperty("abnormalTime"))
                        object.abnormalTime = message.abnormalTime;
                    if (message.lastParkPointName != null && message.hasOwnProperty("lastParkPointName"))
                        object.lastParkPointName = message.lastParkPointName;
                    if (message.typeStr != null && message.hasOwnProperty("typeStr"))
                        object.typeStr = message.typeStr;
                    return object;
                };

                /**
                 * Converts this AbnormalBase to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalBase.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalBase
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalBase
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalBase.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalBase";
                };

                return AbnormalBase;
            })();

            OuterClass.AbnormalOutOfArea = (function() {

                /**
                 * Properties of an AbnormalOutOfArea.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalOutOfArea
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalOutOfArea base
                 * @property {boolean|null} [borrow] AbnormalOutOfArea borrow
                 */

                /**
                 * Constructs a new AbnormalOutOfArea.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalOutOfArea.
                 * @implements IAbnormalOutOfArea
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalOutOfArea=} [properties] Properties to set
                 */
                function AbnormalOutOfArea(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalOutOfArea base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @instance
                 */
                AbnormalOutOfArea.prototype.base = null;

                /**
                 * AbnormalOutOfArea borrow.
                 * @member {boolean} borrow
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @instance
                 */
                AbnormalOutOfArea.prototype.borrow = false;

                /**
                 * Creates a new AbnormalOutOfArea instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOutOfArea=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalOutOfArea} AbnormalOutOfArea instance
                 */
                AbnormalOutOfArea.create = function create(properties) {
                    return new AbnormalOutOfArea(properties);
                };

                /**
                 * Encodes the specified AbnormalOutOfArea message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalOutOfArea.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOutOfArea} message AbnormalOutOfArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalOutOfArea.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.borrow != null && Object.hasOwnProperty.call(message, "borrow"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.borrow);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalOutOfArea message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalOutOfArea.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOutOfArea} message AbnormalOutOfArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalOutOfArea.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalOutOfArea message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalOutOfArea} AbnormalOutOfArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalOutOfArea.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalOutOfArea();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.borrow = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalOutOfArea message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalOutOfArea} AbnormalOutOfArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalOutOfArea.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalOutOfArea message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalOutOfArea.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    if (message.borrow != null && message.hasOwnProperty("borrow"))
                        if (typeof message.borrow !== "boolean")
                            return "borrow: boolean expected";
                    return null;
                };

                /**
                 * Creates an AbnormalOutOfArea message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalOutOfArea} AbnormalOutOfArea
                 */
                AbnormalOutOfArea.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalOutOfArea)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalOutOfArea();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalOutOfArea.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    if (object.borrow != null)
                        message.borrow = Boolean(object.borrow);
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalOutOfArea message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalOutOfArea} message AbnormalOutOfArea
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalOutOfArea.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.base = null;
                        object.borrow = false;
                    }
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    if (message.borrow != null && message.hasOwnProperty("borrow"))
                        object.borrow = message.borrow;
                    return object;
                };

                /**
                 * Converts this AbnormalOutOfArea to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalOutOfArea.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalOutOfArea
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalOutOfArea
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalOutOfArea.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalOutOfArea";
                };

                return AbnormalOutOfArea;
            })();

            /**
             * FromType enum.
             * @name com.bxkz.OuterClass.FromType
             * @enum {number}
             * @property {number} ADMIN=0 ADMIN value
             * @property {number} USER=1 USER value
             */
            OuterClass.FromType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ADMIN"] = 0;
                values[valuesById[1] = "USER"] = 1;
                return values;
            })();

            OuterClass.AbnormalFault = (function() {

                /**
                 * Properties of an AbnormalFault.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalFault
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalFault base
                 * @property {string|null} [reason] AbnormalFault reason
                 * @property {string|null} [point] AbnormalFault point
                 * @property {string|null} [creator] AbnormalFault creator
                 * @property {com.bxkz.OuterClass.FromType|null} [from] AbnormalFault from
                 */

                /**
                 * Constructs a new AbnormalFault.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalFault.
                 * @implements IAbnormalFault
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalFault=} [properties] Properties to set
                 */
                function AbnormalFault(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalFault base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 */
                AbnormalFault.prototype.base = null;

                /**
                 * AbnormalFault reason.
                 * @member {string} reason
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 */
                AbnormalFault.prototype.reason = "";

                /**
                 * AbnormalFault point.
                 * @member {string} point
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 */
                AbnormalFault.prototype.point = "";

                /**
                 * AbnormalFault creator.
                 * @member {string} creator
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 */
                AbnormalFault.prototype.creator = "";

                /**
                 * AbnormalFault from.
                 * @member {com.bxkz.OuterClass.FromType} from
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 */
                AbnormalFault.prototype.from = 0;

                /**
                 * Creates a new AbnormalFault instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalFault=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalFault} AbnormalFault instance
                 */
                AbnormalFault.create = function create(properties) {
                    return new AbnormalFault(properties);
                };

                /**
                 * Encodes the specified AbnormalFault message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalFault.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalFault} message AbnormalFault message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalFault.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
                    if (message.point != null && Object.hasOwnProperty.call(message, "point"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.point);
                    if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.creator);
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.from);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalFault message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalFault.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalFault} message AbnormalFault message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalFault.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalFault message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalFault} AbnormalFault
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalFault.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalFault();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.reason = reader.string();
                                break;
                            }
                        case 3: {
                                message.point = reader.string();
                                break;
                            }
                        case 4: {
                                message.creator = reader.string();
                                break;
                            }
                        case 5: {
                                message.from = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalFault message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalFault} AbnormalFault
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalFault.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalFault message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalFault.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        if (!$util.isString(message.reason))
                            return "reason: string expected";
                    if (message.point != null && message.hasOwnProperty("point"))
                        if (!$util.isString(message.point))
                            return "point: string expected";
                    if (message.creator != null && message.hasOwnProperty("creator"))
                        if (!$util.isString(message.creator))
                            return "creator: string expected";
                    if (message.from != null && message.hasOwnProperty("from"))
                        switch (message.from) {
                        default:
                            return "from: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates an AbnormalFault message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalFault} AbnormalFault
                 */
                AbnormalFault.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalFault)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalFault();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalFault.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    if (object.reason != null)
                        message.reason = String(object.reason);
                    if (object.point != null)
                        message.point = String(object.point);
                    if (object.creator != null)
                        message.creator = String(object.creator);
                    switch (object.from) {
                    default:
                        if (typeof object.from === "number") {
                            message.from = object.from;
                            break;
                        }
                        break;
                    case "ADMIN":
                    case 0:
                        message.from = 0;
                        break;
                    case "USER":
                    case 1:
                        message.from = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalFault message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalFault} message AbnormalFault
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalFault.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.base = null;
                        object.reason = "";
                        object.point = "";
                        object.creator = "";
                        object.from = options.enums === String ? "ADMIN" : 0;
                    }
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        object.reason = message.reason;
                    if (message.point != null && message.hasOwnProperty("point"))
                        object.point = message.point;
                    if (message.creator != null && message.hasOwnProperty("creator"))
                        object.creator = message.creator;
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = options.enums === String ? $root.com.bxkz.OuterClass.FromType[message.from] === undefined ? message.from : $root.com.bxkz.OuterClass.FromType[message.from] : message.from;
                    return object;
                };

                /**
                 * Converts this AbnormalFault to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalFault.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalFault
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalFault
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalFault.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalFault";
                };

                return AbnormalFault;
            })();

            OuterClass.AbnormalOffSite = (function() {

                /**
                 * Properties of an AbnormalOffSite.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalOffSite
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalOffSite base
                 */

                /**
                 * Constructs a new AbnormalOffSite.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalOffSite.
                 * @implements IAbnormalOffSite
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalOffSite=} [properties] Properties to set
                 */
                function AbnormalOffSite(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalOffSite base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @instance
                 */
                AbnormalOffSite.prototype.base = null;

                /**
                 * Creates a new AbnormalOffSite instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOffSite=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalOffSite} AbnormalOffSite instance
                 */
                AbnormalOffSite.create = function create(properties) {
                    return new AbnormalOffSite(properties);
                };

                /**
                 * Encodes the specified AbnormalOffSite message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalOffSite.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOffSite} message AbnormalOffSite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalOffSite.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalOffSite message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalOffSite.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalOffSite} message AbnormalOffSite message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalOffSite.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalOffSite message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalOffSite} AbnormalOffSite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalOffSite.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalOffSite();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalOffSite message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalOffSite} AbnormalOffSite
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalOffSite.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalOffSite message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalOffSite.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalOffSite message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalOffSite} AbnormalOffSite
                 */
                AbnormalOffSite.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalOffSite)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalOffSite();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalOffSite.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalOffSite message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalOffSite} message AbnormalOffSite
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalOffSite.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.base = null;
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    return object;
                };

                /**
                 * Converts this AbnormalOffSite to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalOffSite.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalOffSite
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalOffSite
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalOffSite.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalOffSite";
                };

                return AbnormalOffSite;
            })();

            OuterClass.AbnormalNoSignal = (function() {

                /**
                 * Properties of an AbnormalNoSignal.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalNoSignal
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalNoSignal base
                 */

                /**
                 * Constructs a new AbnormalNoSignal.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalNoSignal.
                 * @implements IAbnormalNoSignal
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalNoSignal=} [properties] Properties to set
                 */
                function AbnormalNoSignal(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalNoSignal base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @instance
                 */
                AbnormalNoSignal.prototype.base = null;

                /**
                 * Creates a new AbnormalNoSignal instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalNoSignal=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalNoSignal} AbnormalNoSignal instance
                 */
                AbnormalNoSignal.create = function create(properties) {
                    return new AbnormalNoSignal(properties);
                };

                /**
                 * Encodes the specified AbnormalNoSignal message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalNoSignal.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalNoSignal} message AbnormalNoSignal message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalNoSignal.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalNoSignal message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalNoSignal.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalNoSignal} message AbnormalNoSignal message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalNoSignal.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalNoSignal message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalNoSignal} AbnormalNoSignal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalNoSignal.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalNoSignal();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalNoSignal message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalNoSignal} AbnormalNoSignal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalNoSignal.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalNoSignal message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalNoSignal.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalNoSignal message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalNoSignal} AbnormalNoSignal
                 */
                AbnormalNoSignal.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalNoSignal)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalNoSignal();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalNoSignal.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalNoSignal message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalNoSignal} message AbnormalNoSignal
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalNoSignal.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.base = null;
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    return object;
                };

                /**
                 * Converts this AbnormalNoSignal to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalNoSignal.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalNoSignal
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalNoSignal
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalNoSignal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalNoSignal";
                };

                return AbnormalNoSignal;
            })();

            OuterClass.AbnormalBatteryException = (function() {

                /**
                 * Properties of an AbnormalBatteryException.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalBatteryException
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalBatteryException base
                 */

                /**
                 * Constructs a new AbnormalBatteryException.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalBatteryException.
                 * @implements IAbnormalBatteryException
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalBatteryException=} [properties] Properties to set
                 */
                function AbnormalBatteryException(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalBatteryException base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @instance
                 */
                AbnormalBatteryException.prototype.base = null;

                /**
                 * Creates a new AbnormalBatteryException instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBatteryException=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalBatteryException} AbnormalBatteryException instance
                 */
                AbnormalBatteryException.create = function create(properties) {
                    return new AbnormalBatteryException(properties);
                };

                /**
                 * Encodes the specified AbnormalBatteryException message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalBatteryException.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBatteryException} message AbnormalBatteryException message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalBatteryException.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalBatteryException message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalBatteryException.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalBatteryException} message AbnormalBatteryException message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalBatteryException.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalBatteryException message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalBatteryException} AbnormalBatteryException
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalBatteryException.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalBatteryException();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalBatteryException message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalBatteryException} AbnormalBatteryException
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalBatteryException.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalBatteryException message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalBatteryException.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalBatteryException message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalBatteryException} AbnormalBatteryException
                 */
                AbnormalBatteryException.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalBatteryException)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalBatteryException();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalBatteryException.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalBatteryException message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalBatteryException} message AbnormalBatteryException
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalBatteryException.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.base = null;
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    return object;
                };

                /**
                 * Converts this AbnormalBatteryException to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalBatteryException.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalBatteryException
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalBatteryException
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalBatteryException.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalBatteryException";
                };

                return AbnormalBatteryException;
            })();

            OuterClass.AbnormalProhibitArea = (function() {

                /**
                 * Properties of an AbnormalProhibitArea.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalProhibitArea
                 * @property {com.bxkz.OuterClass.IAbnormalBase|null} [base] AbnormalProhibitArea base
                 */

                /**
                 * Constructs a new AbnormalProhibitArea.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalProhibitArea.
                 * @implements IAbnormalProhibitArea
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalProhibitArea=} [properties] Properties to set
                 */
                function AbnormalProhibitArea(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalProhibitArea base.
                 * @member {com.bxkz.OuterClass.IAbnormalBase|null|undefined} base
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @instance
                 */
                AbnormalProhibitArea.prototype.base = null;

                /**
                 * Creates a new AbnormalProhibitArea instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalProhibitArea=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalProhibitArea} AbnormalProhibitArea instance
                 */
                AbnormalProhibitArea.create = function create(properties) {
                    return new AbnormalProhibitArea(properties);
                };

                /**
                 * Encodes the specified AbnormalProhibitArea message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalProhibitArea.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalProhibitArea} message AbnormalProhibitArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalProhibitArea.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.base != null && Object.hasOwnProperty.call(message, "base"))
                        $root.com.bxkz.OuterClass.AbnormalBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalProhibitArea message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalProhibitArea.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalProhibitArea} message AbnormalProhibitArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalProhibitArea.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalProhibitArea message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalProhibitArea} AbnormalProhibitArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalProhibitArea.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalProhibitArea();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.base = $root.com.bxkz.OuterClass.AbnormalBase.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalProhibitArea message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalProhibitArea} AbnormalProhibitArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalProhibitArea.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalProhibitArea message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalProhibitArea.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.base != null && message.hasOwnProperty("base")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalBase.verify(message.base);
                        if (error)
                            return "base." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalProhibitArea message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalProhibitArea} AbnormalProhibitArea
                 */
                AbnormalProhibitArea.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalProhibitArea)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalProhibitArea();
                    if (object.base != null) {
                        if (typeof object.base !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalProhibitArea.base: object expected");
                        message.base = $root.com.bxkz.OuterClass.AbnormalBase.fromObject(object.base);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalProhibitArea message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalProhibitArea} message AbnormalProhibitArea
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalProhibitArea.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.base = null;
                    if (message.base != null && message.hasOwnProperty("base"))
                        object.base = $root.com.bxkz.OuterClass.AbnormalBase.toObject(message.base, options);
                    return object;
                };

                /**
                 * Converts this AbnormalProhibitArea to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalProhibitArea.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalProhibitArea
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalProhibitArea
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalProhibitArea.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalProhibitArea";
                };

                return AbnormalProhibitArea;
            })();

            OuterClass.AbnormalAllFault = (function() {

                /**
                 * Properties of an AbnormalAllFault.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalAllFault
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalOutOfArea>|null} [outOfArea] AbnormalAllFault outOfArea
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalFault>|null} [Fault] AbnormalAllFault Fault
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalOffSite>|null} [OffSite] AbnormalAllFault OffSite
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalNoSignal>|null} [NoSignal] AbnormalAllFault NoSignal
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalBatteryException>|null} [BatteryException] AbnormalAllFault BatteryException
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalProhibitArea>|null} [ProhibitArea] AbnormalAllFault ProhibitArea
                 */

                /**
                 * Constructs a new AbnormalAllFault.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalAllFault.
                 * @implements IAbnormalAllFault
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalAllFault=} [properties] Properties to set
                 */
                function AbnormalAllFault(properties) {
                    this.outOfArea = [];
                    this.Fault = [];
                    this.OffSite = [];
                    this.NoSignal = [];
                    this.BatteryException = [];
                    this.ProhibitArea = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalAllFault outOfArea.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalOutOfArea>} outOfArea
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.outOfArea = $util.emptyArray;

                /**
                 * AbnormalAllFault Fault.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalFault>} Fault
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.Fault = $util.emptyArray;

                /**
                 * AbnormalAllFault OffSite.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalOffSite>} OffSite
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.OffSite = $util.emptyArray;

                /**
                 * AbnormalAllFault NoSignal.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalNoSignal>} NoSignal
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.NoSignal = $util.emptyArray;

                /**
                 * AbnormalAllFault BatteryException.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalBatteryException>} BatteryException
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.BatteryException = $util.emptyArray;

                /**
                 * AbnormalAllFault ProhibitArea.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalProhibitArea>} ProhibitArea
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 */
                AbnormalAllFault.prototype.ProhibitArea = $util.emptyArray;

                /**
                 * Creates a new AbnormalAllFault instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFault=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalAllFault} AbnormalAllFault instance
                 */
                AbnormalAllFault.create = function create(properties) {
                    return new AbnormalAllFault(properties);
                };

                /**
                 * Encodes the specified AbnormalAllFault message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFault.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFault} message AbnormalAllFault message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFault.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.outOfArea != null && message.outOfArea.length)
                        for (let i = 0; i < message.outOfArea.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalOutOfArea.encode(message.outOfArea[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.Fault != null && message.Fault.length)
                        for (let i = 0; i < message.Fault.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalFault.encode(message.Fault[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.OffSite != null && message.OffSite.length)
                        for (let i = 0; i < message.OffSite.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalOffSite.encode(message.OffSite[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.NoSignal != null && message.NoSignal.length)
                        for (let i = 0; i < message.NoSignal.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalNoSignal.encode(message.NoSignal[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.BatteryException != null && message.BatteryException.length)
                        for (let i = 0; i < message.BatteryException.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalBatteryException.encode(message.BatteryException[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.ProhibitArea != null && message.ProhibitArea.length)
                        for (let i = 0; i < message.ProhibitArea.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalProhibitArea.encode(message.ProhibitArea[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalAllFault message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFault.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFault} message AbnormalAllFault message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFault.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalAllFault message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalAllFault} AbnormalAllFault
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFault.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalAllFault();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.outOfArea && message.outOfArea.length))
                                    message.outOfArea = [];
                                message.outOfArea.push($root.com.bxkz.OuterClass.AbnormalOutOfArea.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                if (!(message.Fault && message.Fault.length))
                                    message.Fault = [];
                                message.Fault.push($root.com.bxkz.OuterClass.AbnormalFault.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.OffSite && message.OffSite.length))
                                    message.OffSite = [];
                                message.OffSite.push($root.com.bxkz.OuterClass.AbnormalOffSite.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.NoSignal && message.NoSignal.length))
                                    message.NoSignal = [];
                                message.NoSignal.push($root.com.bxkz.OuterClass.AbnormalNoSignal.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.BatteryException && message.BatteryException.length))
                                    message.BatteryException = [];
                                message.BatteryException.push($root.com.bxkz.OuterClass.AbnormalBatteryException.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                if (!(message.ProhibitArea && message.ProhibitArea.length))
                                    message.ProhibitArea = [];
                                message.ProhibitArea.push($root.com.bxkz.OuterClass.AbnormalProhibitArea.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalAllFault message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalAllFault} AbnormalAllFault
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFault.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalAllFault message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalAllFault.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.outOfArea != null && message.hasOwnProperty("outOfArea")) {
                        if (!Array.isArray(message.outOfArea))
                            return "outOfArea: array expected";
                        for (let i = 0; i < message.outOfArea.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalOutOfArea.verify(message.outOfArea[i]);
                            if (error)
                                return "outOfArea." + error;
                        }
                    }
                    if (message.Fault != null && message.hasOwnProperty("Fault")) {
                        if (!Array.isArray(message.Fault))
                            return "Fault: array expected";
                        for (let i = 0; i < message.Fault.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalFault.verify(message.Fault[i]);
                            if (error)
                                return "Fault." + error;
                        }
                    }
                    if (message.OffSite != null && message.hasOwnProperty("OffSite")) {
                        if (!Array.isArray(message.OffSite))
                            return "OffSite: array expected";
                        for (let i = 0; i < message.OffSite.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalOffSite.verify(message.OffSite[i]);
                            if (error)
                                return "OffSite." + error;
                        }
                    }
                    if (message.NoSignal != null && message.hasOwnProperty("NoSignal")) {
                        if (!Array.isArray(message.NoSignal))
                            return "NoSignal: array expected";
                        for (let i = 0; i < message.NoSignal.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalNoSignal.verify(message.NoSignal[i]);
                            if (error)
                                return "NoSignal." + error;
                        }
                    }
                    if (message.BatteryException != null && message.hasOwnProperty("BatteryException")) {
                        if (!Array.isArray(message.BatteryException))
                            return "BatteryException: array expected";
                        for (let i = 0; i < message.BatteryException.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalBatteryException.verify(message.BatteryException[i]);
                            if (error)
                                return "BatteryException." + error;
                        }
                    }
                    if (message.ProhibitArea != null && message.hasOwnProperty("ProhibitArea")) {
                        if (!Array.isArray(message.ProhibitArea))
                            return "ProhibitArea: array expected";
                        for (let i = 0; i < message.ProhibitArea.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalProhibitArea.verify(message.ProhibitArea[i]);
                            if (error)
                                return "ProhibitArea." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalAllFault message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalAllFault} AbnormalAllFault
                 */
                AbnormalAllFault.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalAllFault)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalAllFault();
                    if (object.outOfArea) {
                        if (!Array.isArray(object.outOfArea))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.outOfArea: array expected");
                        message.outOfArea = [];
                        for (let i = 0; i < object.outOfArea.length; ++i) {
                            if (typeof object.outOfArea[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.outOfArea: object expected");
                            message.outOfArea[i] = $root.com.bxkz.OuterClass.AbnormalOutOfArea.fromObject(object.outOfArea[i]);
                        }
                    }
                    if (object.Fault) {
                        if (!Array.isArray(object.Fault))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.Fault: array expected");
                        message.Fault = [];
                        for (let i = 0; i < object.Fault.length; ++i) {
                            if (typeof object.Fault[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.Fault: object expected");
                            message.Fault[i] = $root.com.bxkz.OuterClass.AbnormalFault.fromObject(object.Fault[i]);
                        }
                    }
                    if (object.OffSite) {
                        if (!Array.isArray(object.OffSite))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.OffSite: array expected");
                        message.OffSite = [];
                        for (let i = 0; i < object.OffSite.length; ++i) {
                            if (typeof object.OffSite[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.OffSite: object expected");
                            message.OffSite[i] = $root.com.bxkz.OuterClass.AbnormalOffSite.fromObject(object.OffSite[i]);
                        }
                    }
                    if (object.NoSignal) {
                        if (!Array.isArray(object.NoSignal))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.NoSignal: array expected");
                        message.NoSignal = [];
                        for (let i = 0; i < object.NoSignal.length; ++i) {
                            if (typeof object.NoSignal[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.NoSignal: object expected");
                            message.NoSignal[i] = $root.com.bxkz.OuterClass.AbnormalNoSignal.fromObject(object.NoSignal[i]);
                        }
                    }
                    if (object.BatteryException) {
                        if (!Array.isArray(object.BatteryException))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.BatteryException: array expected");
                        message.BatteryException = [];
                        for (let i = 0; i < object.BatteryException.length; ++i) {
                            if (typeof object.BatteryException[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.BatteryException: object expected");
                            message.BatteryException[i] = $root.com.bxkz.OuterClass.AbnormalBatteryException.fromObject(object.BatteryException[i]);
                        }
                    }
                    if (object.ProhibitArea) {
                        if (!Array.isArray(object.ProhibitArea))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.ProhibitArea: array expected");
                        message.ProhibitArea = [];
                        for (let i = 0; i < object.ProhibitArea.length; ++i) {
                            if (typeof object.ProhibitArea[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFault.ProhibitArea: object expected");
                            message.ProhibitArea[i] = $root.com.bxkz.OuterClass.AbnormalProhibitArea.fromObject(object.ProhibitArea[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalAllFault message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalAllFault} message AbnormalAllFault
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalAllFault.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.outOfArea = [];
                        object.Fault = [];
                        object.OffSite = [];
                        object.NoSignal = [];
                        object.BatteryException = [];
                        object.ProhibitArea = [];
                    }
                    if (message.outOfArea && message.outOfArea.length) {
                        object.outOfArea = [];
                        for (let j = 0; j < message.outOfArea.length; ++j)
                            object.outOfArea[j] = $root.com.bxkz.OuterClass.AbnormalOutOfArea.toObject(message.outOfArea[j], options);
                    }
                    if (message.Fault && message.Fault.length) {
                        object.Fault = [];
                        for (let j = 0; j < message.Fault.length; ++j)
                            object.Fault[j] = $root.com.bxkz.OuterClass.AbnormalFault.toObject(message.Fault[j], options);
                    }
                    if (message.OffSite && message.OffSite.length) {
                        object.OffSite = [];
                        for (let j = 0; j < message.OffSite.length; ++j)
                            object.OffSite[j] = $root.com.bxkz.OuterClass.AbnormalOffSite.toObject(message.OffSite[j], options);
                    }
                    if (message.NoSignal && message.NoSignal.length) {
                        object.NoSignal = [];
                        for (let j = 0; j < message.NoSignal.length; ++j)
                            object.NoSignal[j] = $root.com.bxkz.OuterClass.AbnormalNoSignal.toObject(message.NoSignal[j], options);
                    }
                    if (message.BatteryException && message.BatteryException.length) {
                        object.BatteryException = [];
                        for (let j = 0; j < message.BatteryException.length; ++j)
                            object.BatteryException[j] = $root.com.bxkz.OuterClass.AbnormalBatteryException.toObject(message.BatteryException[j], options);
                    }
                    if (message.ProhibitArea && message.ProhibitArea.length) {
                        object.ProhibitArea = [];
                        for (let j = 0; j < message.ProhibitArea.length; ++j)
                            object.ProhibitArea[j] = $root.com.bxkz.OuterClass.AbnormalProhibitArea.toObject(message.ProhibitArea[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this AbnormalAllFault to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalAllFault.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalAllFault
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalAllFault
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalAllFault.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalAllFault";
                };

                return AbnormalAllFault;
            })();

            OuterClass.AbnormalAllFaultRealTime = (function() {

                /**
                 * Constructs a new AbnormalAllFaultRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalAllFaultRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AbnormalAllFaultRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AbnormalAllFaultRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AbnormalAllFaultRealTime;

                /**
                 * Creates new AbnormalAllFaultRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {AbnormalAllFaultRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                AbnormalAllFaultRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.AbnormalAllFaultRealTime#service}.
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.AbnormalAllFault} [response] AbnormalAllFault
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.AbnormalAllFaultRealTime.serviceCallback} callback Node-style callback called with the error, if any, and AbnormalAllFault
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AbnormalAllFaultRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.AbnormalAllFault, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.AbnormalAllFault>} Promise
                 * @variation 2
                 */

                return AbnormalAllFaultRealTime;
            })();

            OuterClass.AbnormalAllFaultWithTime = (function() {

                /**
                 * Properties of an AbnormalAllFaultWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalAllFaultWithTime
                 * @property {com.bxkz.OuterClass.IAbnormalAllFault|null} [data] AbnormalAllFaultWithTime data
                 * @property {string|null} [dateTime] AbnormalAllFaultWithTime dateTime
                 */

                /**
                 * Constructs a new AbnormalAllFaultWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalAllFaultWithTime.
                 * @implements IAbnormalAllFaultWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTime=} [properties] Properties to set
                 */
                function AbnormalAllFaultWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalAllFaultWithTime data.
                 * @member {com.bxkz.OuterClass.IAbnormalAllFault|null|undefined} data
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @instance
                 */
                AbnormalAllFaultWithTime.prototype.data = null;

                /**
                 * AbnormalAllFaultWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @instance
                 */
                AbnormalAllFaultWithTime.prototype.dateTime = "";

                /**
                 * Creates a new AbnormalAllFaultWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTime} AbnormalAllFaultWithTime instance
                 */
                AbnormalAllFaultWithTime.create = function create(properties) {
                    return new AbnormalAllFaultWithTime(properties);
                };

                /**
                 * Encodes the specified AbnormalAllFaultWithTime message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFaultWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTime} message AbnormalAllFaultWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFaultWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.AbnormalAllFault.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalAllFaultWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFaultWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTime} message AbnormalAllFaultWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFaultWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalAllFaultWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTime} AbnormalAllFaultWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFaultWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.AbnormalAllFault.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalAllFaultWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTime} AbnormalAllFaultWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFaultWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalAllFaultWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalAllFaultWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalAllFault.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates an AbnormalAllFaultWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTime} AbnormalAllFaultWithTime
                 */
                AbnormalAllFaultWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFaultWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.AbnormalAllFault.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalAllFaultWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalAllFaultWithTime} message AbnormalAllFaultWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalAllFaultWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.AbnormalAllFault.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this AbnormalAllFaultWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalAllFaultWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalAllFaultWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalAllFaultWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalAllFaultWithTime";
                };

                return AbnormalAllFaultWithTime;
            })();

            OuterClass.AbnormalAllFaultWithTimeList = (function() {

                /**
                 * Properties of an AbnormalAllFaultWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalAllFaultWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalAllFaultWithTime>|null} [data] AbnormalAllFaultWithTimeList data
                 */

                /**
                 * Constructs a new AbnormalAllFaultWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalAllFaultWithTimeList.
                 * @implements IAbnormalAllFaultWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTimeList=} [properties] Properties to set
                 */
                function AbnormalAllFaultWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalAllFaultWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalAllFaultWithTime>} data
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @instance
                 */
                AbnormalAllFaultWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new AbnormalAllFaultWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} AbnormalAllFaultWithTimeList instance
                 */
                AbnormalAllFaultWithTimeList.create = function create(properties) {
                    return new AbnormalAllFaultWithTimeList(properties);
                };

                /**
                 * Encodes the specified AbnormalAllFaultWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFaultWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTimeList} message AbnormalAllFaultWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFaultWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalAllFaultWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalAllFaultWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalAllFaultWithTimeList} message AbnormalAllFaultWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalAllFaultWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalAllFaultWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} AbnormalAllFaultWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFaultWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalAllFaultWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.AbnormalAllFaultWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalAllFaultWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} AbnormalAllFaultWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalAllFaultWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalAllFaultWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalAllFaultWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalAllFaultWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} AbnormalAllFaultWithTimeList
                 */
                AbnormalAllFaultWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalAllFaultWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalAllFaultWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalAllFaultWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalAllFaultWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalAllFaultWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} message AbnormalAllFaultWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalAllFaultWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.AbnormalAllFaultWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this AbnormalAllFaultWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalAllFaultWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalAllFaultWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalAllFaultWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalAllFaultWithTimeList";
                };

                return AbnormalAllFaultWithTimeList;
            })();

            OuterClass.AbnormalAllFaultHistory = (function() {

                /**
                 * Constructs a new AbnormalAllFaultHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalAllFaultHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AbnormalAllFaultHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AbnormalAllFaultHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AbnormalAllFaultHistory;

                /**
                 * Creates new AbnormalAllFaultHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {AbnormalAllFaultHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                AbnormalAllFaultHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.AbnormalAllFaultHistory#service}.
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.AbnormalAllFaultWithTimeList} [response] AbnormalAllFaultWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.AbnormalAllFaultHistory.serviceCallback} callback Node-style callback called with the error, if any, and AbnormalAllFaultWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AbnormalAllFaultHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.AbnormalAllFaultWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalAllFaultHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.AbnormalAllFaultWithTimeList>} Promise
                 * @variation 2
                 */

                return AbnormalAllFaultHistory;
            })();

            OuterClass.AbnormalStatisticsItem = (function() {

                /**
                 * Properties of an AbnormalStatisticsItem.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalStatisticsItem
                 * @property {string|null} [name] AbnormalStatisticsItem name
                 * @property {number|null} [num] AbnormalStatisticsItem num
                 */

                /**
                 * Constructs a new AbnormalStatisticsItem.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatisticsItem.
                 * @implements IAbnormalStatisticsItem
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsItem=} [properties] Properties to set
                 */
                function AbnormalStatisticsItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalStatisticsItem name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @instance
                 */
                AbnormalStatisticsItem.prototype.name = "";

                /**
                 * AbnormalStatisticsItem num.
                 * @member {number} num
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @instance
                 */
                AbnormalStatisticsItem.prototype.num = 0;

                /**
                 * Creates a new AbnormalStatisticsItem instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsItem=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsItem} AbnormalStatisticsItem instance
                 */
                AbnormalStatisticsItem.create = function create(properties) {
                    return new AbnormalStatisticsItem(properties);
                };

                /**
                 * Encodes the specified AbnormalStatisticsItem message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsItem.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsItem} message AbnormalStatisticsItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsItem.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.num);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalStatisticsItem message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsItem.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsItem} message AbnormalStatisticsItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsItem.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalStatisticsItem message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsItem} AbnormalStatisticsItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsItem.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalStatisticsItem();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.num = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalStatisticsItem message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsItem} AbnormalStatisticsItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsItem.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalStatisticsItem message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalStatisticsItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.num != null && message.hasOwnProperty("num"))
                        if (!$util.isInteger(message.num))
                            return "num: integer expected";
                    return null;
                };

                /**
                 * Creates an AbnormalStatisticsItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsItem} AbnormalStatisticsItem
                 */
                AbnormalStatisticsItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalStatisticsItem)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalStatisticsItem();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.num != null)
                        message.num = object.num | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalStatisticsItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsItem} message AbnormalStatisticsItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalStatisticsItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.num = 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.num != null && message.hasOwnProperty("num"))
                        object.num = message.num;
                    return object;
                };

                /**
                 * Converts this AbnormalStatisticsItem to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalStatisticsItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalStatisticsItem
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsItem
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalStatisticsItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalStatisticsItem";
                };

                return AbnormalStatisticsItem;
            })();

            OuterClass.AbnormalStatistics = (function() {

                /**
                 * Properties of an AbnormalStatistics.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalStatistics
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalStatisticsItem>|null} [data] AbnormalStatistics data
                 */

                /**
                 * Constructs a new AbnormalStatistics.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatistics.
                 * @implements IAbnormalStatistics
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalStatistics=} [properties] Properties to set
                 */
                function AbnormalStatistics(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalStatistics data.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalStatisticsItem>} data
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @instance
                 */
                AbnormalStatistics.prototype.data = $util.emptyArray;

                /**
                 * Creates a new AbnormalStatistics instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatistics=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalStatistics} AbnormalStatistics instance
                 */
                AbnormalStatistics.create = function create(properties) {
                    return new AbnormalStatistics(properties);
                };

                /**
                 * Encodes the specified AbnormalStatistics message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatistics.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatistics} message AbnormalStatistics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatistics.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalStatisticsItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalStatistics message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatistics.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatistics} message AbnormalStatistics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatistics.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalStatistics message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalStatistics} AbnormalStatistics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatistics.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalStatistics();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.AbnormalStatisticsItem.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalStatistics message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalStatistics} AbnormalStatistics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatistics.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalStatistics message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalStatistics.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalStatisticsItem.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalStatistics message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalStatistics} AbnormalStatistics
                 */
                AbnormalStatistics.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalStatistics)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalStatistics();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalStatistics.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalStatistics.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.AbnormalStatisticsItem.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalStatistics message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalStatistics} message AbnormalStatistics
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalStatistics.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.AbnormalStatisticsItem.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this AbnormalStatistics to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalStatistics.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalStatistics
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalStatistics
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalStatistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalStatistics";
                };

                return AbnormalStatistics;
            })();

            OuterClass.AbnormalStatisticsRealTime = (function() {

                /**
                 * Constructs a new AbnormalStatisticsRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatisticsRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AbnormalStatisticsRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AbnormalStatisticsRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AbnormalStatisticsRealTime;

                /**
                 * Creates new AbnormalStatisticsRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {AbnormalStatisticsRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                AbnormalStatisticsRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.AbnormalStatisticsRealTime#service}.
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.AbnormalStatistics} [response] AbnormalStatistics
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsRealTime.serviceCallback} callback Node-style callback called with the error, if any, and AbnormalStatistics
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AbnormalStatisticsRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.AbnormalStatistics, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.AbnormalStatistics>} Promise
                 * @variation 2
                 */

                return AbnormalStatisticsRealTime;
            })();

            OuterClass.AbnormalStatisticsWithTime = (function() {

                /**
                 * Properties of an AbnormalStatisticsWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalStatisticsWithTime
                 * @property {com.bxkz.OuterClass.IAbnormalStatistics|null} [abnormalStatistics] AbnormalStatisticsWithTime abnormalStatistics
                 * @property {string|null} [dateTime] AbnormalStatisticsWithTime dateTime
                 */

                /**
                 * Constructs a new AbnormalStatisticsWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatisticsWithTime.
                 * @implements IAbnormalStatisticsWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTime=} [properties] Properties to set
                 */
                function AbnormalStatisticsWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalStatisticsWithTime abnormalStatistics.
                 * @member {com.bxkz.OuterClass.IAbnormalStatistics|null|undefined} abnormalStatistics
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @instance
                 */
                AbnormalStatisticsWithTime.prototype.abnormalStatistics = null;

                /**
                 * AbnormalStatisticsWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @instance
                 */
                AbnormalStatisticsWithTime.prototype.dateTime = "";

                /**
                 * Creates a new AbnormalStatisticsWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTime} AbnormalStatisticsWithTime instance
                 */
                AbnormalStatisticsWithTime.create = function create(properties) {
                    return new AbnormalStatisticsWithTime(properties);
                };

                /**
                 * Encodes the specified AbnormalStatisticsWithTime message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTime} message AbnormalStatisticsWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.abnormalStatistics != null && Object.hasOwnProperty.call(message, "abnormalStatistics"))
                        $root.com.bxkz.OuterClass.AbnormalStatistics.encode(message.abnormalStatistics, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalStatisticsWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTime} message AbnormalStatisticsWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalStatisticsWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTime} AbnormalStatisticsWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.abnormalStatistics = $root.com.bxkz.OuterClass.AbnormalStatistics.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalStatisticsWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTime} AbnormalStatisticsWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalStatisticsWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalStatisticsWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.abnormalStatistics != null && message.hasOwnProperty("abnormalStatistics")) {
                        let error = $root.com.bxkz.OuterClass.AbnormalStatistics.verify(message.abnormalStatistics);
                        if (error)
                            return "abnormalStatistics." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates an AbnormalStatisticsWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTime} AbnormalStatisticsWithTime
                 */
                AbnormalStatisticsWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime();
                    if (object.abnormalStatistics != null) {
                        if (typeof object.abnormalStatistics !== "object")
                            throw TypeError(".com.bxkz.OuterClass.AbnormalStatisticsWithTime.abnormalStatistics: object expected");
                        message.abnormalStatistics = $root.com.bxkz.OuterClass.AbnormalStatistics.fromObject(object.abnormalStatistics);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalStatisticsWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsWithTime} message AbnormalStatisticsWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalStatisticsWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.abnormalStatistics = null;
                        object.dateTime = "";
                    }
                    if (message.abnormalStatistics != null && message.hasOwnProperty("abnormalStatistics"))
                        object.abnormalStatistics = $root.com.bxkz.OuterClass.AbnormalStatistics.toObject(message.abnormalStatistics, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this AbnormalStatisticsWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalStatisticsWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalStatisticsWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalStatisticsWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalStatisticsWithTime";
                };

                return AbnormalStatisticsWithTime;
            })();

            OuterClass.AbnormalStatisticsWithTimeList = (function() {

                /**
                 * Properties of an AbnormalStatisticsWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IAbnormalStatisticsWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IAbnormalStatisticsWithTime>|null} [data] AbnormalStatisticsWithTimeList data
                 */

                /**
                 * Constructs a new AbnormalStatisticsWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatisticsWithTimeList.
                 * @implements IAbnormalStatisticsWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTimeList=} [properties] Properties to set
                 */
                function AbnormalStatisticsWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AbnormalStatisticsWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IAbnormalStatisticsWithTime>} data
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @instance
                 */
                AbnormalStatisticsWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new AbnormalStatisticsWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} AbnormalStatisticsWithTimeList instance
                 */
                AbnormalStatisticsWithTimeList.create = function create(properties) {
                    return new AbnormalStatisticsWithTimeList(properties);
                };

                /**
                 * Encodes the specified AbnormalStatisticsWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTimeList} message AbnormalStatisticsWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AbnormalStatisticsWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.AbnormalStatisticsWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IAbnormalStatisticsWithTimeList} message AbnormalStatisticsWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AbnormalStatisticsWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AbnormalStatisticsWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} AbnormalStatisticsWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.AbnormalStatisticsWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.AbnormalStatisticsWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AbnormalStatisticsWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} AbnormalStatisticsWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AbnormalStatisticsWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AbnormalStatisticsWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AbnormalStatisticsWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AbnormalStatisticsWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} AbnormalStatisticsWithTimeList
                 */
                AbnormalStatisticsWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.AbnormalStatisticsWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.AbnormalStatisticsWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.AbnormalStatisticsWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.AbnormalStatisticsWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AbnormalStatisticsWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} message AbnormalStatisticsWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AbnormalStatisticsWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.AbnormalStatisticsWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this AbnormalStatisticsWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AbnormalStatisticsWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AbnormalStatisticsWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AbnormalStatisticsWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.AbnormalStatisticsWithTimeList";
                };

                return AbnormalStatisticsWithTimeList;
            })();

            OuterClass.AbnormalStatisticsWithTimeHistory = (function() {

                /**
                 * Constructs a new AbnormalStatisticsWithTimeHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an AbnormalStatisticsWithTimeHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AbnormalStatisticsWithTimeHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AbnormalStatisticsWithTimeHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AbnormalStatisticsWithTimeHistory;

                /**
                 * Creates new AbnormalStatisticsWithTimeHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {AbnormalStatisticsWithTimeHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                AbnormalStatisticsWithTimeHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory#service}.
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsWithTimeList} [response] AbnormalStatisticsWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory.serviceCallback} callback Node-style callback called with the error, if any, and AbnormalStatisticsWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AbnormalStatisticsWithTimeHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.AbnormalStatisticsWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.AbnormalStatisticsWithTimeHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.AbnormalStatisticsWithTimeList>} Promise
                 * @variation 2
                 */

                return AbnormalStatisticsWithTimeHistory;
            })();

            OuterClass.HistoryParam = (function() {

                /**
                 * Properties of a HistoryParam.
                 * @memberof com.bxkz.OuterClass
                 * @interface IHistoryParam
                 * @property {string|null} [start] HistoryParam start
                 * @property {string|null} [end] HistoryParam end
                 * @property {string|null} [hour] HistoryParam hour
                 * @property {string|null} [minute] HistoryParam minute
                 */

                /**
                 * Constructs a new HistoryParam.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a HistoryParam.
                 * @implements IHistoryParam
                 * @constructor
                 * @param {com.bxkz.OuterClass.IHistoryParam=} [properties] Properties to set
                 */
                function HistoryParam(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * HistoryParam start.
                 * @member {string} start
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @instance
                 */
                HistoryParam.prototype.start = "";

                /**
                 * HistoryParam end.
                 * @member {string} end
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @instance
                 */
                HistoryParam.prototype.end = "";

                /**
                 * HistoryParam hour.
                 * @member {string} hour
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @instance
                 */
                HistoryParam.prototype.hour = "";

                /**
                 * HistoryParam minute.
                 * @member {string} minute
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @instance
                 */
                HistoryParam.prototype.minute = "";

                /**
                 * Creates a new HistoryParam instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {com.bxkz.OuterClass.IHistoryParam=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.HistoryParam} HistoryParam instance
                 */
                HistoryParam.create = function create(properties) {
                    return new HistoryParam(properties);
                };

                /**
                 * Encodes the specified HistoryParam message. Does not implicitly {@link com.bxkz.OuterClass.HistoryParam.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {com.bxkz.OuterClass.IHistoryParam} message HistoryParam message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HistoryParam.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.end);
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified HistoryParam message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.HistoryParam.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {com.bxkz.OuterClass.IHistoryParam} message HistoryParam message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HistoryParam.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a HistoryParam message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.HistoryParam} HistoryParam
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HistoryParam.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.HistoryParam();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.string();
                                break;
                            }
                        case 2: {
                                message.end = reader.string();
                                break;
                            }
                        case 3: {
                                message.hour = reader.string();
                                break;
                            }
                        case 4: {
                                message.minute = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a HistoryParam message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.HistoryParam} HistoryParam
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HistoryParam.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a HistoryParam message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HistoryParam.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isString(message.start))
                            return "start: string expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isString(message.end))
                            return "end: string expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isString(message.hour))
                            return "hour: string expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isString(message.minute))
                            return "minute: string expected";
                    return null;
                };

                /**
                 * Creates a HistoryParam message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.HistoryParam} HistoryParam
                 */
                HistoryParam.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.HistoryParam)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.HistoryParam();
                    if (object.start != null)
                        message.start = String(object.start);
                    if (object.end != null)
                        message.end = String(object.end);
                    if (object.hour != null)
                        message.hour = String(object.hour);
                    if (object.minute != null)
                        message.minute = String(object.minute);
                    return message;
                };

                /**
                 * Creates a plain object from a HistoryParam message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {com.bxkz.OuterClass.HistoryParam} message HistoryParam
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HistoryParam.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = "";
                        object.end = "";
                        object.hour = "";
                        object.minute = "";
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this HistoryParam to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HistoryParam.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for HistoryParam
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.HistoryParam
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                HistoryParam.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.HistoryParam";
                };

                return HistoryParam;
            })();

            OuterClass.MaintenanceWorker = (function() {

                /**
                 * Properties of a MaintenanceWorker.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorker
                 * @property {string|null} [id] MaintenanceWorker id
                 * @property {string|null} [name] MaintenanceWorker name
                 * @property {string|null} [phone] MaintenanceWorker phone
                 * @property {string|null} [clockTime] MaintenanceWorker clockTime
                 * @property {string|null} [state] MaintenanceWorker state
                 * @property {string|null} [lon] MaintenanceWorker lon
                 * @property {string|null} [lat] MaintenanceWorker lat
                 * @property {string|null} [pointTime] MaintenanceWorker pointTime
                 */

                /**
                 * Constructs a new MaintenanceWorker.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 运维基本信息
                 * @implements IMaintenanceWorker
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorker=} [properties] Properties to set
                 */
                function MaintenanceWorker(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorker id.
                 * @member {string} id
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.id = "";

                /**
                 * MaintenanceWorker name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.name = "";

                /**
                 * MaintenanceWorker phone.
                 * @member {string} phone
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.phone = "";

                /**
                 * MaintenanceWorker clockTime.
                 * @member {string} clockTime
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.clockTime = "";

                /**
                 * MaintenanceWorker state.
                 * @member {string} state
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.state = "";

                /**
                 * MaintenanceWorker lon.
                 * @member {string} lon
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.lon = "";

                /**
                 * MaintenanceWorker lat.
                 * @member {string} lat
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.lat = "";

                /**
                 * MaintenanceWorker pointTime.
                 * @member {string} pointTime
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 */
                MaintenanceWorker.prototype.pointTime = "";

                /**
                 * Creates a new MaintenanceWorker instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorker=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorker} MaintenanceWorker instance
                 */
                MaintenanceWorker.create = function create(properties) {
                    return new MaintenanceWorker(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorker message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorker.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorker} message MaintenanceWorker message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorker.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                    if (message.clockTime != null && Object.hasOwnProperty.call(message, "clockTime"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.clockTime);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.state);
                    if (message.lon != null && Object.hasOwnProperty.call(message, "lon"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.lon);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.lat);
                    if (message.pointTime != null && Object.hasOwnProperty.call(message, "pointTime"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.pointTime);
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorker message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorker.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorker} message MaintenanceWorker message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorker.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorker message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorker} MaintenanceWorker
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorker.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorker();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.phone = reader.string();
                                break;
                            }
                        case 4: {
                                message.clockTime = reader.string();
                                break;
                            }
                        case 5: {
                                message.state = reader.string();
                                break;
                            }
                        case 6: {
                                message.lon = reader.string();
                                break;
                            }
                        case 7: {
                                message.lat = reader.string();
                                break;
                            }
                        case 8: {
                                message.pointTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorker message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorker} MaintenanceWorker
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorker.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorker message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorker.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.clockTime != null && message.hasOwnProperty("clockTime"))
                        if (!$util.isString(message.clockTime))
                            return "clockTime: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.lon != null && message.hasOwnProperty("lon"))
                        if (!$util.isString(message.lon))
                            return "lon: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (!$util.isString(message.lat))
                            return "lat: string expected";
                    if (message.pointTime != null && message.hasOwnProperty("pointTime"))
                        if (!$util.isString(message.pointTime))
                            return "pointTime: string expected";
                    return null;
                };

                /**
                 * Creates a MaintenanceWorker message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorker} MaintenanceWorker
                 */
                MaintenanceWorker.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorker)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorker();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.clockTime != null)
                        message.clockTime = String(object.clockTime);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.lon != null)
                        message.lon = String(object.lon);
                    if (object.lat != null)
                        message.lat = String(object.lat);
                    if (object.pointTime != null)
                        message.pointTime = String(object.pointTime);
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorker message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorker} message MaintenanceWorker
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorker.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.name = "";
                        object.phone = "";
                        object.clockTime = "";
                        object.state = "";
                        object.lon = "";
                        object.lat = "";
                        object.pointTime = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.clockTime != null && message.hasOwnProperty("clockTime"))
                        object.clockTime = message.clockTime;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.lon != null && message.hasOwnProperty("lon"))
                        object.lon = message.lon;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = message.lat;
                    if (message.pointTime != null && message.hasOwnProperty("pointTime"))
                        object.pointTime = message.pointTime;
                    return object;
                };

                /**
                 * Converts this MaintenanceWorker to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorker.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorker
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorker
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorker";
                };

                return MaintenanceWorker;
            })();

            OuterClass.MaintenanceWorkerList = (function() {

                /**
                 * Properties of a MaintenanceWorkerList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerList
                 * @property {Array.<com.bxkz.OuterClass.IMaintenanceWorker>|null} [data] MaintenanceWorkerList data
                 */

                /**
                 * Constructs a new MaintenanceWorkerList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerList.
                 * @implements IMaintenanceWorkerList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerList=} [properties] Properties to set
                 */
                function MaintenanceWorkerList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerList data.
                 * @member {Array.<com.bxkz.OuterClass.IMaintenanceWorker>} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @instance
                 */
                MaintenanceWorkerList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkerList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerList} MaintenanceWorkerList instance
                 */
                MaintenanceWorkerList.create = function create(properties) {
                    return new MaintenanceWorkerList(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerList message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerList} message MaintenanceWorkerList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.MaintenanceWorker.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerList} message MaintenanceWorkerList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerList} MaintenanceWorkerList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.MaintenanceWorker.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerList} MaintenanceWorkerList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.MaintenanceWorker.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerList} MaintenanceWorkerList
                 */
                MaintenanceWorkerList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.MaintenanceWorker.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerList} message MaintenanceWorkerList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.MaintenanceWorker.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerList";
                };

                return MaintenanceWorkerList;
            })();

            OuterClass.MaintenanceWorkerRealTime = (function() {

                /**
                 * Constructs a new MaintenanceWorkerRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerRealTime;

                /**
                 * Creates new MaintenanceWorkerRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerRealTime#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerList} [response] MaintenanceWorkerList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerRealTime.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkerList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.MaintenanceWorkerList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkerList>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerRealTime;
            })();

            OuterClass.MaintenanceWorkListWithTime = (function() {

                /**
                 * Properties of a MaintenanceWorkListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkListWithTime
                 * @property {com.bxkz.OuterClass.IMaintenanceWorkerList|null} [data] MaintenanceWorkListWithTime data
                 * @property {string|null} [dateTime] MaintenanceWorkListWithTime dateTime
                 */

                /**
                 * Constructs a new MaintenanceWorkListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkListWithTime.
                 * @implements IMaintenanceWorkListWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTime=} [properties] Properties to set
                 */
                function MaintenanceWorkListWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkListWithTime data.
                 * @member {com.bxkz.OuterClass.IMaintenanceWorkerList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @instance
                 */
                MaintenanceWorkListWithTime.prototype.data = null;

                /**
                 * MaintenanceWorkListWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @instance
                 */
                MaintenanceWorkListWithTime.prototype.dateTime = "";

                /**
                 * Creates a new MaintenanceWorkListWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTime} MaintenanceWorkListWithTime instance
                 */
                MaintenanceWorkListWithTime.create = function create(properties) {
                    return new MaintenanceWorkListWithTime(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkListWithTime message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkListWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTime} message MaintenanceWorkListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkListWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.MaintenanceWorkerList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkListWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkListWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTime} message MaintenanceWorkListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkListWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkListWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTime} MaintenanceWorkListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkListWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.MaintenanceWorkerList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkListWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTime} MaintenanceWorkListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkListWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkListWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkListWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.MaintenanceWorkerList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkListWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTime} MaintenanceWorkListWithTime
                 */
                MaintenanceWorkListWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkListWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.MaintenanceWorkerList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkListWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkListWithTime} message MaintenanceWorkListWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkListWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.MaintenanceWorkerList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkListWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkListWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkListWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkListWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkListWithTime";
                };

                return MaintenanceWorkListWithTime;
            })();

            OuterClass.MaintenanceWorkListWithTimeList = (function() {

                /**
                 * Properties of a MaintenanceWorkListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkListWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IMaintenanceWorkListWithTime>|null} [data] MaintenanceWorkListWithTimeList data
                 */

                /**
                 * Constructs a new MaintenanceWorkListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkListWithTimeList.
                 * @implements IMaintenanceWorkListWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTimeList=} [properties] Properties to set
                 */
                function MaintenanceWorkListWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkListWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IMaintenanceWorkListWithTime>} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @instance
                 */
                MaintenanceWorkListWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkListWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} MaintenanceWorkListWithTimeList instance
                 */
                MaintenanceWorkListWithTimeList.create = function create(properties) {
                    return new MaintenanceWorkListWithTimeList(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkListWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkListWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTimeList} message MaintenanceWorkListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkListWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkListWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkListWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkListWithTimeList} message MaintenanceWorkListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkListWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkListWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} MaintenanceWorkListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkListWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkListWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.MaintenanceWorkListWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkListWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} MaintenanceWorkListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkListWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkListWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkListWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkListWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} MaintenanceWorkListWithTimeList
                 */
                MaintenanceWorkListWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkListWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkListWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkListWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkListWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkListWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} message MaintenanceWorkListWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkListWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.MaintenanceWorkListWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkListWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkListWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkListWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkListWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkListWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkListWithTimeList";
                };

                return MaintenanceWorkListWithTimeList;
            })();

            OuterClass.MaintenanceWorkerHistory = (function() {

                /**
                 * Constructs a new MaintenanceWorkerHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerHistory;

                /**
                 * Creates new MaintenanceWorkerHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerHistory#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkListWithTimeList} [response] MaintenanceWorkListWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerHistory.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkListWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.MaintenanceWorkListWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkListWithTimeList>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerHistory;
            })();

            OuterClass.MaintenanceWorkerStatistics = (function() {

                /**
                 * Properties of a MaintenanceWorkerStatistics.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerStatistics
                 * @property {number|null} [powerChange] MaintenanceWorkerStatistics powerChange
                 * @property {number|null} [dispatch] MaintenanceWorkerStatistics dispatch
                 * @property {number|null} [move] MaintenanceWorkerStatistics move
                 */

                /**
                 * Constructs a new MaintenanceWorkerStatistics.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerStatistics.
                 * @implements IMaintenanceWorkerStatistics
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatistics=} [properties] Properties to set
                 */
                function MaintenanceWorkerStatistics(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerStatistics powerChange.
                 * @member {number} powerChange
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @instance
                 */
                MaintenanceWorkerStatistics.prototype.powerChange = 0;

                /**
                 * MaintenanceWorkerStatistics dispatch.
                 * @member {number} dispatch
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @instance
                 */
                MaintenanceWorkerStatistics.prototype.dispatch = 0;

                /**
                 * MaintenanceWorkerStatistics move.
                 * @member {number} move
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @instance
                 */
                MaintenanceWorkerStatistics.prototype.move = 0;

                /**
                 * Creates a new MaintenanceWorkerStatistics instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatistics=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatistics} MaintenanceWorkerStatistics instance
                 */
                MaintenanceWorkerStatistics.create = function create(properties) {
                    return new MaintenanceWorkerStatistics(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatistics message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatistics.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatistics} message MaintenanceWorkerStatistics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatistics.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.powerChange != null && Object.hasOwnProperty.call(message, "powerChange"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.powerChange);
                    if (message.dispatch != null && Object.hasOwnProperty.call(message, "dispatch"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dispatch);
                    if (message.move != null && Object.hasOwnProperty.call(message, "move"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.move);
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatistics message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatistics.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatistics} message MaintenanceWorkerStatistics message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatistics.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerStatistics message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatistics} MaintenanceWorkerStatistics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatistics.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.powerChange = reader.int32();
                                break;
                            }
                        case 2: {
                                message.dispatch = reader.int32();
                                break;
                            }
                        case 3: {
                                message.move = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerStatistics message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatistics} MaintenanceWorkerStatistics
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatistics.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerStatistics message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerStatistics.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.powerChange != null && message.hasOwnProperty("powerChange"))
                        if (!$util.isInteger(message.powerChange))
                            return "powerChange: integer expected";
                    if (message.dispatch != null && message.hasOwnProperty("dispatch"))
                        if (!$util.isInteger(message.dispatch))
                            return "dispatch: integer expected";
                    if (message.move != null && message.hasOwnProperty("move"))
                        if (!$util.isInteger(message.move))
                            return "move: integer expected";
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerStatistics message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatistics} MaintenanceWorkerStatistics
                 */
                MaintenanceWorkerStatistics.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics();
                    if (object.powerChange != null)
                        message.powerChange = object.powerChange | 0;
                    if (object.dispatch != null)
                        message.dispatch = object.dispatch | 0;
                    if (object.move != null)
                        message.move = object.move | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerStatistics message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatistics} message MaintenanceWorkerStatistics
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerStatistics.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.powerChange = 0;
                        object.dispatch = 0;
                        object.move = 0;
                    }
                    if (message.powerChange != null && message.hasOwnProperty("powerChange"))
                        object.powerChange = message.powerChange;
                    if (message.dispatch != null && message.hasOwnProperty("dispatch"))
                        object.dispatch = message.dispatch;
                    if (message.move != null && message.hasOwnProperty("move"))
                        object.move = message.move;
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerStatistics to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerStatistics.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerStatistics
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatistics
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerStatistics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerStatistics";
                };

                return MaintenanceWorkerStatistics;
            })();

            OuterClass.MaintenanceWorkerStatisticsRealTime = (function() {

                /**
                 * Constructs a new MaintenanceWorkerStatisticsRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerStatisticsRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerStatisticsRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerStatisticsRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerStatisticsRealTime;

                /**
                 * Creates new MaintenanceWorkerStatisticsRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerStatisticsRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerStatisticsRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatistics} [response] MaintenanceWorkerStatistics
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkerStatistics
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerStatisticsRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkerStatistics>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerStatisticsRealTime;
            })();

            OuterClass.MaintenanceWorkerStatisticsWithTime = (function() {

                /**
                 * Properties of a MaintenanceWorkerStatisticsWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerStatisticsWithTime
                 * @property {com.bxkz.OuterClass.IMaintenanceWorkerStatistics|null} [data] MaintenanceWorkerStatisticsWithTime data
                 * @property {string|null} [dateTime] MaintenanceWorkerStatisticsWithTime dateTime
                 */

                /**
                 * Constructs a new MaintenanceWorkerStatisticsWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerStatisticsWithTime.
                 * @implements IMaintenanceWorkerStatisticsWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime=} [properties] Properties to set
                 */
                function MaintenanceWorkerStatisticsWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerStatisticsWithTime data.
                 * @member {com.bxkz.OuterClass.IMaintenanceWorkerStatistics|null|undefined} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @instance
                 */
                MaintenanceWorkerStatisticsWithTime.prototype.data = null;

                /**
                 * MaintenanceWorkerStatisticsWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @instance
                 */
                MaintenanceWorkerStatisticsWithTime.prototype.dateTime = "";

                /**
                 * Creates a new MaintenanceWorkerStatisticsWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime} MaintenanceWorkerStatisticsWithTime instance
                 */
                MaintenanceWorkerStatisticsWithTime.create = function create(properties) {
                    return new MaintenanceWorkerStatisticsWithTime(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatisticsWithTime message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime} message MaintenanceWorkerStatisticsWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatisticsWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatisticsWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime} message MaintenanceWorkerStatisticsWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatisticsWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerStatisticsWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime} MaintenanceWorkerStatisticsWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatisticsWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerStatisticsWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime} MaintenanceWorkerStatisticsWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatisticsWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerStatisticsWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerStatisticsWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerStatisticsWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime} MaintenanceWorkerStatisticsWithTime
                 */
                MaintenanceWorkerStatisticsWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerStatisticsWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime} message MaintenanceWorkerStatisticsWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerStatisticsWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.MaintenanceWorkerStatistics.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerStatisticsWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerStatisticsWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerStatisticsWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerStatisticsWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime";
                };

                return MaintenanceWorkerStatisticsWithTime;
            })();

            OuterClass.MaintenanceWorkerStatisticsWithTimeList = (function() {

                /**
                 * Properties of a MaintenanceWorkerStatisticsWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerStatisticsWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime>|null} [data] MaintenanceWorkerStatisticsWithTimeList data
                 */

                /**
                 * Constructs a new MaintenanceWorkerStatisticsWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerStatisticsWithTimeList.
                 * @implements IMaintenanceWorkerStatisticsWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTimeList=} [properties] Properties to set
                 */
                function MaintenanceWorkerStatisticsWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerStatisticsWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTime>} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @instance
                 */
                MaintenanceWorkerStatisticsWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkerStatisticsWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} MaintenanceWorkerStatisticsWithTimeList instance
                 */
                MaintenanceWorkerStatisticsWithTimeList.create = function create(properties) {
                    return new MaintenanceWorkerStatisticsWithTimeList(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatisticsWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTimeList} message MaintenanceWorkerStatisticsWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatisticsWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerStatisticsWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerStatisticsWithTimeList} message MaintenanceWorkerStatisticsWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerStatisticsWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerStatisticsWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} MaintenanceWorkerStatisticsWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatisticsWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerStatisticsWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} MaintenanceWorkerStatisticsWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerStatisticsWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerStatisticsWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerStatisticsWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerStatisticsWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} MaintenanceWorkerStatisticsWithTimeList
                 */
                MaintenanceWorkerStatisticsWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerStatisticsWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} message MaintenanceWorkerStatisticsWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerStatisticsWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerStatisticsWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerStatisticsWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerStatisticsWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerStatisticsWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList";
                };

                return MaintenanceWorkerStatisticsWithTimeList;
            })();

            OuterClass.MaintenanceWorkerStatisticsHistory = (function() {

                /**
                 * Constructs a new MaintenanceWorkerStatisticsHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerStatisticsHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerStatisticsHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerStatisticsHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerStatisticsHistory;

                /**
                 * Creates new MaintenanceWorkerStatisticsHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerStatisticsHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerStatisticsHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList} [response] MaintenanceWorkerStatisticsWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkerStatisticsWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerStatisticsHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerStatisticsHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkerStatisticsWithTimeList>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerStatisticsHistory;
            })();

            /**
             * TrackType enum.
             * @name com.bxkz.OuterClass.TrackType
             * @enum {number}
             * @property {number} ClockIn=0 ClockIn value
             * @property {number} ClockOut=1 ClockOut value
             * @property {number} Position=2 Position value
             * @property {number} DispatchIn=3 DispatchIn value
             * @property {number} DispatchOut=4 DispatchOut value
             * @property {number} Move=5 Move value
             * @property {number} PowerChange=6 PowerChange value
             */
            OuterClass.TrackType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ClockIn"] = 0;
                values[valuesById[1] = "ClockOut"] = 1;
                values[valuesById[2] = "Position"] = 2;
                values[valuesById[3] = "DispatchIn"] = 3;
                values[valuesById[4] = "DispatchOut"] = 4;
                values[valuesById[5] = "Move"] = 5;
                values[valuesById[6] = "PowerChange"] = 6;
                return values;
            })();

            OuterClass.TrackInfo = (function() {

                /**
                 * Properties of a TrackInfo.
                 * @memberof com.bxkz.OuterClass
                 * @interface ITrackInfo
                 * @property {string|null} [userCode] TrackInfo userCode
                 * @property {boolean|null} [isValid] TrackInfo isValid
                 * @property {string|null} [powerChangeTime] TrackInfo powerChangeTime
                 * @property {number|null} [powerOldSoc] TrackInfo powerOldSoc
                 * @property {number|null} [powerNewSoc] TrackInfo powerNewSoc
                 * @property {string|null} [powerRemark] TrackInfo powerRemark
                 * @property {string|null} [powerLon] TrackInfo powerLon
                 * @property {string|null} [powerLat] TrackInfo powerLat
                 * @property {string|null} [moveParkPointName] TrackInfo moveParkPointName
                 * @property {string|null} [moveStartTime] TrackInfo moveStartTime
                 * @property {string|null} [moveEndTime] TrackInfo moveEndTime
                 * @property {string|null} [moveStartLon] TrackInfo moveStartLon
                 * @property {string|null} [moveStartLat] TrackInfo moveStartLat
                 * @property {string|null} [moveEndLon] TrackInfo moveEndLon
                 * @property {string|null} [moveEndLat] TrackInfo moveEndLat
                 * @property {string|null} [dispatchDispatchTime] TrackInfo dispatchDispatchTime
                 * @property {string|null} [dispatchFinishTime] TrackInfo dispatchFinishTime
                 * @property {string|null} [dispatchOldParkPoint] TrackInfo dispatchOldParkPoint
                 * @property {string|null} [dispatchNewParkPoint] TrackInfo dispatchNewParkPoint
                 * @property {number|null} [dispatchState] TrackInfo dispatchState
                 * @property {string|null} [dispatchStartLonC] TrackInfo dispatchStartLonC
                 * @property {string|null} [dispatchStartLatC] TrackInfo dispatchStartLatC
                 * @property {string|null} [dispatchEndLonC] TrackInfo dispatchEndLonC
                 * @property {string|null} [dispatchEndLatC] TrackInfo dispatchEndLatC
                 */

                /**
                 * Constructs a new TrackInfo.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a TrackInfo.
                 * @implements ITrackInfo
                 * @constructor
                 * @param {com.bxkz.OuterClass.ITrackInfo=} [properties] Properties to set
                 */
                function TrackInfo(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TrackInfo userCode.
                 * @member {string} userCode
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.userCode = "";

                /**
                 * TrackInfo isValid.
                 * @member {boolean} isValid
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.isValid = false;

                /**
                 * TrackInfo powerChangeTime.
                 * @member {string} powerChangeTime
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerChangeTime = "";

                /**
                 * TrackInfo powerOldSoc.
                 * @member {number} powerOldSoc
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerOldSoc = 0;

                /**
                 * TrackInfo powerNewSoc.
                 * @member {number} powerNewSoc
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerNewSoc = 0;

                /**
                 * TrackInfo powerRemark.
                 * @member {string} powerRemark
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerRemark = "";

                /**
                 * TrackInfo powerLon.
                 * @member {string} powerLon
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerLon = "";

                /**
                 * TrackInfo powerLat.
                 * @member {string} powerLat
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.powerLat = "";

                /**
                 * TrackInfo moveParkPointName.
                 * @member {string} moveParkPointName
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveParkPointName = "";

                /**
                 * TrackInfo moveStartTime.
                 * @member {string} moveStartTime
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveStartTime = "";

                /**
                 * TrackInfo moveEndTime.
                 * @member {string} moveEndTime
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveEndTime = "";

                /**
                 * TrackInfo moveStartLon.
                 * @member {string} moveStartLon
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveStartLon = "";

                /**
                 * TrackInfo moveStartLat.
                 * @member {string} moveStartLat
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveStartLat = "";

                /**
                 * TrackInfo moveEndLon.
                 * @member {string} moveEndLon
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveEndLon = "";

                /**
                 * TrackInfo moveEndLat.
                 * @member {string} moveEndLat
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.moveEndLat = "";

                /**
                 * TrackInfo dispatchDispatchTime.
                 * @member {string} dispatchDispatchTime
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchDispatchTime = "";

                /**
                 * TrackInfo dispatchFinishTime.
                 * @member {string} dispatchFinishTime
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchFinishTime = "";

                /**
                 * TrackInfo dispatchOldParkPoint.
                 * @member {string} dispatchOldParkPoint
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchOldParkPoint = "";

                /**
                 * TrackInfo dispatchNewParkPoint.
                 * @member {string} dispatchNewParkPoint
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchNewParkPoint = "";

                /**
                 * TrackInfo dispatchState.
                 * @member {number} dispatchState
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchState = 0;

                /**
                 * TrackInfo dispatchStartLonC.
                 * @member {string} dispatchStartLonC
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchStartLonC = "";

                /**
                 * TrackInfo dispatchStartLatC.
                 * @member {string} dispatchStartLatC
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchStartLatC = "";

                /**
                 * TrackInfo dispatchEndLonC.
                 * @member {string} dispatchEndLonC
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchEndLonC = "";

                /**
                 * TrackInfo dispatchEndLatC.
                 * @member {string} dispatchEndLatC
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 */
                TrackInfo.prototype.dispatchEndLatC = "";

                /**
                 * Creates a new TrackInfo instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {com.bxkz.OuterClass.ITrackInfo=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.TrackInfo} TrackInfo instance
                 */
                TrackInfo.create = function create(properties) {
                    return new TrackInfo(properties);
                };

                /**
                 * Encodes the specified TrackInfo message. Does not implicitly {@link com.bxkz.OuterClass.TrackInfo.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {com.bxkz.OuterClass.ITrackInfo} message TrackInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TrackInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userCode != null && Object.hasOwnProperty.call(message, "userCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userCode);
                    if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isValid);
                    if (message.powerChangeTime != null && Object.hasOwnProperty.call(message, "powerChangeTime"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.powerChangeTime);
                    if (message.powerOldSoc != null && Object.hasOwnProperty.call(message, "powerOldSoc"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.powerOldSoc);
                    if (message.powerNewSoc != null && Object.hasOwnProperty.call(message, "powerNewSoc"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.powerNewSoc);
                    if (message.powerRemark != null && Object.hasOwnProperty.call(message, "powerRemark"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.powerRemark);
                    if (message.powerLon != null && Object.hasOwnProperty.call(message, "powerLon"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.powerLon);
                    if (message.powerLat != null && Object.hasOwnProperty.call(message, "powerLat"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.powerLat);
                    if (message.moveParkPointName != null && Object.hasOwnProperty.call(message, "moveParkPointName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.moveParkPointName);
                    if (message.moveStartTime != null && Object.hasOwnProperty.call(message, "moveStartTime"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.moveStartTime);
                    if (message.moveEndTime != null && Object.hasOwnProperty.call(message, "moveEndTime"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.moveEndTime);
                    if (message.moveStartLon != null && Object.hasOwnProperty.call(message, "moveStartLon"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.moveStartLon);
                    if (message.moveStartLat != null && Object.hasOwnProperty.call(message, "moveStartLat"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.moveStartLat);
                    if (message.moveEndLon != null && Object.hasOwnProperty.call(message, "moveEndLon"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.moveEndLon);
                    if (message.moveEndLat != null && Object.hasOwnProperty.call(message, "moveEndLat"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.moveEndLat);
                    if (message.dispatchDispatchTime != null && Object.hasOwnProperty.call(message, "dispatchDispatchTime"))
                        writer.uint32(/* id 16, wireType 2 =*/130).string(message.dispatchDispatchTime);
                    if (message.dispatchFinishTime != null && Object.hasOwnProperty.call(message, "dispatchFinishTime"))
                        writer.uint32(/* id 17, wireType 2 =*/138).string(message.dispatchFinishTime);
                    if (message.dispatchOldParkPoint != null && Object.hasOwnProperty.call(message, "dispatchOldParkPoint"))
                        writer.uint32(/* id 18, wireType 2 =*/146).string(message.dispatchOldParkPoint);
                    if (message.dispatchNewParkPoint != null && Object.hasOwnProperty.call(message, "dispatchNewParkPoint"))
                        writer.uint32(/* id 19, wireType 2 =*/154).string(message.dispatchNewParkPoint);
                    if (message.dispatchState != null && Object.hasOwnProperty.call(message, "dispatchState"))
                        writer.uint32(/* id 20, wireType 0 =*/160).int32(message.dispatchState);
                    if (message.dispatchStartLonC != null && Object.hasOwnProperty.call(message, "dispatchStartLonC"))
                        writer.uint32(/* id 21, wireType 2 =*/170).string(message.dispatchStartLonC);
                    if (message.dispatchStartLatC != null && Object.hasOwnProperty.call(message, "dispatchStartLatC"))
                        writer.uint32(/* id 22, wireType 2 =*/178).string(message.dispatchStartLatC);
                    if (message.dispatchEndLonC != null && Object.hasOwnProperty.call(message, "dispatchEndLonC"))
                        writer.uint32(/* id 23, wireType 2 =*/186).string(message.dispatchEndLonC);
                    if (message.dispatchEndLatC != null && Object.hasOwnProperty.call(message, "dispatchEndLatC"))
                        writer.uint32(/* id 24, wireType 2 =*/194).string(message.dispatchEndLatC);
                    return writer;
                };

                /**
                 * Encodes the specified TrackInfo message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.TrackInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {com.bxkz.OuterClass.ITrackInfo} message TrackInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TrackInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TrackInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.TrackInfo} TrackInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TrackInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.TrackInfo();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.isValid = reader.bool();
                                break;
                            }
                        case 3: {
                                message.powerChangeTime = reader.string();
                                break;
                            }
                        case 4: {
                                message.powerOldSoc = reader.int32();
                                break;
                            }
                        case 5: {
                                message.powerNewSoc = reader.int32();
                                break;
                            }
                        case 6: {
                                message.powerRemark = reader.string();
                                break;
                            }
                        case 7: {
                                message.powerLon = reader.string();
                                break;
                            }
                        case 8: {
                                message.powerLat = reader.string();
                                break;
                            }
                        case 9: {
                                message.moveParkPointName = reader.string();
                                break;
                            }
                        case 10: {
                                message.moveStartTime = reader.string();
                                break;
                            }
                        case 11: {
                                message.moveEndTime = reader.string();
                                break;
                            }
                        case 12: {
                                message.moveStartLon = reader.string();
                                break;
                            }
                        case 13: {
                                message.moveStartLat = reader.string();
                                break;
                            }
                        case 14: {
                                message.moveEndLon = reader.string();
                                break;
                            }
                        case 15: {
                                message.moveEndLat = reader.string();
                                break;
                            }
                        case 16: {
                                message.dispatchDispatchTime = reader.string();
                                break;
                            }
                        case 17: {
                                message.dispatchFinishTime = reader.string();
                                break;
                            }
                        case 18: {
                                message.dispatchOldParkPoint = reader.string();
                                break;
                            }
                        case 19: {
                                message.dispatchNewParkPoint = reader.string();
                                break;
                            }
                        case 20: {
                                message.dispatchState = reader.int32();
                                break;
                            }
                        case 21: {
                                message.dispatchStartLonC = reader.string();
                                break;
                            }
                        case 22: {
                                message.dispatchStartLatC = reader.string();
                                break;
                            }
                        case 23: {
                                message.dispatchEndLonC = reader.string();
                                break;
                            }
                        case 24: {
                                message.dispatchEndLatC = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TrackInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.TrackInfo} TrackInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TrackInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TrackInfo message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TrackInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        if (!$util.isString(message.userCode))
                            return "userCode: string expected";
                    if (message.isValid != null && message.hasOwnProperty("isValid"))
                        if (typeof message.isValid !== "boolean")
                            return "isValid: boolean expected";
                    if (message.powerChangeTime != null && message.hasOwnProperty("powerChangeTime"))
                        if (!$util.isString(message.powerChangeTime))
                            return "powerChangeTime: string expected";
                    if (message.powerOldSoc != null && message.hasOwnProperty("powerOldSoc"))
                        if (!$util.isInteger(message.powerOldSoc))
                            return "powerOldSoc: integer expected";
                    if (message.powerNewSoc != null && message.hasOwnProperty("powerNewSoc"))
                        if (!$util.isInteger(message.powerNewSoc))
                            return "powerNewSoc: integer expected";
                    if (message.powerRemark != null && message.hasOwnProperty("powerRemark"))
                        if (!$util.isString(message.powerRemark))
                            return "powerRemark: string expected";
                    if (message.powerLon != null && message.hasOwnProperty("powerLon"))
                        if (!$util.isString(message.powerLon))
                            return "powerLon: string expected";
                    if (message.powerLat != null && message.hasOwnProperty("powerLat"))
                        if (!$util.isString(message.powerLat))
                            return "powerLat: string expected";
                    if (message.moveParkPointName != null && message.hasOwnProperty("moveParkPointName"))
                        if (!$util.isString(message.moveParkPointName))
                            return "moveParkPointName: string expected";
                    if (message.moveStartTime != null && message.hasOwnProperty("moveStartTime"))
                        if (!$util.isString(message.moveStartTime))
                            return "moveStartTime: string expected";
                    if (message.moveEndTime != null && message.hasOwnProperty("moveEndTime"))
                        if (!$util.isString(message.moveEndTime))
                            return "moveEndTime: string expected";
                    if (message.moveStartLon != null && message.hasOwnProperty("moveStartLon"))
                        if (!$util.isString(message.moveStartLon))
                            return "moveStartLon: string expected";
                    if (message.moveStartLat != null && message.hasOwnProperty("moveStartLat"))
                        if (!$util.isString(message.moveStartLat))
                            return "moveStartLat: string expected";
                    if (message.moveEndLon != null && message.hasOwnProperty("moveEndLon"))
                        if (!$util.isString(message.moveEndLon))
                            return "moveEndLon: string expected";
                    if (message.moveEndLat != null && message.hasOwnProperty("moveEndLat"))
                        if (!$util.isString(message.moveEndLat))
                            return "moveEndLat: string expected";
                    if (message.dispatchDispatchTime != null && message.hasOwnProperty("dispatchDispatchTime"))
                        if (!$util.isString(message.dispatchDispatchTime))
                            return "dispatchDispatchTime: string expected";
                    if (message.dispatchFinishTime != null && message.hasOwnProperty("dispatchFinishTime"))
                        if (!$util.isString(message.dispatchFinishTime))
                            return "dispatchFinishTime: string expected";
                    if (message.dispatchOldParkPoint != null && message.hasOwnProperty("dispatchOldParkPoint"))
                        if (!$util.isString(message.dispatchOldParkPoint))
                            return "dispatchOldParkPoint: string expected";
                    if (message.dispatchNewParkPoint != null && message.hasOwnProperty("dispatchNewParkPoint"))
                        if (!$util.isString(message.dispatchNewParkPoint))
                            return "dispatchNewParkPoint: string expected";
                    if (message.dispatchState != null && message.hasOwnProperty("dispatchState"))
                        if (!$util.isInteger(message.dispatchState))
                            return "dispatchState: integer expected";
                    if (message.dispatchStartLonC != null && message.hasOwnProperty("dispatchStartLonC"))
                        if (!$util.isString(message.dispatchStartLonC))
                            return "dispatchStartLonC: string expected";
                    if (message.dispatchStartLatC != null && message.hasOwnProperty("dispatchStartLatC"))
                        if (!$util.isString(message.dispatchStartLatC))
                            return "dispatchStartLatC: string expected";
                    if (message.dispatchEndLonC != null && message.hasOwnProperty("dispatchEndLonC"))
                        if (!$util.isString(message.dispatchEndLonC))
                            return "dispatchEndLonC: string expected";
                    if (message.dispatchEndLatC != null && message.hasOwnProperty("dispatchEndLatC"))
                        if (!$util.isString(message.dispatchEndLatC))
                            return "dispatchEndLatC: string expected";
                    return null;
                };

                /**
                 * Creates a TrackInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.TrackInfo} TrackInfo
                 */
                TrackInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.TrackInfo)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.TrackInfo();
                    if (object.userCode != null)
                        message.userCode = String(object.userCode);
                    if (object.isValid != null)
                        message.isValid = Boolean(object.isValid);
                    if (object.powerChangeTime != null)
                        message.powerChangeTime = String(object.powerChangeTime);
                    if (object.powerOldSoc != null)
                        message.powerOldSoc = object.powerOldSoc | 0;
                    if (object.powerNewSoc != null)
                        message.powerNewSoc = object.powerNewSoc | 0;
                    if (object.powerRemark != null)
                        message.powerRemark = String(object.powerRemark);
                    if (object.powerLon != null)
                        message.powerLon = String(object.powerLon);
                    if (object.powerLat != null)
                        message.powerLat = String(object.powerLat);
                    if (object.moveParkPointName != null)
                        message.moveParkPointName = String(object.moveParkPointName);
                    if (object.moveStartTime != null)
                        message.moveStartTime = String(object.moveStartTime);
                    if (object.moveEndTime != null)
                        message.moveEndTime = String(object.moveEndTime);
                    if (object.moveStartLon != null)
                        message.moveStartLon = String(object.moveStartLon);
                    if (object.moveStartLat != null)
                        message.moveStartLat = String(object.moveStartLat);
                    if (object.moveEndLon != null)
                        message.moveEndLon = String(object.moveEndLon);
                    if (object.moveEndLat != null)
                        message.moveEndLat = String(object.moveEndLat);
                    if (object.dispatchDispatchTime != null)
                        message.dispatchDispatchTime = String(object.dispatchDispatchTime);
                    if (object.dispatchFinishTime != null)
                        message.dispatchFinishTime = String(object.dispatchFinishTime);
                    if (object.dispatchOldParkPoint != null)
                        message.dispatchOldParkPoint = String(object.dispatchOldParkPoint);
                    if (object.dispatchNewParkPoint != null)
                        message.dispatchNewParkPoint = String(object.dispatchNewParkPoint);
                    if (object.dispatchState != null)
                        message.dispatchState = object.dispatchState | 0;
                    if (object.dispatchStartLonC != null)
                        message.dispatchStartLonC = String(object.dispatchStartLonC);
                    if (object.dispatchStartLatC != null)
                        message.dispatchStartLatC = String(object.dispatchStartLatC);
                    if (object.dispatchEndLonC != null)
                        message.dispatchEndLonC = String(object.dispatchEndLonC);
                    if (object.dispatchEndLatC != null)
                        message.dispatchEndLatC = String(object.dispatchEndLatC);
                    return message;
                };

                /**
                 * Creates a plain object from a TrackInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {com.bxkz.OuterClass.TrackInfo} message TrackInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TrackInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userCode = "";
                        object.isValid = false;
                        object.powerChangeTime = "";
                        object.powerOldSoc = 0;
                        object.powerNewSoc = 0;
                        object.powerRemark = "";
                        object.powerLon = "";
                        object.powerLat = "";
                        object.moveParkPointName = "";
                        object.moveStartTime = "";
                        object.moveEndTime = "";
                        object.moveStartLon = "";
                        object.moveStartLat = "";
                        object.moveEndLon = "";
                        object.moveEndLat = "";
                        object.dispatchDispatchTime = "";
                        object.dispatchFinishTime = "";
                        object.dispatchOldParkPoint = "";
                        object.dispatchNewParkPoint = "";
                        object.dispatchState = 0;
                        object.dispatchStartLonC = "";
                        object.dispatchStartLatC = "";
                        object.dispatchEndLonC = "";
                        object.dispatchEndLatC = "";
                    }
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        object.userCode = message.userCode;
                    if (message.isValid != null && message.hasOwnProperty("isValid"))
                        object.isValid = message.isValid;
                    if (message.powerChangeTime != null && message.hasOwnProperty("powerChangeTime"))
                        object.powerChangeTime = message.powerChangeTime;
                    if (message.powerOldSoc != null && message.hasOwnProperty("powerOldSoc"))
                        object.powerOldSoc = message.powerOldSoc;
                    if (message.powerNewSoc != null && message.hasOwnProperty("powerNewSoc"))
                        object.powerNewSoc = message.powerNewSoc;
                    if (message.powerRemark != null && message.hasOwnProperty("powerRemark"))
                        object.powerRemark = message.powerRemark;
                    if (message.powerLon != null && message.hasOwnProperty("powerLon"))
                        object.powerLon = message.powerLon;
                    if (message.powerLat != null && message.hasOwnProperty("powerLat"))
                        object.powerLat = message.powerLat;
                    if (message.moveParkPointName != null && message.hasOwnProperty("moveParkPointName"))
                        object.moveParkPointName = message.moveParkPointName;
                    if (message.moveStartTime != null && message.hasOwnProperty("moveStartTime"))
                        object.moveStartTime = message.moveStartTime;
                    if (message.moveEndTime != null && message.hasOwnProperty("moveEndTime"))
                        object.moveEndTime = message.moveEndTime;
                    if (message.moveStartLon != null && message.hasOwnProperty("moveStartLon"))
                        object.moveStartLon = message.moveStartLon;
                    if (message.moveStartLat != null && message.hasOwnProperty("moveStartLat"))
                        object.moveStartLat = message.moveStartLat;
                    if (message.moveEndLon != null && message.hasOwnProperty("moveEndLon"))
                        object.moveEndLon = message.moveEndLon;
                    if (message.moveEndLat != null && message.hasOwnProperty("moveEndLat"))
                        object.moveEndLat = message.moveEndLat;
                    if (message.dispatchDispatchTime != null && message.hasOwnProperty("dispatchDispatchTime"))
                        object.dispatchDispatchTime = message.dispatchDispatchTime;
                    if (message.dispatchFinishTime != null && message.hasOwnProperty("dispatchFinishTime"))
                        object.dispatchFinishTime = message.dispatchFinishTime;
                    if (message.dispatchOldParkPoint != null && message.hasOwnProperty("dispatchOldParkPoint"))
                        object.dispatchOldParkPoint = message.dispatchOldParkPoint;
                    if (message.dispatchNewParkPoint != null && message.hasOwnProperty("dispatchNewParkPoint"))
                        object.dispatchNewParkPoint = message.dispatchNewParkPoint;
                    if (message.dispatchState != null && message.hasOwnProperty("dispatchState"))
                        object.dispatchState = message.dispatchState;
                    if (message.dispatchStartLonC != null && message.hasOwnProperty("dispatchStartLonC"))
                        object.dispatchStartLonC = message.dispatchStartLonC;
                    if (message.dispatchStartLatC != null && message.hasOwnProperty("dispatchStartLatC"))
                        object.dispatchStartLatC = message.dispatchStartLatC;
                    if (message.dispatchEndLonC != null && message.hasOwnProperty("dispatchEndLonC"))
                        object.dispatchEndLonC = message.dispatchEndLonC;
                    if (message.dispatchEndLatC != null && message.hasOwnProperty("dispatchEndLatC"))
                        object.dispatchEndLatC = message.dispatchEndLatC;
                    return object;
                };

                /**
                 * Converts this TrackInfo to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TrackInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TrackInfo
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.TrackInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TrackInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.TrackInfo";
                };

                return TrackInfo;
            })();

            OuterClass.MaintenanceWorkerTrack = (function() {

                /**
                 * Properties of a MaintenanceWorkerTrack.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerTrack
                 * @property {string|null} [dateTime] MaintenanceWorkerTrack dateTime
                 * @property {string|null} [lonC] MaintenanceWorkerTrack lonC
                 * @property {string|null} [latC] MaintenanceWorkerTrack latC
                 * @property {com.bxkz.OuterClass.TrackType|null} [type] MaintenanceWorkerTrack type
                 * @property {string|null} [typeStr] MaintenanceWorkerTrack typeStr
                 * @property {number|null} [num] MaintenanceWorkerTrack num
                 * @property {Array.<com.bxkz.OuterClass.ITrackInfo>|null} [details] MaintenanceWorkerTrack details
                 */

                /**
                 * Constructs a new MaintenanceWorkerTrack.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerTrack.
                 * @implements IMaintenanceWorkerTrack
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrack=} [properties] Properties to set
                 */
                function MaintenanceWorkerTrack(properties) {
                    this.details = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerTrack dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.dateTime = "";

                /**
                 * MaintenanceWorkerTrack lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.lonC = "";

                /**
                 * MaintenanceWorkerTrack latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.latC = "";

                /**
                 * MaintenanceWorkerTrack type.
                 * @member {com.bxkz.OuterClass.TrackType} type
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.type = 0;

                /**
                 * MaintenanceWorkerTrack typeStr.
                 * @member {string} typeStr
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.typeStr = "";

                /**
                 * MaintenanceWorkerTrack num.
                 * @member {number} num
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.num = 0;

                /**
                 * MaintenanceWorkerTrack details.
                 * @member {Array.<com.bxkz.OuterClass.ITrackInfo>} details
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 */
                MaintenanceWorkerTrack.prototype.details = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkerTrack instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrack=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrack} MaintenanceWorkerTrack instance
                 */
                MaintenanceWorkerTrack.create = function create(properties) {
                    return new MaintenanceWorkerTrack(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrack message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrack.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrack} message MaintenanceWorkerTrack message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrack.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.dateTime);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.latC);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                    if (message.typeStr != null && Object.hasOwnProperty.call(message, "typeStr"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.typeStr);
                    if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.num);
                    if (message.details != null && message.details.length)
                        for (let i = 0; i < message.details.length; ++i)
                            $root.com.bxkz.OuterClass.TrackInfo.encode(message.details[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrack message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrack.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrack} message MaintenanceWorkerTrack message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrack.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerTrack message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrack} MaintenanceWorkerTrack
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrack.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrack();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.dateTime = reader.string();
                                break;
                            }
                        case 2: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 3: {
                                message.latC = reader.string();
                                break;
                            }
                        case 4: {
                                message.type = reader.int32();
                                break;
                            }
                        case 5: {
                                message.typeStr = reader.string();
                                break;
                            }
                        case 6: {
                                message.num = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.details && message.details.length))
                                    message.details = [];
                                message.details.push($root.com.bxkz.OuterClass.TrackInfo.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerTrack message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrack} MaintenanceWorkerTrack
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrack.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerTrack message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerTrack.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.typeStr != null && message.hasOwnProperty("typeStr"))
                        if (!$util.isString(message.typeStr))
                            return "typeStr: string expected";
                    if (message.num != null && message.hasOwnProperty("num"))
                        if (!$util.isInteger(message.num))
                            return "num: integer expected";
                    if (message.details != null && message.hasOwnProperty("details")) {
                        if (!Array.isArray(message.details))
                            return "details: array expected";
                        for (let i = 0; i < message.details.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.TrackInfo.verify(message.details[i]);
                            if (error)
                                return "details." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerTrack message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrack} MaintenanceWorkerTrack
                 */
                MaintenanceWorkerTrack.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerTrack)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrack();
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "ClockIn":
                    case 0:
                        message.type = 0;
                        break;
                    case "ClockOut":
                    case 1:
                        message.type = 1;
                        break;
                    case "Position":
                    case 2:
                        message.type = 2;
                        break;
                    case "DispatchIn":
                    case 3:
                        message.type = 3;
                        break;
                    case "DispatchOut":
                    case 4:
                        message.type = 4;
                        break;
                    case "Move":
                    case 5:
                        message.type = 5;
                        break;
                    case "PowerChange":
                    case 6:
                        message.type = 6;
                        break;
                    }
                    if (object.typeStr != null)
                        message.typeStr = String(object.typeStr);
                    if (object.num != null)
                        message.num = object.num | 0;
                    if (object.details) {
                        if (!Array.isArray(object.details))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrack.details: array expected");
                        message.details = [];
                        for (let i = 0; i < object.details.length; ++i) {
                            if (typeof object.details[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrack.details: object expected");
                            message.details[i] = $root.com.bxkz.OuterClass.TrackInfo.fromObject(object.details[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerTrack message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrack} message MaintenanceWorkerTrack
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerTrack.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.details = [];
                    if (options.defaults) {
                        object.dateTime = "";
                        object.lonC = "";
                        object.latC = "";
                        object.type = options.enums === String ? "ClockIn" : 0;
                        object.typeStr = "";
                        object.num = 0;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.com.bxkz.OuterClass.TrackType[message.type] === undefined ? message.type : $root.com.bxkz.OuterClass.TrackType[message.type] : message.type;
                    if (message.typeStr != null && message.hasOwnProperty("typeStr"))
                        object.typeStr = message.typeStr;
                    if (message.num != null && message.hasOwnProperty("num"))
                        object.num = message.num;
                    if (message.details && message.details.length) {
                        object.details = [];
                        for (let j = 0; j < message.details.length; ++j)
                            object.details[j] = $root.com.bxkz.OuterClass.TrackInfo.toObject(message.details[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerTrack to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerTrack.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerTrack
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrack
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerTrack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerTrack";
                };

                return MaintenanceWorkerTrack;
            })();

            OuterClass.MaintenanceWorkerTrackListWithName = (function() {

                /**
                 * Properties of a MaintenanceWorkerTrackListWithName.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerTrackListWithName
                 * @property {string|null} [name] MaintenanceWorkerTrackListWithName name
                 * @property {Array.<com.bxkz.OuterClass.IMaintenanceWorkerTrack>|null} [data] MaintenanceWorkerTrackListWithName data
                 */

                /**
                 * Constructs a new MaintenanceWorkerTrackListWithName.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerTrackListWithName.
                 * @implements IMaintenanceWorkerTrackListWithName
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName=} [properties] Properties to set
                 */
                function MaintenanceWorkerTrackListWithName(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerTrackListWithName name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @instance
                 */
                MaintenanceWorkerTrackListWithName.prototype.name = "";

                /**
                 * MaintenanceWorkerTrackListWithName data.
                 * @member {Array.<com.bxkz.OuterClass.IMaintenanceWorkerTrack>} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @instance
                 */
                MaintenanceWorkerTrackListWithName.prototype.data = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkerTrackListWithName instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName} MaintenanceWorkerTrackListWithName instance
                 */
                MaintenanceWorkerTrackListWithName.create = function create(properties) {
                    return new MaintenanceWorkerTrackListWithName(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrackListWithName message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName} message MaintenanceWorkerTrackListWithName message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrackListWithName.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.MaintenanceWorkerTrack.encode(message.data[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrackListWithName message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName} message MaintenanceWorkerTrackListWithName message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrackListWithName.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerTrackListWithName message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName} MaintenanceWorkerTrackListWithName
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrackListWithName.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.MaintenanceWorkerTrack.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerTrackListWithName message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName} MaintenanceWorkerTrackListWithName
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrackListWithName.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerTrackListWithName message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerTrackListWithName.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.MaintenanceWorkerTrack.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerTrackListWithName message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName} MaintenanceWorkerTrackListWithName
                 */
                MaintenanceWorkerTrackListWithName.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.MaintenanceWorkerTrack.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerTrackListWithName message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName} message MaintenanceWorkerTrackListWithName
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerTrackListWithName.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.MaintenanceWorkerTrack.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerTrackListWithName to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerTrackListWithName.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerTrackListWithName
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerTrackListWithName.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName";
                };

                return MaintenanceWorkerTrackListWithName;
            })();

            OuterClass.MaintenanceWorkerTrackListWithNameList = (function() {

                /**
                 * Properties of a MaintenanceWorkerTrackListWithNameList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IMaintenanceWorkerTrackListWithNameList
                 * @property {Array.<com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName>|null} [data] MaintenanceWorkerTrackListWithNameList data
                 */

                /**
                 * Constructs a new MaintenanceWorkerTrackListWithNameList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerTrackListWithNameList.
                 * @implements IMaintenanceWorkerTrackListWithNameList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithNameList=} [properties] Properties to set
                 */
                function MaintenanceWorkerTrackListWithNameList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceWorkerTrackListWithNameList data.
                 * @member {Array.<com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithName>} data
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @instance
                 */
                MaintenanceWorkerTrackListWithNameList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new MaintenanceWorkerTrackListWithNameList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithNameList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} MaintenanceWorkerTrackListWithNameList instance
                 */
                MaintenanceWorkerTrackListWithNameList.create = function create(properties) {
                    return new MaintenanceWorkerTrackListWithNameList(properties);
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrackListWithNameList message. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithNameList} message MaintenanceWorkerTrackListWithNameList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrackListWithNameList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MaintenanceWorkerTrackListWithNameList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {com.bxkz.OuterClass.IMaintenanceWorkerTrackListWithNameList} message MaintenanceWorkerTrackListWithNameList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MaintenanceWorkerTrackListWithNameList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MaintenanceWorkerTrackListWithNameList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} MaintenanceWorkerTrackListWithNameList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrackListWithNameList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MaintenanceWorkerTrackListWithNameList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} MaintenanceWorkerTrackListWithNameList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MaintenanceWorkerTrackListWithNameList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MaintenanceWorkerTrackListWithNameList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceWorkerTrackListWithNameList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MaintenanceWorkerTrackListWithNameList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} MaintenanceWorkerTrackListWithNameList
                 */
                MaintenanceWorkerTrackListWithNameList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceWorkerTrackListWithNameList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} message MaintenanceWorkerTrackListWithNameList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceWorkerTrackListWithNameList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithName.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MaintenanceWorkerTrackListWithNameList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceWorkerTrackListWithNameList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MaintenanceWorkerTrackListWithNameList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MaintenanceWorkerTrackListWithNameList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList";
                };

                return MaintenanceWorkerTrackListWithNameList;
            })();

            OuterClass.MaintenanceWorkerTrackRealTime = (function() {

                /**
                 * Constructs a new MaintenanceWorkerTrackRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerTrackRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerTrackRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerTrackRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerTrackRealTime;

                /**
                 * Creates new MaintenanceWorkerTrackRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerTrackRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerTrackRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} [response] MaintenanceWorkerTrackListWithNameList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkerTrackListWithNameList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerTrackRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerTrackRealTime;
            })();

            OuterClass.MaintenanceWorkerTrackHistory = (function() {

                /**
                 * Constructs a new MaintenanceWorkerTrackHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a MaintenanceWorkerTrackHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function MaintenanceWorkerTrackHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (MaintenanceWorkerTrackHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MaintenanceWorkerTrackHistory;

                /**
                 * Creates new MaintenanceWorkerTrackHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {MaintenanceWorkerTrackHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                MaintenanceWorkerTrackHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.MaintenanceWorkerTrackHistory#service}.
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList} [response] MaintenanceWorkerTrackListWithNameList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.MaintenanceWorkerTrackHistory.serviceCallback} callback Node-style callback called with the error, if any, and MaintenanceWorkerTrackListWithNameList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(MaintenanceWorkerTrackHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.MaintenanceWorkerTrackHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.MaintenanceWorkerTrackListWithNameList>} Promise
                 * @variation 2
                 */

                return MaintenanceWorkerTrackHistory;
            })();

            OuterClass.NoPower = (function() {

                /**
                 * Properties of a NoPower.
                 * @memberof com.bxkz.OuterClass
                 * @interface INoPower
                 * @property {string|null} [userCode] NoPower userCode
                 * @property {string|null} [lonC] NoPower lonC
                 * @property {string|null} [latC] NoPower latC
                 * @property {string|null} [soc] NoPower soc
                 * @property {boolean|null} [noPower] NoPower noPower
                 * @property {string|null} [lastUsedDateTime] NoPower lastUsedDateTime
                 * @property {string|null} [posDt] NoPower posDt
                 * @property {string|null} [batDt] NoPower batDt
                 * @property {string|null} [lastParkPointName] NoPower lastParkPointName
                 */

                /**
                 * Constructs a new NoPower.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 亏电
                 * @implements INoPower
                 * @constructor
                 * @param {com.bxkz.OuterClass.INoPower=} [properties] Properties to set
                 */
                function NoPower(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NoPower userCode.
                 * @member {string} userCode
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.userCode = "";

                /**
                 * NoPower lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.lonC = "";

                /**
                 * NoPower latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.latC = "";

                /**
                 * NoPower soc.
                 * @member {string} soc
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.soc = "";

                /**
                 * NoPower noPower.
                 * @member {boolean} noPower
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.noPower = false;

                /**
                 * NoPower lastUsedDateTime.
                 * @member {string} lastUsedDateTime
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.lastUsedDateTime = "";

                /**
                 * NoPower posDt.
                 * @member {string} posDt
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.posDt = "";

                /**
                 * NoPower batDt.
                 * @member {string} batDt
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.batDt = "";

                /**
                 * NoPower lastParkPointName.
                 * @member {string} lastParkPointName
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 */
                NoPower.prototype.lastParkPointName = "";

                /**
                 * Creates a new NoPower instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {com.bxkz.OuterClass.INoPower=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.NoPower} NoPower instance
                 */
                NoPower.create = function create(properties) {
                    return new NoPower(properties);
                };

                /**
                 * Encodes the specified NoPower message. Does not implicitly {@link com.bxkz.OuterClass.NoPower.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {com.bxkz.OuterClass.INoPower} message NoPower message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPower.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userCode != null && Object.hasOwnProperty.call(message, "userCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userCode);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.latC);
                    if (message.soc != null && Object.hasOwnProperty.call(message, "soc"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.soc);
                    if (message.noPower != null && Object.hasOwnProperty.call(message, "noPower"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.noPower);
                    if (message.lastUsedDateTime != null && Object.hasOwnProperty.call(message, "lastUsedDateTime"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.lastUsedDateTime);
                    if (message.posDt != null && Object.hasOwnProperty.call(message, "posDt"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.posDt);
                    if (message.batDt != null && Object.hasOwnProperty.call(message, "batDt"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.batDt);
                    if (message.lastParkPointName != null && Object.hasOwnProperty.call(message, "lastParkPointName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.lastParkPointName);
                    return writer;
                };

                /**
                 * Encodes the specified NoPower message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.NoPower.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {com.bxkz.OuterClass.INoPower} message NoPower message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPower.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NoPower message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.NoPower} NoPower
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPower.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.NoPower();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 3: {
                                message.latC = reader.string();
                                break;
                            }
                        case 4: {
                                message.soc = reader.string();
                                break;
                            }
                        case 5: {
                                message.noPower = reader.bool();
                                break;
                            }
                        case 6: {
                                message.lastUsedDateTime = reader.string();
                                break;
                            }
                        case 7: {
                                message.posDt = reader.string();
                                break;
                            }
                        case 8: {
                                message.batDt = reader.string();
                                break;
                            }
                        case 9: {
                                message.lastParkPointName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NoPower message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.NoPower} NoPower
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPower.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NoPower message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoPower.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        if (!$util.isString(message.userCode))
                            return "userCode: string expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        if (!$util.isString(message.soc))
                            return "soc: string expected";
                    if (message.noPower != null && message.hasOwnProperty("noPower"))
                        if (typeof message.noPower !== "boolean")
                            return "noPower: boolean expected";
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        if (!$util.isString(message.lastUsedDateTime))
                            return "lastUsedDateTime: string expected";
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        if (!$util.isString(message.posDt))
                            return "posDt: string expected";
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        if (!$util.isString(message.batDt))
                            return "batDt: string expected";
                    if (message.lastParkPointName != null && message.hasOwnProperty("lastParkPointName"))
                        if (!$util.isString(message.lastParkPointName))
                            return "lastParkPointName: string expected";
                    return null;
                };

                /**
                 * Creates a NoPower message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.NoPower} NoPower
                 */
                NoPower.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.NoPower)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.NoPower();
                    if (object.userCode != null)
                        message.userCode = String(object.userCode);
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    if (object.soc != null)
                        message.soc = String(object.soc);
                    if (object.noPower != null)
                        message.noPower = Boolean(object.noPower);
                    if (object.lastUsedDateTime != null)
                        message.lastUsedDateTime = String(object.lastUsedDateTime);
                    if (object.posDt != null)
                        message.posDt = String(object.posDt);
                    if (object.batDt != null)
                        message.batDt = String(object.batDt);
                    if (object.lastParkPointName != null)
                        message.lastParkPointName = String(object.lastParkPointName);
                    return message;
                };

                /**
                 * Creates a plain object from a NoPower message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {com.bxkz.OuterClass.NoPower} message NoPower
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoPower.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userCode = "";
                        object.lonC = "";
                        object.latC = "";
                        object.soc = "";
                        object.noPower = false;
                        object.lastUsedDateTime = "";
                        object.posDt = "";
                        object.batDt = "";
                        object.lastParkPointName = "";
                    }
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        object.userCode = message.userCode;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        object.soc = message.soc;
                    if (message.noPower != null && message.hasOwnProperty("noPower"))
                        object.noPower = message.noPower;
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        object.lastUsedDateTime = message.lastUsedDateTime;
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        object.posDt = message.posDt;
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        object.batDt = message.batDt;
                    if (message.lastParkPointName != null && message.hasOwnProperty("lastParkPointName"))
                        object.lastParkPointName = message.lastParkPointName;
                    return object;
                };

                /**
                 * Converts this NoPower to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoPower.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoPower
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.NoPower
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoPower.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.NoPower";
                };

                return NoPower;
            })();

            OuterClass.NoPowerList = (function() {

                /**
                 * Properties of a NoPowerList.
                 * @memberof com.bxkz.OuterClass
                 * @interface INoPowerList
                 * @property {Array.<com.bxkz.OuterClass.INoPower>|null} [data] NoPowerList data
                 */

                /**
                 * Constructs a new NoPowerList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a NoPowerList.
                 * @implements INoPowerList
                 * @constructor
                 * @param {com.bxkz.OuterClass.INoPowerList=} [properties] Properties to set
                 */
                function NoPowerList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NoPowerList data.
                 * @member {Array.<com.bxkz.OuterClass.INoPower>} data
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @instance
                 */
                NoPowerList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new NoPowerList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.NoPowerList} NoPowerList instance
                 */
                NoPowerList.create = function create(properties) {
                    return new NoPowerList(properties);
                };

                /**
                 * Encodes the specified NoPowerList message. Does not implicitly {@link com.bxkz.OuterClass.NoPowerList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerList} message NoPowerList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.NoPower.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified NoPowerList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.NoPowerList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerList} message NoPowerList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NoPowerList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.NoPowerList} NoPowerList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.NoPowerList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.NoPower.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NoPowerList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.NoPowerList} NoPowerList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NoPowerList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoPowerList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.NoPower.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a NoPowerList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.NoPowerList} NoPowerList
                 */
                NoPowerList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.NoPowerList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.NoPowerList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.NoPowerList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.NoPowerList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.NoPower.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a NoPowerList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {com.bxkz.OuterClass.NoPowerList} message NoPowerList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoPowerList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.NoPower.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this NoPowerList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoPowerList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoPowerList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.NoPowerList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoPowerList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.NoPowerList";
                };

                return NoPowerList;
            })();

            OuterClass.NoPowerRealTime = (function() {

                /**
                 * Constructs a new NoPowerRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a NoPowerRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function NoPowerRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (NoPowerRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NoPowerRealTime;

                /**
                 * Creates new NoPowerRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPowerRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {NoPowerRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                NoPowerRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.NoPowerRealTime#service}.
                 * @memberof com.bxkz.OuterClass.NoPowerRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.NoPowerList} [response] NoPowerList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.NoPowerRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.NoPowerRealTime.serviceCallback} callback Node-style callback called with the error, if any, and NoPowerList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NoPowerRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.NoPowerList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.NoPowerRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.NoPowerList>} Promise
                 * @variation 2
                 */

                return NoPowerRealTime;
            })();

            OuterClass.NoPowerWithTime = (function() {

                /**
                 * Properties of a NoPowerWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface INoPowerWithTime
                 * @property {com.bxkz.OuterClass.INoPowerList|null} [data] NoPowerWithTime data
                 * @property {string|null} [dateTime] NoPowerWithTime dateTime
                 */

                /**
                 * Constructs a new NoPowerWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a NoPowerWithTime.
                 * @implements INoPowerWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.INoPowerWithTime=} [properties] Properties to set
                 */
                function NoPowerWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NoPowerWithTime data.
                 * @member {com.bxkz.OuterClass.INoPowerList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @instance
                 */
                NoPowerWithTime.prototype.data = null;

                /**
                 * NoPowerWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @instance
                 */
                NoPowerWithTime.prototype.dateTime = "";

                /**
                 * Creates a new NoPowerWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.NoPowerWithTime} NoPowerWithTime instance
                 */
                NoPowerWithTime.create = function create(properties) {
                    return new NoPowerWithTime(properties);
                };

                /**
                 * Encodes the specified NoPowerWithTime message. Does not implicitly {@link com.bxkz.OuterClass.NoPowerWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTime} message NoPowerWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.NoPowerList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified NoPowerWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.NoPowerWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTime} message NoPowerWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NoPowerWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.NoPowerWithTime} NoPowerWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.NoPowerWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.NoPowerList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NoPowerWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.NoPowerWithTime} NoPowerWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NoPowerWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoPowerWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.NoPowerList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a NoPowerWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.NoPowerWithTime} NoPowerWithTime
                 */
                NoPowerWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.NoPowerWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.NoPowerWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.NoPowerWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.NoPowerList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a NoPowerWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.NoPowerWithTime} message NoPowerWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoPowerWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.NoPowerList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this NoPowerWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoPowerWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoPowerWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.NoPowerWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoPowerWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.NoPowerWithTime";
                };

                return NoPowerWithTime;
            })();

            OuterClass.NoPowerWithTimeList = (function() {

                /**
                 * Properties of a NoPowerWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface INoPowerWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.INoPowerWithTime>|null} [data] NoPowerWithTimeList data
                 */

                /**
                 * Constructs a new NoPowerWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a NoPowerWithTimeList.
                 * @implements INoPowerWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.INoPowerWithTimeList=} [properties] Properties to set
                 */
                function NoPowerWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NoPowerWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.INoPowerWithTime>} data
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @instance
                 */
                NoPowerWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new NoPowerWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.NoPowerWithTimeList} NoPowerWithTimeList instance
                 */
                NoPowerWithTimeList.create = function create(properties) {
                    return new NoPowerWithTimeList(properties);
                };

                /**
                 * Encodes the specified NoPowerWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.NoPowerWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTimeList} message NoPowerWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.NoPowerWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified NoPowerWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.NoPowerWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.INoPowerWithTimeList} message NoPowerWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NoPowerWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NoPowerWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.NoPowerWithTimeList} NoPowerWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.NoPowerWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.NoPowerWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NoPowerWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.NoPowerWithTimeList} NoPowerWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NoPowerWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NoPowerWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NoPowerWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.NoPowerWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a NoPowerWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.NoPowerWithTimeList} NoPowerWithTimeList
                 */
                NoPowerWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.NoPowerWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.NoPowerWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.NoPowerWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.NoPowerWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.NoPowerWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a NoPowerWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.NoPowerWithTimeList} message NoPowerWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NoPowerWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.NoPowerWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this NoPowerWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NoPowerWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NoPowerWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.NoPowerWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NoPowerWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.NoPowerWithTimeList";
                };

                return NoPowerWithTimeList;
            })();

            OuterClass.NoPowerHistory = (function() {

                /**
                 * Constructs a new NoPowerHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a NoPowerHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function NoPowerHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (NoPowerHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NoPowerHistory;

                /**
                 * Creates new NoPowerHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.NoPowerHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {NoPowerHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                NoPowerHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.NoPowerHistory#service}.
                 * @memberof com.bxkz.OuterClass.NoPowerHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.NoPowerWithTimeList} [response] NoPowerWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.NoPowerHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.NoPowerHistory.serviceCallback} callback Node-style callback called with the error, if any, and NoPowerWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(NoPowerHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.NoPowerWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.NoPowerHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.NoPowerWithTimeList>} Promise
                 * @variation 2
                 */

                return NoPowerHistory;
            })();

            OuterClass.ParkPoint = (function() {

                /**
                 * Properties of a ParkPoint.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPoint
                 * @property {number|null} [parkPointId] ParkPoint parkPointId
                 * @property {string|null} [name] ParkPoint name
                 * @property {string|null} [points] ParkPoint points
                 * @property {string|null} [lonC] ParkPoint lonC
                 * @property {string|null} [latC] ParkPoint latC
                 */

                /**
                 * Constructs a new ParkPoint.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 站点信息
                 * @implements IParkPoint
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPoint=} [properties] Properties to set
                 */
                function ParkPoint(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPoint parkPointId.
                 * @member {number} parkPointId
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 */
                ParkPoint.prototype.parkPointId = 0;

                /**
                 * ParkPoint name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 */
                ParkPoint.prototype.name = "";

                /**
                 * ParkPoint points.
                 * @member {string} points
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 */
                ParkPoint.prototype.points = "";

                /**
                 * ParkPoint lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 */
                ParkPoint.prototype.lonC = "";

                /**
                 * ParkPoint latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 */
                ParkPoint.prototype.latC = "";

                /**
                 * Creates a new ParkPoint instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPoint=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPoint} ParkPoint instance
                 */
                ParkPoint.create = function create(properties) {
                    return new ParkPoint(properties);
                };

                /**
                 * Encodes the specified ParkPoint message. Does not implicitly {@link com.bxkz.OuterClass.ParkPoint.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPoint} message ParkPoint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPoint.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.parkPointId != null && Object.hasOwnProperty.call(message, "parkPointId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.parkPointId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.points);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.latC);
                    return writer;
                };

                /**
                 * Encodes the specified ParkPoint message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPoint.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPoint} message ParkPoint message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPoint.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPoint message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPoint} ParkPoint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPoint.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPoint();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.parkPointId = reader.int32();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.points = reader.string();
                                break;
                            }
                        case 4: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 5: {
                                message.latC = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPoint message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPoint} ParkPoint
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPoint.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPoint message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPoint.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.parkPointId != null && message.hasOwnProperty("parkPointId"))
                        if (!$util.isInteger(message.parkPointId))
                            return "parkPointId: integer expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.points != null && message.hasOwnProperty("points"))
                        if (!$util.isString(message.points))
                            return "points: string expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    return null;
                };

                /**
                 * Creates a ParkPoint message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPoint} ParkPoint
                 */
                ParkPoint.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPoint)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPoint();
                    if (object.parkPointId != null)
                        message.parkPointId = object.parkPointId | 0;
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.points != null)
                        message.points = String(object.points);
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPoint message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPoint} message ParkPoint
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPoint.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.parkPointId = 0;
                        object.name = "";
                        object.points = "";
                        object.lonC = "";
                        object.latC = "";
                    }
                    if (message.parkPointId != null && message.hasOwnProperty("parkPointId"))
                        object.parkPointId = message.parkPointId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.points != null && message.hasOwnProperty("points"))
                        object.points = message.points;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    return object;
                };

                /**
                 * Converts this ParkPoint to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPoint.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPoint
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPoint
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPoint";
                };

                return ParkPoint;
            })();

            OuterClass.ParkPointList = (function() {

                /**
                 * Properties of a ParkPointList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointList
                 * @property {Array.<com.bxkz.OuterClass.IParkPoint>|null} [data] ParkPointList data
                 */

                /**
                 * Constructs a new ParkPointList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointList.
                 * @implements IParkPointList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointList=} [properties] Properties to set
                 */
                function ParkPointList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointList data.
                 * @member {Array.<com.bxkz.OuterClass.IParkPoint>} data
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @instance
                 */
                ParkPointList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ParkPointList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointList} ParkPointList instance
                 */
                ParkPointList.create = function create(properties) {
                    return new ParkPointList(properties);
                };

                /**
                 * Encodes the specified ParkPointList message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointList} message ParkPointList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ParkPoint.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointList} message ParkPointList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointList} ParkPointList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ParkPoint.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointList} ParkPointList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ParkPoint.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ParkPointList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointList} ParkPointList
                 */
                ParkPointList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ParkPointList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ParkPoint.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointList} message ParkPointList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ParkPoint.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ParkPointList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointList";
                };

                return ParkPointList;
            })();

            OuterClass.ParkPointRealTime = (function() {

                /**
                 * Constructs a new ParkPointRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ParkPointRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ParkPointRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ParkPointRealTime;

                /**
                 * Creates new ParkPointRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ParkPointRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                ParkPointRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ParkPointRealTime#service}.
                 * @memberof com.bxkz.OuterClass.ParkPointRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ParkPointList} [response] ParkPointList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.ParkPointRealTime.serviceCallback} callback Node-style callback called with the error, if any, and ParkPointList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ParkPointRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.ParkPointList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ParkPointList>} Promise
                 * @variation 2
                 */

                return ParkPointRealTime;
            })();

            OuterClass.ParkPointListWithTime = (function() {

                /**
                 * Properties of a ParkPointListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointListWithTime
                 * @property {com.bxkz.OuterClass.IParkPointList|null} [data] ParkPointListWithTime data
                 * @property {string|null} [dateTime] ParkPointListWithTime dateTime
                 */

                /**
                 * Constructs a new ParkPointListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointListWithTime.
                 * @implements IParkPointListWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointListWithTime=} [properties] Properties to set
                 */
                function ParkPointListWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointListWithTime data.
                 * @member {com.bxkz.OuterClass.IParkPointList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @instance
                 */
                ParkPointListWithTime.prototype.data = null;

                /**
                 * ParkPointListWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @instance
                 */
                ParkPointListWithTime.prototype.dateTime = "";

                /**
                 * Creates a new ParkPointListWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTime} ParkPointListWithTime instance
                 */
                ParkPointListWithTime.create = function create(properties) {
                    return new ParkPointListWithTime(properties);
                };

                /**
                 * Encodes the specified ParkPointListWithTime message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointListWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTime} message ParkPointListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointListWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.ParkPointList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointListWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointListWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTime} message ParkPointListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointListWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointListWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTime} ParkPointListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointListWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointListWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.ParkPointList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointListWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTime} ParkPointListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointListWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointListWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointListWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.ParkPointList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a ParkPointListWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTime} ParkPointListWithTime
                 */
                ParkPointListWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointListWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointListWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.ParkPointListWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.ParkPointList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointListWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointListWithTime} message ParkPointListWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointListWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.ParkPointList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this ParkPointListWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointListWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointListWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointListWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointListWithTime";
                };

                return ParkPointListWithTime;
            })();

            OuterClass.ParkPointListWithTimeList = (function() {

                /**
                 * Properties of a ParkPointListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointListWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IParkPointListWithTime>|null} [data] ParkPointListWithTimeList data
                 */

                /**
                 * Constructs a new ParkPointListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointListWithTimeList.
                 * @implements IParkPointListWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointListWithTimeList=} [properties] Properties to set
                 */
                function ParkPointListWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointListWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IParkPointListWithTime>} data
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @instance
                 */
                ParkPointListWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ParkPointListWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTimeList} ParkPointListWithTimeList instance
                 */
                ParkPointListWithTimeList.create = function create(properties) {
                    return new ParkPointListWithTimeList(properties);
                };

                /**
                 * Encodes the specified ParkPointListWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointListWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTimeList} message ParkPointListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointListWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ParkPointListWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointListWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointListWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointListWithTimeList} message ParkPointListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointListWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointListWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTimeList} ParkPointListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointListWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointListWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ParkPointListWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointListWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTimeList} ParkPointListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointListWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointListWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointListWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ParkPointListWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ParkPointListWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointListWithTimeList} ParkPointListWithTimeList
                 */
                ParkPointListWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointListWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointListWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointListWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ParkPointListWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ParkPointListWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointListWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointListWithTimeList} message ParkPointListWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointListWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ParkPointListWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ParkPointListWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointListWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointListWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointListWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointListWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointListWithTimeList";
                };

                return ParkPointListWithTimeList;
            })();

            OuterClass.ParkPointHistory = (function() {

                /**
                 * Constructs a new ParkPointHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ParkPointHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ParkPointHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ParkPointHistory;

                /**
                 * Creates new ParkPointHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ParkPointHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                ParkPointHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ParkPointHistory#service}.
                 * @memberof com.bxkz.OuterClass.ParkPointHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ParkPointListWithTimeList} [response] ParkPointListWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.ParkPointHistory.serviceCallback} callback Node-style callback called with the error, if any, and ParkPointListWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ParkPointHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.ParkPointListWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ParkPointListWithTimeList>} Promise
                 * @variation 2
                 */

                return ParkPointHistory;
            })();

            OuterClass.ParkPointWithWeight = (function() {

                /**
                 * Properties of a ParkPointWithWeight.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointWithWeight
                 * @property {com.bxkz.OuterClass.IParkPoint|null} [parkPoint] ParkPointWithWeight parkPoint
                 * @property {number|null} [parkNum] ParkPointWithWeight parkNum
                 * @property {Array.<number>|null} [lendNum] ParkPointWithWeight lendNum
                 * @property {Array.<number>|null} [returnNum] ParkPointWithWeight returnNum
                 */

                /**
                 * Constructs a new ParkPointWithWeight.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 站点信息(带站点借出, 还入的权重)
                 * @implements IParkPointWithWeight
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointWithWeight=} [properties] Properties to set
                 */
                function ParkPointWithWeight(properties) {
                    this.lendNum = [];
                    this.returnNum = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointWithWeight parkPoint.
                 * @member {com.bxkz.OuterClass.IParkPoint|null|undefined} parkPoint
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @instance
                 */
                ParkPointWithWeight.prototype.parkPoint = null;

                /**
                 * ParkPointWithWeight parkNum.
                 * @member {number} parkNum
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @instance
                 */
                ParkPointWithWeight.prototype.parkNum = 0;

                /**
                 * ParkPointWithWeight lendNum.
                 * @member {Array.<number>} lendNum
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @instance
                 */
                ParkPointWithWeight.prototype.lendNum = $util.emptyArray;

                /**
                 * ParkPointWithWeight returnNum.
                 * @member {Array.<number>} returnNum
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @instance
                 */
                ParkPointWithWeight.prototype.returnNum = $util.emptyArray;

                /**
                 * Creates a new ParkPointWithWeight instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeight=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeight} ParkPointWithWeight instance
                 */
                ParkPointWithWeight.create = function create(properties) {
                    return new ParkPointWithWeight(properties);
                };

                /**
                 * Encodes the specified ParkPointWithWeight message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeight.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeight} message ParkPointWithWeight message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeight.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.parkPoint != null && Object.hasOwnProperty.call(message, "parkPoint"))
                        $root.com.bxkz.OuterClass.ParkPoint.encode(message.parkPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.parkNum != null && Object.hasOwnProperty.call(message, "parkNum"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.parkNum);
                    if (message.lendNum != null && message.lendNum.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (let i = 0; i < message.lendNum.length; ++i)
                            writer.int32(message.lendNum[i]);
                        writer.ldelim();
                    }
                    if (message.returnNum != null && message.returnNum.length) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork();
                        for (let i = 0; i < message.returnNum.length; ++i)
                            writer.int32(message.returnNum[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointWithWeight message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeight.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeight} message ParkPointWithWeight message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeight.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointWithWeight message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeight} ParkPointWithWeight
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeight.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointWithWeight();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.parkPoint = $root.com.bxkz.OuterClass.ParkPoint.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.parkNum = reader.int32();
                                break;
                            }
                        case 3: {
                                if (!(message.lendNum && message.lendNum.length))
                                    message.lendNum = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.lendNum.push(reader.int32());
                                } else
                                    message.lendNum.push(reader.int32());
                                break;
                            }
                        case 4: {
                                if (!(message.returnNum && message.returnNum.length))
                                    message.returnNum = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.returnNum.push(reader.int32());
                                } else
                                    message.returnNum.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointWithWeight message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeight} ParkPointWithWeight
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeight.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointWithWeight message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointWithWeight.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.parkPoint != null && message.hasOwnProperty("parkPoint")) {
                        let error = $root.com.bxkz.OuterClass.ParkPoint.verify(message.parkPoint);
                        if (error)
                            return "parkPoint." + error;
                    }
                    if (message.parkNum != null && message.hasOwnProperty("parkNum"))
                        if (!$util.isInteger(message.parkNum))
                            return "parkNum: integer expected";
                    if (message.lendNum != null && message.hasOwnProperty("lendNum")) {
                        if (!Array.isArray(message.lendNum))
                            return "lendNum: array expected";
                        for (let i = 0; i < message.lendNum.length; ++i)
                            if (!$util.isInteger(message.lendNum[i]))
                                return "lendNum: integer[] expected";
                    }
                    if (message.returnNum != null && message.hasOwnProperty("returnNum")) {
                        if (!Array.isArray(message.returnNum))
                            return "returnNum: array expected";
                        for (let i = 0; i < message.returnNum.length; ++i)
                            if (!$util.isInteger(message.returnNum[i]))
                                return "returnNum: integer[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ParkPointWithWeight message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeight} ParkPointWithWeight
                 */
                ParkPointWithWeight.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointWithWeight)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointWithWeight();
                    if (object.parkPoint != null) {
                        if (typeof object.parkPoint !== "object")
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeight.parkPoint: object expected");
                        message.parkPoint = $root.com.bxkz.OuterClass.ParkPoint.fromObject(object.parkPoint);
                    }
                    if (object.parkNum != null)
                        message.parkNum = object.parkNum | 0;
                    if (object.lendNum) {
                        if (!Array.isArray(object.lendNum))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeight.lendNum: array expected");
                        message.lendNum = [];
                        for (let i = 0; i < object.lendNum.length; ++i)
                            message.lendNum[i] = object.lendNum[i] | 0;
                    }
                    if (object.returnNum) {
                        if (!Array.isArray(object.returnNum))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeight.returnNum: array expected");
                        message.returnNum = [];
                        for (let i = 0; i < object.returnNum.length; ++i)
                            message.returnNum[i] = object.returnNum[i] | 0;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointWithWeight message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointWithWeight} message ParkPointWithWeight
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointWithWeight.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.lendNum = [];
                        object.returnNum = [];
                    }
                    if (options.defaults) {
                        object.parkPoint = null;
                        object.parkNum = 0;
                    }
                    if (message.parkPoint != null && message.hasOwnProperty("parkPoint"))
                        object.parkPoint = $root.com.bxkz.OuterClass.ParkPoint.toObject(message.parkPoint, options);
                    if (message.parkNum != null && message.hasOwnProperty("parkNum"))
                        object.parkNum = message.parkNum;
                    if (message.lendNum && message.lendNum.length) {
                        object.lendNum = [];
                        for (let j = 0; j < message.lendNum.length; ++j)
                            object.lendNum[j] = message.lendNum[j];
                    }
                    if (message.returnNum && message.returnNum.length) {
                        object.returnNum = [];
                        for (let j = 0; j < message.returnNum.length; ++j)
                            object.returnNum[j] = message.returnNum[j];
                    }
                    return object;
                };

                /**
                 * Converts this ParkPointWithWeight to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointWithWeight.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointWithWeight
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeight
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointWithWeight.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointWithWeight";
                };

                return ParkPointWithWeight;
            })();

            OuterClass.ParkPointWithWeightList = (function() {

                /**
                 * Properties of a ParkPointWithWeightList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointWithWeightList
                 * @property {Array.<com.bxkz.OuterClass.IParkPointWithWeight>|null} [data] ParkPointWithWeightList data
                 */

                /**
                 * Constructs a new ParkPointWithWeightList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointWithWeightList.
                 * @implements IParkPointWithWeightList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightList=} [properties] Properties to set
                 */
                function ParkPointWithWeightList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointWithWeightList data.
                 * @member {Array.<com.bxkz.OuterClass.IParkPointWithWeight>} data
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @instance
                 */
                ParkPointWithWeightList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ParkPointWithWeightList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightList} ParkPointWithWeightList instance
                 */
                ParkPointWithWeightList.create = function create(properties) {
                    return new ParkPointWithWeightList(properties);
                };

                /**
                 * Encodes the specified ParkPointWithWeightList message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightList} message ParkPointWithWeightList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ParkPointWithWeight.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointWithWeightList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightList} message ParkPointWithWeightList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointWithWeightList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightList} ParkPointWithWeightList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointWithWeightList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ParkPointWithWeight.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointWithWeightList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightList} ParkPointWithWeightList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointWithWeightList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointWithWeightList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ParkPointWithWeight.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ParkPointWithWeightList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightList} ParkPointWithWeightList
                 */
                ParkPointWithWeightList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointWithWeightList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointWithWeightList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeightList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeightList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ParkPointWithWeight.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointWithWeightList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightList} message ParkPointWithWeightList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointWithWeightList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ParkPointWithWeight.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ParkPointWithWeightList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointWithWeightList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointWithWeightList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointWithWeightList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointWithWeightList";
                };

                return ParkPointWithWeightList;
            })();

            OuterClass.ParkPointWithWeightRealTime = (function() {

                /**
                 * Constructs a new ParkPointWithWeightRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointWithWeightRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ParkPointWithWeightRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ParkPointWithWeightRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ParkPointWithWeightRealTime;

                /**
                 * Creates new ParkPointWithWeightRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ParkPointWithWeightRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                ParkPointWithWeightRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ParkPointWithWeightRealTime#service}.
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightList} [response] ParkPointWithWeightList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightRealTime.serviceCallback} callback Node-style callback called with the error, if any, and ParkPointWithWeightList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ParkPointWithWeightRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.ParkPointWithWeightList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ParkPointWithWeightList>} Promise
                 * @variation 2
                 */

                return ParkPointWithWeightRealTime;
            })();

            OuterClass.ParkPointWithWeightListWithTime = (function() {

                /**
                 * Properties of a ParkPointWithWeightListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointWithWeightListWithTime
                 * @property {com.bxkz.OuterClass.IParkPointWithWeightList|null} [data] ParkPointWithWeightListWithTime data
                 * @property {string|null} [dateTime] ParkPointWithWeightListWithTime dateTime
                 */

                /**
                 * Constructs a new ParkPointWithWeightListWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointWithWeightListWithTime.
                 * @implements IParkPointWithWeightListWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTime=} [properties] Properties to set
                 */
                function ParkPointWithWeightListWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointWithWeightListWithTime data.
                 * @member {com.bxkz.OuterClass.IParkPointWithWeightList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @instance
                 */
                ParkPointWithWeightListWithTime.prototype.data = null;

                /**
                 * ParkPointWithWeightListWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @instance
                 */
                ParkPointWithWeightListWithTime.prototype.dateTime = "";

                /**
                 * Creates a new ParkPointWithWeightListWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTime} ParkPointWithWeightListWithTime instance
                 */
                ParkPointWithWeightListWithTime.create = function create(properties) {
                    return new ParkPointWithWeightListWithTime(properties);
                };

                /**
                 * Encodes the specified ParkPointWithWeightListWithTime message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightListWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTime} message ParkPointWithWeightListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightListWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.ParkPointWithWeightList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointWithWeightListWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightListWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTime} message ParkPointWithWeightListWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightListWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointWithWeightListWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTime} ParkPointWithWeightListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightListWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.ParkPointWithWeightList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointWithWeightListWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTime} ParkPointWithWeightListWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightListWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointWithWeightListWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointWithWeightListWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.ParkPointWithWeightList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a ParkPointWithWeightListWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTime} ParkPointWithWeightListWithTime
                 */
                ParkPointWithWeightListWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeightListWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.ParkPointWithWeightList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointWithWeightListWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightListWithTime} message ParkPointWithWeightListWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointWithWeightListWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.ParkPointWithWeightList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this ParkPointWithWeightListWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointWithWeightListWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointWithWeightListWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointWithWeightListWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointWithWeightListWithTime";
                };

                return ParkPointWithWeightListWithTime;
            })();

            OuterClass.ParkPointWithWeightListWithTimeList = (function() {

                /**
                 * Properties of a ParkPointWithWeightListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IParkPointWithWeightListWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IParkPointWithWeightListWithTime>|null} [data] ParkPointWithWeightListWithTimeList data
                 */

                /**
                 * Constructs a new ParkPointWithWeightListWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointWithWeightListWithTimeList.
                 * @implements IParkPointWithWeightListWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTimeList=} [properties] Properties to set
                 */
                function ParkPointWithWeightListWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ParkPointWithWeightListWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IParkPointWithWeightListWithTime>} data
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @instance
                 */
                ParkPointWithWeightListWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ParkPointWithWeightListWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} ParkPointWithWeightListWithTimeList instance
                 */
                ParkPointWithWeightListWithTimeList.create = function create(properties) {
                    return new ParkPointWithWeightListWithTimeList(properties);
                };

                /**
                 * Encodes the specified ParkPointWithWeightListWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTimeList} message ParkPointWithWeightListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightListWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ParkPointWithWeightListWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IParkPointWithWeightListWithTimeList} message ParkPointWithWeightListWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ParkPointWithWeightListWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ParkPointWithWeightListWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} ParkPointWithWeightListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightListWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ParkPointWithWeightListWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} ParkPointWithWeightListWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ParkPointWithWeightListWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ParkPointWithWeightListWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ParkPointWithWeightListWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ParkPointWithWeightListWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} ParkPointWithWeightListWithTimeList
                 */
                ParkPointWithWeightListWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ParkPointWithWeightListWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} message ParkPointWithWeightListWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ParkPointWithWeightListWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ParkPointWithWeightListWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ParkPointWithWeightListWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ParkPointWithWeightListWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ParkPointWithWeightListWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList";
                };

                return ParkPointWithWeightListWithTimeList;
            })();

            OuterClass.ParkPointWithWeightHistory = (function() {

                /**
                 * Constructs a new ParkPointWithWeightHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ParkPointWithWeightHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ParkPointWithWeightHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ParkPointWithWeightHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ParkPointWithWeightHistory;

                /**
                 * Creates new ParkPointWithWeightHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ParkPointWithWeightHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                ParkPointWithWeightHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ParkPointWithWeightHistory#service}.
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList} [response] ParkPointWithWeightListWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.ParkPointWithWeightHistory.serviceCallback} callback Node-style callback called with the error, if any, and ParkPointWithWeightListWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ParkPointWithWeightHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ParkPointWithWeightHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ParkPointWithWeightListWithTimeList>} Promise
                 * @variation 2
                 */

                return ParkPointWithWeightHistory;
            })();

            OuterClass.ProhibitArea = (function() {

                /**
                 * Properties of a ProhibitArea.
                 * @memberof com.bxkz.OuterClass
                 * @interface IProhibitArea
                 * @property {number|null} [prohibitAreaId] ProhibitArea prohibitAreaId
                 * @property {number|null} [accountId] ProhibitArea accountId
                 * @property {string|null} [name] ProhibitArea name
                 * @property {number|null} [prohibitAreaType] ProhibitArea prohibitAreaType
                 * @property {string|null} [lonC] ProhibitArea lonC
                 * @property {string|null} [latC] ProhibitArea latC
                 * @property {number|null} [allowRange] ProhibitArea allowRange
                 * @property {string|null} [points] ProhibitArea points
                 * @property {string|null} [remark] ProhibitArea remark
                 * @property {string|null} [pointsC] ProhibitArea pointsC
                 * @property {number|null} [regionId] ProhibitArea regionId
                 * @property {string|null} [updateTime] ProhibitArea updateTime
                 */

                /**
                 * Constructs a new ProhibitArea.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 禁停区
                 * @implements IProhibitArea
                 * @constructor
                 * @param {com.bxkz.OuterClass.IProhibitArea=} [properties] Properties to set
                 */
                function ProhibitArea(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProhibitArea prohibitAreaId.
                 * @member {number} prohibitAreaId
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.prohibitAreaId = 0;

                /**
                 * ProhibitArea accountId.
                 * @member {number} accountId
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.accountId = 0;

                /**
                 * ProhibitArea name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.name = "";

                /**
                 * ProhibitArea prohibitAreaType.
                 * @member {number} prohibitAreaType
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.prohibitAreaType = 0;

                /**
                 * ProhibitArea lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.lonC = "";

                /**
                 * ProhibitArea latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.latC = "";

                /**
                 * ProhibitArea allowRange.
                 * @member {number} allowRange
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.allowRange = 0;

                /**
                 * ProhibitArea points.
                 * @member {string} points
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.points = "";

                /**
                 * ProhibitArea remark.
                 * @member {string} remark
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.remark = "";

                /**
                 * ProhibitArea pointsC.
                 * @member {string} pointsC
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.pointsC = "";

                /**
                 * ProhibitArea regionId.
                 * @member {number} regionId
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.regionId = 0;

                /**
                 * ProhibitArea updateTime.
                 * @member {string} updateTime
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 */
                ProhibitArea.prototype.updateTime = "";

                /**
                 * Creates a new ProhibitArea instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitArea=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ProhibitArea} ProhibitArea instance
                 */
                ProhibitArea.create = function create(properties) {
                    return new ProhibitArea(properties);
                };

                /**
                 * Encodes the specified ProhibitArea message. Does not implicitly {@link com.bxkz.OuterClass.ProhibitArea.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitArea} message ProhibitArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitArea.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.prohibitAreaId != null && Object.hasOwnProperty.call(message, "prohibitAreaId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.prohibitAreaId);
                    if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.accountId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.prohibitAreaType != null && Object.hasOwnProperty.call(message, "prohibitAreaType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.prohibitAreaType);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.latC);
                    if (message.allowRange != null && Object.hasOwnProperty.call(message, "allowRange"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.allowRange);
                    if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.points);
                    if (message.remark != null && Object.hasOwnProperty.call(message, "remark"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.remark);
                    if (message.pointsC != null && Object.hasOwnProperty.call(message, "pointsC"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.pointsC);
                    if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.regionId);
                    if (message.updateTime != null && Object.hasOwnProperty.call(message, "updateTime"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.updateTime);
                    return writer;
                };

                /**
                 * Encodes the specified ProhibitArea message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ProhibitArea.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitArea} message ProhibitArea message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitArea.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProhibitArea message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ProhibitArea} ProhibitArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitArea.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ProhibitArea();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.prohibitAreaId = reader.int32();
                                break;
                            }
                        case 2: {
                                message.accountId = reader.int32();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.prohibitAreaType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 6: {
                                message.latC = reader.string();
                                break;
                            }
                        case 7: {
                                message.allowRange = reader.int32();
                                break;
                            }
                        case 8: {
                                message.points = reader.string();
                                break;
                            }
                        case 9: {
                                message.remark = reader.string();
                                break;
                            }
                        case 10: {
                                message.pointsC = reader.string();
                                break;
                            }
                        case 11: {
                                message.regionId = reader.int32();
                                break;
                            }
                        case 12: {
                                message.updateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProhibitArea message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ProhibitArea} ProhibitArea
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitArea.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProhibitArea message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProhibitArea.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.prohibitAreaId != null && message.hasOwnProperty("prohibitAreaId"))
                        if (!$util.isInteger(message.prohibitAreaId))
                            return "prohibitAreaId: integer expected";
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        if (!$util.isInteger(message.accountId))
                            return "accountId: integer expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.prohibitAreaType != null && message.hasOwnProperty("prohibitAreaType"))
                        if (!$util.isInteger(message.prohibitAreaType))
                            return "prohibitAreaType: integer expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    if (message.allowRange != null && message.hasOwnProperty("allowRange"))
                        if (!$util.isInteger(message.allowRange))
                            return "allowRange: integer expected";
                    if (message.points != null && message.hasOwnProperty("points"))
                        if (!$util.isString(message.points))
                            return "points: string expected";
                    if (message.remark != null && message.hasOwnProperty("remark"))
                        if (!$util.isString(message.remark))
                            return "remark: string expected";
                    if (message.pointsC != null && message.hasOwnProperty("pointsC"))
                        if (!$util.isString(message.pointsC))
                            return "pointsC: string expected";
                    if (message.regionId != null && message.hasOwnProperty("regionId"))
                        if (!$util.isInteger(message.regionId))
                            return "regionId: integer expected";
                    if (message.updateTime != null && message.hasOwnProperty("updateTime"))
                        if (!$util.isString(message.updateTime))
                            return "updateTime: string expected";
                    return null;
                };

                /**
                 * Creates a ProhibitArea message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ProhibitArea} ProhibitArea
                 */
                ProhibitArea.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ProhibitArea)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ProhibitArea();
                    if (object.prohibitAreaId != null)
                        message.prohibitAreaId = object.prohibitAreaId | 0;
                    if (object.accountId != null)
                        message.accountId = object.accountId | 0;
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.prohibitAreaType != null)
                        message.prohibitAreaType = object.prohibitAreaType | 0;
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    if (object.allowRange != null)
                        message.allowRange = object.allowRange | 0;
                    if (object.points != null)
                        message.points = String(object.points);
                    if (object.remark != null)
                        message.remark = String(object.remark);
                    if (object.pointsC != null)
                        message.pointsC = String(object.pointsC);
                    if (object.regionId != null)
                        message.regionId = object.regionId | 0;
                    if (object.updateTime != null)
                        message.updateTime = String(object.updateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a ProhibitArea message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {com.bxkz.OuterClass.ProhibitArea} message ProhibitArea
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProhibitArea.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.prohibitAreaId = 0;
                        object.accountId = 0;
                        object.name = "";
                        object.prohibitAreaType = 0;
                        object.lonC = "";
                        object.latC = "";
                        object.allowRange = 0;
                        object.points = "";
                        object.remark = "";
                        object.pointsC = "";
                        object.regionId = 0;
                        object.updateTime = "";
                    }
                    if (message.prohibitAreaId != null && message.hasOwnProperty("prohibitAreaId"))
                        object.prohibitAreaId = message.prohibitAreaId;
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        object.accountId = message.accountId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.prohibitAreaType != null && message.hasOwnProperty("prohibitAreaType"))
                        object.prohibitAreaType = message.prohibitAreaType;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    if (message.allowRange != null && message.hasOwnProperty("allowRange"))
                        object.allowRange = message.allowRange;
                    if (message.points != null && message.hasOwnProperty("points"))
                        object.points = message.points;
                    if (message.remark != null && message.hasOwnProperty("remark"))
                        object.remark = message.remark;
                    if (message.pointsC != null && message.hasOwnProperty("pointsC"))
                        object.pointsC = message.pointsC;
                    if (message.regionId != null && message.hasOwnProperty("regionId"))
                        object.regionId = message.regionId;
                    if (message.updateTime != null && message.hasOwnProperty("updateTime"))
                        object.updateTime = message.updateTime;
                    return object;
                };

                /**
                 * Converts this ProhibitArea to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProhibitArea.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProhibitArea
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ProhibitArea
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProhibitArea.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ProhibitArea";
                };

                return ProhibitArea;
            })();

            OuterClass.ProhibitAreaList = (function() {

                /**
                 * Properties of a ProhibitAreaList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IProhibitAreaList
                 * @property {Array.<com.bxkz.OuterClass.IProhibitArea>|null} [data] ProhibitAreaList data
                 */

                /**
                 * Constructs a new ProhibitAreaList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ProhibitAreaList.
                 * @implements IProhibitAreaList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IProhibitAreaList=} [properties] Properties to set
                 */
                function ProhibitAreaList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProhibitAreaList data.
                 * @member {Array.<com.bxkz.OuterClass.IProhibitArea>} data
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @instance
                 */
                ProhibitAreaList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ProhibitAreaList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ProhibitAreaList} ProhibitAreaList instance
                 */
                ProhibitAreaList.create = function create(properties) {
                    return new ProhibitAreaList(properties);
                };

                /**
                 * Encodes the specified ProhibitAreaList message. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaList} message ProhibitAreaList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ProhibitArea.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProhibitAreaList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaList} message ProhibitAreaList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProhibitAreaList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ProhibitAreaList} ProhibitAreaList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ProhibitAreaList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ProhibitArea.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProhibitAreaList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ProhibitAreaList} ProhibitAreaList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProhibitAreaList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProhibitAreaList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ProhibitArea.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProhibitAreaList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ProhibitAreaList} ProhibitAreaList
                 */
                ProhibitAreaList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ProhibitAreaList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ProhibitAreaList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ProhibitAreaList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ProhibitAreaList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ProhibitArea.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProhibitAreaList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {com.bxkz.OuterClass.ProhibitAreaList} message ProhibitAreaList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProhibitAreaList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ProhibitArea.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProhibitAreaList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProhibitAreaList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProhibitAreaList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ProhibitAreaList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProhibitAreaList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ProhibitAreaList";
                };

                return ProhibitAreaList;
            })();

            OuterClass.ProhibitAreaRealTime = (function() {

                /**
                 * Constructs a new ProhibitAreaRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ProhibitAreaRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ProhibitAreaRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ProhibitAreaRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProhibitAreaRealTime;

                /**
                 * Creates new ProhibitAreaRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitAreaRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ProhibitAreaRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                ProhibitAreaRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ProhibitAreaRealTime#service}.
                 * @memberof com.bxkz.OuterClass.ProhibitAreaRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ProhibitAreaList} [response] ProhibitAreaList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ProhibitAreaRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.ProhibitAreaRealTime.serviceCallback} callback Node-style callback called with the error, if any, and ProhibitAreaList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProhibitAreaRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.ProhibitAreaList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ProhibitAreaRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ProhibitAreaList>} Promise
                 * @variation 2
                 */

                return ProhibitAreaRealTime;
            })();

            OuterClass.ProhibitAreaWithTime = (function() {

                /**
                 * Properties of a ProhibitAreaWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IProhibitAreaWithTime
                 * @property {com.bxkz.OuterClass.IProhibitAreaList|null} [data] ProhibitAreaWithTime data
                 * @property {string|null} [dateTime] ProhibitAreaWithTime dateTime
                 */

                /**
                 * Constructs a new ProhibitAreaWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ProhibitAreaWithTime.
                 * @implements IProhibitAreaWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTime=} [properties] Properties to set
                 */
                function ProhibitAreaWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProhibitAreaWithTime data.
                 * @member {com.bxkz.OuterClass.IProhibitAreaList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @instance
                 */
                ProhibitAreaWithTime.prototype.data = null;

                /**
                 * ProhibitAreaWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @instance
                 */
                ProhibitAreaWithTime.prototype.dateTime = "";

                /**
                 * Creates a new ProhibitAreaWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTime} ProhibitAreaWithTime instance
                 */
                ProhibitAreaWithTime.create = function create(properties) {
                    return new ProhibitAreaWithTime(properties);
                };

                /**
                 * Encodes the specified ProhibitAreaWithTime message. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTime} message ProhibitAreaWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.ProhibitAreaList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified ProhibitAreaWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTime} message ProhibitAreaWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProhibitAreaWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTime} ProhibitAreaWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ProhibitAreaWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.ProhibitAreaList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProhibitAreaWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTime} ProhibitAreaWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProhibitAreaWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProhibitAreaWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.ProhibitAreaList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a ProhibitAreaWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTime} ProhibitAreaWithTime
                 */
                ProhibitAreaWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ProhibitAreaWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ProhibitAreaWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.ProhibitAreaWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.ProhibitAreaList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a ProhibitAreaWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.ProhibitAreaWithTime} message ProhibitAreaWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProhibitAreaWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.ProhibitAreaList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this ProhibitAreaWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProhibitAreaWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProhibitAreaWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProhibitAreaWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ProhibitAreaWithTime";
                };

                return ProhibitAreaWithTime;
            })();

            OuterClass.ProhibitAreaWithTimeList = (function() {

                /**
                 * Properties of a ProhibitAreaWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IProhibitAreaWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IProhibitAreaWithTime>|null} [data] ProhibitAreaWithTimeList data
                 */

                /**
                 * Constructs a new ProhibitAreaWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ProhibitAreaWithTimeList.
                 * @implements IProhibitAreaWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTimeList=} [properties] Properties to set
                 */
                function ProhibitAreaWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProhibitAreaWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IProhibitAreaWithTime>} data
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @instance
                 */
                ProhibitAreaWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new ProhibitAreaWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTimeList} ProhibitAreaWithTimeList instance
                 */
                ProhibitAreaWithTimeList.create = function create(properties) {
                    return new ProhibitAreaWithTimeList(properties);
                };

                /**
                 * Encodes the specified ProhibitAreaWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTimeList} message ProhibitAreaWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.ProhibitAreaWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProhibitAreaWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.ProhibitAreaWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IProhibitAreaWithTimeList} message ProhibitAreaWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProhibitAreaWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProhibitAreaWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTimeList} ProhibitAreaWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.ProhibitAreaWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.ProhibitAreaWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProhibitAreaWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTimeList} ProhibitAreaWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProhibitAreaWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProhibitAreaWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProhibitAreaWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.ProhibitAreaWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProhibitAreaWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.ProhibitAreaWithTimeList} ProhibitAreaWithTimeList
                 */
                ProhibitAreaWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.ProhibitAreaWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.ProhibitAreaWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.ProhibitAreaWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.ProhibitAreaWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.ProhibitAreaWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProhibitAreaWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.ProhibitAreaWithTimeList} message ProhibitAreaWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProhibitAreaWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.ProhibitAreaWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProhibitAreaWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProhibitAreaWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProhibitAreaWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.ProhibitAreaWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProhibitAreaWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.ProhibitAreaWithTimeList";
                };

                return ProhibitAreaWithTimeList;
            })();

            OuterClass.ProhibitAreaHistory = (function() {

                /**
                 * Constructs a new ProhibitAreaHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a ProhibitAreaHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ProhibitAreaHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ProhibitAreaHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProhibitAreaHistory;

                /**
                 * Creates new ProhibitAreaHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.ProhibitAreaHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ProhibitAreaHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                ProhibitAreaHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.ProhibitAreaHistory#service}.
                 * @memberof com.bxkz.OuterClass.ProhibitAreaHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.ProhibitAreaWithTimeList} [response] ProhibitAreaWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ProhibitAreaHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.ProhibitAreaHistory.serviceCallback} callback Node-style callback called with the error, if any, and ProhibitAreaWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProhibitAreaHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.ProhibitAreaWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.ProhibitAreaHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.ProhibitAreaWithTimeList>} Promise
                 * @variation 2
                 */

                return ProhibitAreaHistory;
            })();

            /**
             * Service enum.
             * @name com.bxkz.OuterClass.Service
             * @enum {number}
             * @property {number} Ser_WEATHER=0 Ser_WEATHER value
             * @property {number} Ser_UN_USED=1 Ser_UN_USED value
             * @property {number} Ser_RIDING_BIKE=2 Ser_RIDING_BIKE value
             * @property {number} Ser_PROHIBIT_AREA=3 Ser_PROHIBIT_AREA value
             * @property {number} Ser_PARK_POINT_WITH_WEIGHT=4 Ser_PARK_POINT_WITH_WEIGHT value
             * @property {number} Ser_PARK_POINT=5 Ser_PARK_POINT value
             * @property {number} Ser_NO_POWER=6 Ser_NO_POWER value
             * @property {number} Ser_MAINTENANCE_WORKER_TRACK=7 Ser_MAINTENANCE_WORKER_TRACK value
             * @property {number} Ser_MAINTENANCE_WORKER_STATISTICS=8 Ser_MAINTENANCE_WORKER_STATISTICS value
             * @property {number} Ser_MAINTENANCE_WORKER=9 Ser_MAINTENANCE_WORKER value
             * @property {number} Ser_AB_NORMAL_STATISTICS=10 Ser_AB_NORMAL_STATISTICS value
             * @property {number} Ser_AB_NORMAL_ALL_FAULT=11 Ser_AB_NORMAL_ALL_FAULT value
             */
            OuterClass.Service = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Ser_WEATHER"] = 0;
                values[valuesById[1] = "Ser_UN_USED"] = 1;
                values[valuesById[2] = "Ser_RIDING_BIKE"] = 2;
                values[valuesById[3] = "Ser_PROHIBIT_AREA"] = 3;
                values[valuesById[4] = "Ser_PARK_POINT_WITH_WEIGHT"] = 4;
                values[valuesById[5] = "Ser_PARK_POINT"] = 5;
                values[valuesById[6] = "Ser_NO_POWER"] = 6;
                values[valuesById[7] = "Ser_MAINTENANCE_WORKER_TRACK"] = 7;
                values[valuesById[8] = "Ser_MAINTENANCE_WORKER_STATISTICS"] = 8;
                values[valuesById[9] = "Ser_MAINTENANCE_WORKER"] = 9;
                values[valuesById[10] = "Ser_AB_NORMAL_STATISTICS"] = 10;
                values[valuesById[11] = "Ser_AB_NORMAL_ALL_FAULT"] = 11;
                return values;
            })();

            OuterClass.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof com.bxkz.OuterClass
                 * @interface IRequest
                 * @property {com.bxkz.OuterClass.Service|null} [service] Request service
                 * @property {com.bxkz.OuterClass.IHistoryParam|null} [params] Request params
                 */

                /**
                 * Constructs a new Request.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {com.bxkz.OuterClass.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request service.
                 * @member {com.bxkz.OuterClass.Service} service
                 * @memberof com.bxkz.OuterClass.Request
                 * @instance
                 */
                Request.prototype.service = 0;

                /**
                 * Request params.
                 * @member {com.bxkz.OuterClass.IHistoryParam|null|undefined} params
                 * @memberof com.bxkz.OuterClass.Request
                 * @instance
                 */
                Request.prototype.params = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {com.bxkz.OuterClass.IRequest=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link com.bxkz.OuterClass.Request.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {com.bxkz.OuterClass.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.service != null && Object.hasOwnProperty.call(message, "service"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.service);
                    if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                        $root.com.bxkz.OuterClass.HistoryParam.encode(message.params, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {com.bxkz.OuterClass.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.Request();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.service = reader.int32();
                                break;
                            }
                        case 2: {
                                message.params = $root.com.bxkz.OuterClass.HistoryParam.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.service != null && message.hasOwnProperty("service"))
                        switch (message.service) {
                        default:
                            return "service: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                            break;
                        }
                    if (message.params != null && message.hasOwnProperty("params")) {
                        let error = $root.com.bxkz.OuterClass.HistoryParam.verify(message.params);
                        if (error)
                            return "params." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.Request)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.Request();
                    switch (object.service) {
                    default:
                        if (typeof object.service === "number") {
                            message.service = object.service;
                            break;
                        }
                        break;
                    case "Ser_WEATHER":
                    case 0:
                        message.service = 0;
                        break;
                    case "Ser_UN_USED":
                    case 1:
                        message.service = 1;
                        break;
                    case "Ser_RIDING_BIKE":
                    case 2:
                        message.service = 2;
                        break;
                    case "Ser_PROHIBIT_AREA":
                    case 3:
                        message.service = 3;
                        break;
                    case "Ser_PARK_POINT_WITH_WEIGHT":
                    case 4:
                        message.service = 4;
                        break;
                    case "Ser_PARK_POINT":
                    case 5:
                        message.service = 5;
                        break;
                    case "Ser_NO_POWER":
                    case 6:
                        message.service = 6;
                        break;
                    case "Ser_MAINTENANCE_WORKER_TRACK":
                    case 7:
                        message.service = 7;
                        break;
                    case "Ser_MAINTENANCE_WORKER_STATISTICS":
                    case 8:
                        message.service = 8;
                        break;
                    case "Ser_MAINTENANCE_WORKER":
                    case 9:
                        message.service = 9;
                        break;
                    case "Ser_AB_NORMAL_STATISTICS":
                    case 10:
                        message.service = 10;
                        break;
                    case "Ser_AB_NORMAL_ALL_FAULT":
                    case 11:
                        message.service = 11;
                        break;
                    }
                    if (object.params != null) {
                        if (typeof object.params !== "object")
                            throw TypeError(".com.bxkz.OuterClass.Request.params: object expected");
                        message.params = $root.com.bxkz.OuterClass.HistoryParam.fromObject(object.params);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {com.bxkz.OuterClass.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.service = options.enums === String ? "Ser_WEATHER" : 0;
                        object.params = null;
                    }
                    if (message.service != null && message.hasOwnProperty("service"))
                        object.service = options.enums === String ? $root.com.bxkz.OuterClass.Service[message.service] === undefined ? message.service : $root.com.bxkz.OuterClass.Service[message.service] : message.service;
                    if (message.params != null && message.hasOwnProperty("params"))
                        object.params = $root.com.bxkz.OuterClass.HistoryParam.toObject(message.params, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.Request";
                };

                return Request;
            })();

            /**
             * StateCode enum.
             * @name com.bxkz.OuterClass.StateCode
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} SERVER_ERROR=1 SERVER_ERROR value
             * @property {number} CLIENT_ERROR=2 CLIENT_ERROR value
             */
            OuterClass.StateCode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "SERVER_ERROR"] = 1;
                values[valuesById[2] = "CLIENT_ERROR"] = 2;
                return values;
            })();

            OuterClass.Resp = (function() {

                /**
                 * Properties of a Resp.
                 * @memberof com.bxkz.OuterClass
                 * @interface IResp
                 * @property {com.bxkz.OuterClass.StateCode|null} [code] Resp code
                 * @property {google.protobuf.IAny|null} [data] Resp data
                 */

                /**
                 * Constructs a new Resp.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {com.bxkz.OuterClass.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Resp code.
                 * @member {com.bxkz.OuterClass.StateCode} code
                 * @memberof com.bxkz.OuterClass.Resp
                 * @instance
                 */
                Resp.prototype.code = 0;

                /**
                 * Resp data.
                 * @member {google.protobuf.IAny|null|undefined} data
                 * @memberof com.bxkz.OuterClass.Resp
                 * @instance
                 */
                Resp.prototype.data = null;

                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {com.bxkz.OuterClass.IResp=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };

                /**
                 * Encodes the specified Resp message. Does not implicitly {@link com.bxkz.OuterClass.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {com.bxkz.OuterClass.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.google.protobuf.Any.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {com.bxkz.OuterClass.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.Resp();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.code = reader.int32();
                                break;
                            }
                        case 2: {
                                message.data = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        switch (message.code) {
                        default:
                            return "code: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.google.protobuf.Any.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.Resp)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.Resp();
                    switch (object.code) {
                    default:
                        if (typeof object.code === "number") {
                            message.code = object.code;
                            break;
                        }
                        break;
                    case "SUCCESS":
                    case 0:
                        message.code = 0;
                        break;
                    case "SERVER_ERROR":
                    case 1:
                        message.code = 1;
                        break;
                    case "CLIENT_ERROR":
                    case 2:
                        message.code = 2;
                        break;
                    }
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.Resp.data: object expected");
                        message.data = $root.google.protobuf.Any.fromObject(object.data);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {com.bxkz.OuterClass.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.code = options.enums === String ? "SUCCESS" : 0;
                        object.data = null;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = options.enums === String ? $root.com.bxkz.OuterClass.StateCode[message.code] === undefined ? message.code : $root.com.bxkz.OuterClass.StateCode[message.code] : message.code;
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.google.protobuf.Any.toObject(message.data, options);
                    return object;
                };

                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Resp
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.Resp
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Resp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.Resp";
                };

                return Resp;
            })();

            OuterClass.RidingBike = (function() {

                /**
                 * Properties of a RidingBike.
                 * @memberof com.bxkz.OuterClass
                 * @interface IRidingBike
                 * @property {string|null} [machineId] RidingBike machineId
                 * @property {string|null} [userCode] RidingBike userCode
                 * @property {number|null} [soc] RidingBike soc
                 * @property {number|null} [times] RidingBike times
                 * @property {number|null} [minutes] RidingBike minutes
                 * @property {string|null} [lonC] RidingBike lonC
                 * @property {string|null} [latC] RidingBike latC
                 * @property {boolean|null} [machineFault] RidingBike machineFault
                 * @property {string|null} [startRideTime] RidingBike startRideTime
                 * @property {string|null} [phone] RidingBike phone
                 */

                /**
                 * Constructs a new RidingBike.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 骑行中车辆信息集合
                 * @implements IRidingBike
                 * @constructor
                 * @param {com.bxkz.OuterClass.IRidingBike=} [properties] Properties to set
                 */
                function RidingBike(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RidingBike machineId.
                 * @member {string} machineId
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.machineId = "";

                /**
                 * RidingBike userCode.
                 * @member {string} userCode
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.userCode = "";

                /**
                 * RidingBike soc.
                 * @member {number} soc
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.soc = 0;

                /**
                 * RidingBike times.
                 * @member {number} times
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.times = 0;

                /**
                 * RidingBike minutes.
                 * @member {number} minutes
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.minutes = 0;

                /**
                 * RidingBike lonC.
                 * @member {string} lonC
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.lonC = "";

                /**
                 * RidingBike latC.
                 * @member {string} latC
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.latC = "";

                /**
                 * RidingBike machineFault.
                 * @member {boolean} machineFault
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.machineFault = false;

                /**
                 * RidingBike startRideTime.
                 * @member {string} startRideTime
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.startRideTime = "";

                /**
                 * RidingBike phone.
                 * @member {string} phone
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 */
                RidingBike.prototype.phone = "";

                /**
                 * Creates a new RidingBike instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBike=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.RidingBike} RidingBike instance
                 */
                RidingBike.create = function create(properties) {
                    return new RidingBike(properties);
                };

                /**
                 * Encodes the specified RidingBike message. Does not implicitly {@link com.bxkz.OuterClass.RidingBike.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBike} message RidingBike message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBike.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.machineId != null && Object.hasOwnProperty.call(message, "machineId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.machineId);
                    if (message.userCode != null && Object.hasOwnProperty.call(message, "userCode"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.userCode);
                    if (message.soc != null && Object.hasOwnProperty.call(message, "soc"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.soc);
                    if (message.times != null && Object.hasOwnProperty.call(message, "times"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.times);
                    if (message.minutes != null && Object.hasOwnProperty.call(message, "minutes"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.minutes);
                    if (message.lonC != null && Object.hasOwnProperty.call(message, "lonC"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.lonC);
                    if (message.latC != null && Object.hasOwnProperty.call(message, "latC"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.latC);
                    if (message.startRideTime != null && Object.hasOwnProperty.call(message, "startRideTime"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.startRideTime);
                    if (message.machineFault != null && Object.hasOwnProperty.call(message, "machineFault"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.machineFault);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.phone);
                    return writer;
                };

                /**
                 * Encodes the specified RidingBike message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.RidingBike.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBike} message RidingBike message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBike.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RidingBike message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.RidingBike} RidingBike
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBike.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.RidingBike();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.machineId = reader.string();
                                break;
                            }
                        case 3: {
                                message.userCode = reader.string();
                                break;
                            }
                        case 4: {
                                message.soc = reader.int32();
                                break;
                            }
                        case 5: {
                                message.times = reader.int32();
                                break;
                            }
                        case 6: {
                                message.minutes = reader.int32();
                                break;
                            }
                        case 7: {
                                message.lonC = reader.string();
                                break;
                            }
                        case 8: {
                                message.latC = reader.string();
                                break;
                            }
                        case 11: {
                                message.machineFault = reader.bool();
                                break;
                            }
                        case 10: {
                                message.startRideTime = reader.string();
                                break;
                            }
                        case 12: {
                                message.phone = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RidingBike message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.RidingBike} RidingBike
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBike.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RidingBike message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RidingBike.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.machineId != null && message.hasOwnProperty("machineId"))
                        if (!$util.isString(message.machineId))
                            return "machineId: string expected";
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        if (!$util.isString(message.userCode))
                            return "userCode: string expected";
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        if (!$util.isInteger(message.soc))
                            return "soc: integer expected";
                    if (message.times != null && message.hasOwnProperty("times"))
                        if (!$util.isInteger(message.times))
                            return "times: integer expected";
                    if (message.minutes != null && message.hasOwnProperty("minutes"))
                        if (!$util.isInteger(message.minutes))
                            return "minutes: integer expected";
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        if (!$util.isString(message.lonC))
                            return "lonC: string expected";
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        if (!$util.isString(message.latC))
                            return "latC: string expected";
                    if (message.machineFault != null && message.hasOwnProperty("machineFault"))
                        if (typeof message.machineFault !== "boolean")
                            return "machineFault: boolean expected";
                    if (message.startRideTime != null && message.hasOwnProperty("startRideTime"))
                        if (!$util.isString(message.startRideTime))
                            return "startRideTime: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    return null;
                };

                /**
                 * Creates a RidingBike message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.RidingBike} RidingBike
                 */
                RidingBike.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.RidingBike)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.RidingBike();
                    if (object.machineId != null)
                        message.machineId = String(object.machineId);
                    if (object.userCode != null)
                        message.userCode = String(object.userCode);
                    if (object.soc != null)
                        message.soc = object.soc | 0;
                    if (object.times != null)
                        message.times = object.times | 0;
                    if (object.minutes != null)
                        message.minutes = object.minutes | 0;
                    if (object.lonC != null)
                        message.lonC = String(object.lonC);
                    if (object.latC != null)
                        message.latC = String(object.latC);
                    if (object.machineFault != null)
                        message.machineFault = Boolean(object.machineFault);
                    if (object.startRideTime != null)
                        message.startRideTime = String(object.startRideTime);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    return message;
                };

                /**
                 * Creates a plain object from a RidingBike message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {com.bxkz.OuterClass.RidingBike} message RidingBike
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RidingBike.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.machineId = "";
                        object.userCode = "";
                        object.soc = 0;
                        object.times = 0;
                        object.minutes = 0;
                        object.lonC = "";
                        object.latC = "";
                        object.startRideTime = "";
                        object.machineFault = false;
                        object.phone = "";
                    }
                    if (message.machineId != null && message.hasOwnProperty("machineId"))
                        object.machineId = message.machineId;
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        object.userCode = message.userCode;
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        object.soc = message.soc;
                    if (message.times != null && message.hasOwnProperty("times"))
                        object.times = message.times;
                    if (message.minutes != null && message.hasOwnProperty("minutes"))
                        object.minutes = message.minutes;
                    if (message.lonC != null && message.hasOwnProperty("lonC"))
                        object.lonC = message.lonC;
                    if (message.latC != null && message.hasOwnProperty("latC"))
                        object.latC = message.latC;
                    if (message.startRideTime != null && message.hasOwnProperty("startRideTime"))
                        object.startRideTime = message.startRideTime;
                    if (message.machineFault != null && message.hasOwnProperty("machineFault"))
                        object.machineFault = message.machineFault;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    return object;
                };

                /**
                 * Converts this RidingBike to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RidingBike.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RidingBike
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.RidingBike
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RidingBike.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.RidingBike";
                };

                return RidingBike;
            })();

            OuterClass.RidingBikeList = (function() {

                /**
                 * Properties of a RidingBikeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IRidingBikeList
                 * @property {Array.<com.bxkz.OuterClass.IRidingBike>|null} [data] RidingBikeList data
                 */

                /**
                 * Constructs a new RidingBikeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 骑行中车辆信息集合
                 * @implements IRidingBikeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IRidingBikeList=} [properties] Properties to set
                 */
                function RidingBikeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RidingBikeList data.
                 * @member {Array.<com.bxkz.OuterClass.IRidingBike>} data
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @instance
                 */
                RidingBikeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new RidingBikeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.RidingBikeList} RidingBikeList instance
                 */
                RidingBikeList.create = function create(properties) {
                    return new RidingBikeList(properties);
                };

                /**
                 * Encodes the specified RidingBikeList message. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeList} message RidingBikeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.RidingBike.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RidingBikeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeList} message RidingBikeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RidingBikeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.RidingBikeList} RidingBikeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.RidingBikeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.RidingBike.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RidingBikeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.RidingBikeList} RidingBikeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RidingBikeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RidingBikeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.RidingBike.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a RidingBikeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.RidingBikeList} RidingBikeList
                 */
                RidingBikeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.RidingBikeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.RidingBikeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.RidingBikeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.RidingBikeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.RidingBike.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RidingBikeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {com.bxkz.OuterClass.RidingBikeList} message RidingBikeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RidingBikeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.RidingBike.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this RidingBikeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RidingBikeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RidingBikeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.RidingBikeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RidingBikeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.RidingBikeList";
                };

                return RidingBikeList;
            })();

            OuterClass.RidingBikeRealTime = (function() {

                /**
                 * Constructs a new RidingBikeRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 实时的骑行中车辆信息集合接口
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function RidingBikeRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (RidingBikeRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RidingBikeRealTime;

                /**
                 * Creates new RidingBikeRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingBikeRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {RidingBikeRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                RidingBikeRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.RidingBikeRealTime#service}.
                 * @memberof com.bxkz.OuterClass.RidingBikeRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.RidingBikeList} [response] RidingBikeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.RidingBikeRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.RidingBikeRealTime.serviceCallback} callback Node-style callback called with the error, if any, and RidingBikeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(RidingBikeRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.RidingBikeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.RidingBikeRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.RidingBikeList>} Promise
                 * @variation 2
                 */

                return RidingBikeRealTime;
            })();

            OuterClass.RidingBikeWithTime = (function() {

                /**
                 * Properties of a RidingBikeWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IRidingBikeWithTime
                 * @property {com.bxkz.OuterClass.IRidingBikeList|null} [data] RidingBikeWithTime data
                 * @property {string|null} [dateTime] RidingBikeWithTime dateTime
                 */

                /**
                 * Constructs a new RidingBikeWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 骑行中车辆信息集合的历史记录
                 * @implements IRidingBikeWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTime=} [properties] Properties to set
                 */
                function RidingBikeWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RidingBikeWithTime data.
                 * @member {com.bxkz.OuterClass.IRidingBikeList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @instance
                 */
                RidingBikeWithTime.prototype.data = null;

                /**
                 * RidingBikeWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @instance
                 */
                RidingBikeWithTime.prototype.dateTime = "";

                /**
                 * Creates a new RidingBikeWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTime} RidingBikeWithTime instance
                 */
                RidingBikeWithTime.create = function create(properties) {
                    return new RidingBikeWithTime(properties);
                };

                /**
                 * Encodes the specified RidingBikeWithTime message. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTime} message RidingBikeWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.RidingBikeList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified RidingBikeWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTime} message RidingBikeWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RidingBikeWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTime} RidingBikeWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.RidingBikeWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.RidingBikeList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RidingBikeWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTime} RidingBikeWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RidingBikeWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RidingBikeWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.RidingBikeList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a RidingBikeWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTime} RidingBikeWithTime
                 */
                RidingBikeWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.RidingBikeWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.RidingBikeWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.RidingBikeWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.RidingBikeList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a RidingBikeWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.RidingBikeWithTime} message RidingBikeWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RidingBikeWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.RidingBikeList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this RidingBikeWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RidingBikeWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RidingBikeWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RidingBikeWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.RidingBikeWithTime";
                };

                return RidingBikeWithTime;
            })();

            OuterClass.RidingBikeWithTimeList = (function() {

                /**
                 * Properties of a RidingBikeWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IRidingBikeWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IRidingBikeWithTime>|null} [data] RidingBikeWithTimeList data
                 */

                /**
                 * Constructs a new RidingBikeWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a RidingBikeWithTimeList.
                 * @implements IRidingBikeWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTimeList=} [properties] Properties to set
                 */
                function RidingBikeWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RidingBikeWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IRidingBikeWithTime>} data
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @instance
                 */
                RidingBikeWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new RidingBikeWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTimeList} RidingBikeWithTimeList instance
                 */
                RidingBikeWithTimeList.create = function create(properties) {
                    return new RidingBikeWithTimeList(properties);
                };

                /**
                 * Encodes the specified RidingBikeWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTimeList} message RidingBikeWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.RidingBikeWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RidingBikeWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.RidingBikeWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IRidingBikeWithTimeList} message RidingBikeWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RidingBikeWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RidingBikeWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTimeList} RidingBikeWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.RidingBikeWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.RidingBikeWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RidingBikeWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTimeList} RidingBikeWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RidingBikeWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RidingBikeWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RidingBikeWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.RidingBikeWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a RidingBikeWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.RidingBikeWithTimeList} RidingBikeWithTimeList
                 */
                RidingBikeWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.RidingBikeWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.RidingBikeWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.RidingBikeWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.RidingBikeWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.RidingBikeWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RidingBikeWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.RidingBikeWithTimeList} message RidingBikeWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RidingBikeWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.RidingBikeWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this RidingBikeWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RidingBikeWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RidingBikeWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.RidingBikeWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RidingBikeWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.RidingBikeWithTimeList";
                };

                return RidingBikeWithTimeList;
            })();

            OuterClass.RidingHistory = (function() {

                /**
                 * Constructs a new RidingHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 骑行中车辆信息集合的历史记录接口
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function RidingHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (RidingHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RidingHistory;

                /**
                 * Creates new RidingHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.RidingHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {RidingHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                RidingHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.RidingHistory#service}.
                 * @memberof com.bxkz.OuterClass.RidingHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.RidingBikeWithTimeList} [response] RidingBikeWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.RidingHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.RidingHistory.serviceCallback} callback Node-style callback called with the error, if any, and RidingBikeWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(RidingHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.RidingBikeWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.RidingHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.RidingBikeWithTimeList>} Promise
                 * @variation 2
                 */

                return RidingHistory;
            })();

            OuterClass._OneOf = (function() {

                /**
                 * Properties of a _OneOf.
                 * @memberof com.bxkz.OuterClass
                 * @interface I_OneOf
                 * @property {string|null} [name] _OneOf name
                 * @property {number|null} [age] _OneOf age
                 * @property {string|null} [address] _OneOf address
                 */

                /**
                 * Constructs a new _OneOf.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a _OneOf.
                 * @implements I_OneOf
                 * @constructor
                 * @param {com.bxkz.OuterClass.I_OneOf=} [properties] Properties to set
                 */
                function _OneOf(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * _OneOf name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @instance
                 */
                _OneOf.prototype.name = "";

                /**
                 * _OneOf age.
                 * @member {number|null|undefined} age
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @instance
                 */
                _OneOf.prototype.age = null;

                /**
                 * _OneOf address.
                 * @member {string|null|undefined} address
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @instance
                 */
                _OneOf.prototype.address = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * _OneOf details.
                 * @member {"age"|"address"|undefined} details
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @instance
                 */
                Object.defineProperty(_OneOf.prototype, "details", {
                    get: $util.oneOfGetter($oneOfFields = ["age", "address"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new _OneOf instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {com.bxkz.OuterClass.I_OneOf=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass._OneOf} _OneOf instance
                 */
                _OneOf.create = function create(properties) {
                    return new _OneOf(properties);
                };

                /**
                 * Encodes the specified _OneOf message. Does not implicitly {@link com.bxkz.OuterClass._OneOf.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {com.bxkz.OuterClass.I_OneOf} message _OneOf message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                _OneOf.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.age != null && Object.hasOwnProperty.call(message, "age"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.age);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    return writer;
                };

                /**
                 * Encodes the specified _OneOf message, length delimited. Does not implicitly {@link com.bxkz.OuterClass._OneOf.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {com.bxkz.OuterClass.I_OneOf} message _OneOf message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                _OneOf.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a _OneOf message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass._OneOf} _OneOf
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                _OneOf.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass._OneOf();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.age = reader.int32();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a _OneOf message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass._OneOf} _OneOf
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                _OneOf.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a _OneOf message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                _OneOf.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.age != null && message.hasOwnProperty("age")) {
                        properties.details = 1;
                        if (!$util.isInteger(message.age))
                            return "age: integer expected";
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        if (properties.details === 1)
                            return "details: multiple values";
                        properties.details = 1;
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a _OneOf message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass._OneOf} _OneOf
                 */
                _OneOf.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass._OneOf)
                        return object;
                    let message = new $root.com.bxkz.OuterClass._OneOf();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.age != null)
                        message.age = object.age | 0;
                    if (object.address != null)
                        message.address = String(object.address);
                    return message;
                };

                /**
                 * Creates a plain object from a _OneOf message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {com.bxkz.OuterClass._OneOf} message _OneOf
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                _OneOf.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.age != null && message.hasOwnProperty("age")) {
                        object.age = message.age;
                        if (options.oneofs)
                            object.details = "age";
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        object.address = message.address;
                        if (options.oneofs)
                            object.details = "address";
                    }
                    return object;
                };

                /**
                 * Converts this _OneOf to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                _OneOf.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for _OneOf
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass._OneOf
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                _OneOf.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass._OneOf";
                };

                return _OneOf;
            })();

            OuterClass._Normal = (function() {

                /**
                 * Properties of a _Normal.
                 * @memberof com.bxkz.OuterClass
                 * @interface I_Normal
                 * @property {string|null} [name] _Normal name
                 * @property {number|null} [age] _Normal age
                 * @property {string|null} [address] _Normal address
                 */

                /**
                 * Constructs a new _Normal.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a _Normal.
                 * @implements I_Normal
                 * @constructor
                 * @param {com.bxkz.OuterClass.I_Normal=} [properties] Properties to set
                 */
                function _Normal(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * _Normal name.
                 * @member {string} name
                 * @memberof com.bxkz.OuterClass._Normal
                 * @instance
                 */
                _Normal.prototype.name = "";

                /**
                 * _Normal age.
                 * @member {number} age
                 * @memberof com.bxkz.OuterClass._Normal
                 * @instance
                 */
                _Normal.prototype.age = 0;

                /**
                 * _Normal address.
                 * @member {string} address
                 * @memberof com.bxkz.OuterClass._Normal
                 * @instance
                 */
                _Normal.prototype.address = "";

                /**
                 * Creates a new _Normal instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {com.bxkz.OuterClass.I_Normal=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass._Normal} _Normal instance
                 */
                _Normal.create = function create(properties) {
                    return new _Normal(properties);
                };

                /**
                 * Encodes the specified _Normal message. Does not implicitly {@link com.bxkz.OuterClass._Normal.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {com.bxkz.OuterClass.I_Normal} message _Normal message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                _Normal.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.age != null && Object.hasOwnProperty.call(message, "age"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.age);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    return writer;
                };

                /**
                 * Encodes the specified _Normal message, length delimited. Does not implicitly {@link com.bxkz.OuterClass._Normal.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {com.bxkz.OuterClass.I_Normal} message _Normal message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                _Normal.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a _Normal message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass._Normal} _Normal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                _Normal.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass._Normal();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.age = reader.int32();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a _Normal message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass._Normal} _Normal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                _Normal.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a _Normal message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                _Normal.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.age != null && message.hasOwnProperty("age"))
                        if (!$util.isInteger(message.age))
                            return "age: integer expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    return null;
                };

                /**
                 * Creates a _Normal message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass._Normal} _Normal
                 */
                _Normal.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass._Normal)
                        return object;
                    let message = new $root.com.bxkz.OuterClass._Normal();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.age != null)
                        message.age = object.age | 0;
                    if (object.address != null)
                        message.address = String(object.address);
                    return message;
                };

                /**
                 * Creates a plain object from a _Normal message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {com.bxkz.OuterClass._Normal} message _Normal
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                _Normal.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.age = 0;
                        object.address = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.age != null && message.hasOwnProperty("age"))
                        object.age = message.age;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    return object;
                };

                /**
                 * Converts this _Normal to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass._Normal
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                _Normal.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for _Normal
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass._Normal
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                _Normal.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass._Normal";
                };

                return _Normal;
            })();

            OuterClass.Unused = (function() {

                /**
                 * Properties of an Unused.
                 * @memberof com.bxkz.OuterClass
                 * @interface IUnused
                 * @property {string|null} [userCode] Unused userCode
                 * @property {string|null} [parkPoint] Unused parkPoint
                 * @property {string|null} [soc] Unused soc
                 * @property {string|null} [lastUsedDateTime] Unused lastUsedDateTime
                 * @property {string|null} [noUseHour] Unused noUseHour
                 * @property {string|null} [parkPointId] Unused parkPointId
                 * @property {string|null} [lon] Unused lon
                 * @property {string|null} [lat] Unused lat
                 * @property {string|null} [posDt] Unused posDt
                 * @property {string|null} [batDt] Unused batDt
                 * @property {string|null} [noOrderHour] Unused noOrderHour
                 * @property {boolean|null} [isAbnormalFromAdmin] Unused isAbnormalFromAdmin
                 */

                /**
                 * Constructs a new Unused.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an Unused.
                 * @implements IUnused
                 * @constructor
                 * @param {com.bxkz.OuterClass.IUnused=} [properties] Properties to set
                 */
                function Unused(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Unused userCode.
                 * @member {string} userCode
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.userCode = "";

                /**
                 * Unused parkPoint.
                 * @member {string} parkPoint
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.parkPoint = "";

                /**
                 * Unused soc.
                 * @member {string} soc
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.soc = "";

                /**
                 * Unused lastUsedDateTime.
                 * @member {string} lastUsedDateTime
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.lastUsedDateTime = "";

                /**
                 * Unused noUseHour.
                 * @member {string} noUseHour
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.noUseHour = "";

                /**
                 * Unused parkPointId.
                 * @member {string} parkPointId
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.parkPointId = "";

                /**
                 * Unused lon.
                 * @member {string} lon
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.lon = "";

                /**
                 * Unused lat.
                 * @member {string} lat
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.lat = "";

                /**
                 * Unused posDt.
                 * @member {string} posDt
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.posDt = "";

                /**
                 * Unused batDt.
                 * @member {string} batDt
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.batDt = "";

                /**
                 * Unused noOrderHour.
                 * @member {string} noOrderHour
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.noOrderHour = "";

                /**
                 * Unused isAbnormalFromAdmin.
                 * @member {boolean} isAbnormalFromAdmin
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 */
                Unused.prototype.isAbnormalFromAdmin = false;

                /**
                 * Creates a new Unused instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {com.bxkz.OuterClass.IUnused=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.Unused} Unused instance
                 */
                Unused.create = function create(properties) {
                    return new Unused(properties);
                };

                /**
                 * Encodes the specified Unused message. Does not implicitly {@link com.bxkz.OuterClass.Unused.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {com.bxkz.OuterClass.IUnused} message Unused message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Unused.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userCode != null && Object.hasOwnProperty.call(message, "userCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userCode);
                    if (message.parkPoint != null && Object.hasOwnProperty.call(message, "parkPoint"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.parkPoint);
                    if (message.soc != null && Object.hasOwnProperty.call(message, "soc"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.soc);
                    if (message.lastUsedDateTime != null && Object.hasOwnProperty.call(message, "lastUsedDateTime"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastUsedDateTime);
                    if (message.noUseHour != null && Object.hasOwnProperty.call(message, "noUseHour"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.noUseHour);
                    if (message.parkPointId != null && Object.hasOwnProperty.call(message, "parkPointId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.parkPointId);
                    if (message.lon != null && Object.hasOwnProperty.call(message, "lon"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.lon);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.lat);
                    if (message.posDt != null && Object.hasOwnProperty.call(message, "posDt"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.posDt);
                    if (message.batDt != null && Object.hasOwnProperty.call(message, "batDt"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.batDt);
                    if (message.noOrderHour != null && Object.hasOwnProperty.call(message, "noOrderHour"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.noOrderHour);
                    if (message.isAbnormalFromAdmin != null && Object.hasOwnProperty.call(message, "isAbnormalFromAdmin"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isAbnormalFromAdmin);
                    return writer;
                };

                /**
                 * Encodes the specified Unused message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.Unused.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {com.bxkz.OuterClass.IUnused} message Unused message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Unused.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Unused message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.Unused} Unused
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Unused.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.Unused();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.parkPoint = reader.string();
                                break;
                            }
                        case 3: {
                                message.soc = reader.string();
                                break;
                            }
                        case 4: {
                                message.lastUsedDateTime = reader.string();
                                break;
                            }
                        case 5: {
                                message.noUseHour = reader.string();
                                break;
                            }
                        case 6: {
                                message.parkPointId = reader.string();
                                break;
                            }
                        case 7: {
                                message.lon = reader.string();
                                break;
                            }
                        case 8: {
                                message.lat = reader.string();
                                break;
                            }
                        case 9: {
                                message.posDt = reader.string();
                                break;
                            }
                        case 10: {
                                message.batDt = reader.string();
                                break;
                            }
                        case 11: {
                                message.noOrderHour = reader.string();
                                break;
                            }
                        case 12: {
                                message.isAbnormalFromAdmin = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Unused message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.Unused} Unused
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Unused.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Unused message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Unused.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        if (!$util.isString(message.userCode))
                            return "userCode: string expected";
                    if (message.parkPoint != null && message.hasOwnProperty("parkPoint"))
                        if (!$util.isString(message.parkPoint))
                            return "parkPoint: string expected";
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        if (!$util.isString(message.soc))
                            return "soc: string expected";
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        if (!$util.isString(message.lastUsedDateTime))
                            return "lastUsedDateTime: string expected";
                    if (message.noUseHour != null && message.hasOwnProperty("noUseHour"))
                        if (!$util.isString(message.noUseHour))
                            return "noUseHour: string expected";
                    if (message.parkPointId != null && message.hasOwnProperty("parkPointId"))
                        if (!$util.isString(message.parkPointId))
                            return "parkPointId: string expected";
                    if (message.lon != null && message.hasOwnProperty("lon"))
                        if (!$util.isString(message.lon))
                            return "lon: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (!$util.isString(message.lat))
                            return "lat: string expected";
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        if (!$util.isString(message.posDt))
                            return "posDt: string expected";
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        if (!$util.isString(message.batDt))
                            return "batDt: string expected";
                    if (message.noOrderHour != null && message.hasOwnProperty("noOrderHour"))
                        if (!$util.isString(message.noOrderHour))
                            return "noOrderHour: string expected";
                    if (message.isAbnormalFromAdmin != null && message.hasOwnProperty("isAbnormalFromAdmin"))
                        if (typeof message.isAbnormalFromAdmin !== "boolean")
                            return "isAbnormalFromAdmin: boolean expected";
                    return null;
                };

                /**
                 * Creates an Unused message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.Unused} Unused
                 */
                Unused.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.Unused)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.Unused();
                    if (object.userCode != null)
                        message.userCode = String(object.userCode);
                    if (object.parkPoint != null)
                        message.parkPoint = String(object.parkPoint);
                    if (object.soc != null)
                        message.soc = String(object.soc);
                    if (object.lastUsedDateTime != null)
                        message.lastUsedDateTime = String(object.lastUsedDateTime);
                    if (object.noUseHour != null)
                        message.noUseHour = String(object.noUseHour);
                    if (object.parkPointId != null)
                        message.parkPointId = String(object.parkPointId);
                    if (object.lon != null)
                        message.lon = String(object.lon);
                    if (object.lat != null)
                        message.lat = String(object.lat);
                    if (object.posDt != null)
                        message.posDt = String(object.posDt);
                    if (object.batDt != null)
                        message.batDt = String(object.batDt);
                    if (object.noOrderHour != null)
                        message.noOrderHour = String(object.noOrderHour);
                    if (object.isAbnormalFromAdmin != null)
                        message.isAbnormalFromAdmin = Boolean(object.isAbnormalFromAdmin);
                    return message;
                };

                /**
                 * Creates a plain object from an Unused message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {com.bxkz.OuterClass.Unused} message Unused
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Unused.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userCode = "";
                        object.parkPoint = "";
                        object.soc = "";
                        object.lastUsedDateTime = "";
                        object.noUseHour = "";
                        object.parkPointId = "";
                        object.lon = "";
                        object.lat = "";
                        object.posDt = "";
                        object.batDt = "";
                        object.noOrderHour = "";
                        object.isAbnormalFromAdmin = false;
                    }
                    if (message.userCode != null && message.hasOwnProperty("userCode"))
                        object.userCode = message.userCode;
                    if (message.parkPoint != null && message.hasOwnProperty("parkPoint"))
                        object.parkPoint = message.parkPoint;
                    if (message.soc != null && message.hasOwnProperty("soc"))
                        object.soc = message.soc;
                    if (message.lastUsedDateTime != null && message.hasOwnProperty("lastUsedDateTime"))
                        object.lastUsedDateTime = message.lastUsedDateTime;
                    if (message.noUseHour != null && message.hasOwnProperty("noUseHour"))
                        object.noUseHour = message.noUseHour;
                    if (message.parkPointId != null && message.hasOwnProperty("parkPointId"))
                        object.parkPointId = message.parkPointId;
                    if (message.lon != null && message.hasOwnProperty("lon"))
                        object.lon = message.lon;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = message.lat;
                    if (message.posDt != null && message.hasOwnProperty("posDt"))
                        object.posDt = message.posDt;
                    if (message.batDt != null && message.hasOwnProperty("batDt"))
                        object.batDt = message.batDt;
                    if (message.noOrderHour != null && message.hasOwnProperty("noOrderHour"))
                        object.noOrderHour = message.noOrderHour;
                    if (message.isAbnormalFromAdmin != null && message.hasOwnProperty("isAbnormalFromAdmin"))
                        object.isAbnormalFromAdmin = message.isAbnormalFromAdmin;
                    return object;
                };

                /**
                 * Converts this Unused to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.Unused
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Unused.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Unused
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.Unused
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Unused.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.Unused";
                };

                return Unused;
            })();

            OuterClass.UnusedList = (function() {

                /**
                 * Properties of an UnusedList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IUnusedList
                 * @property {Array.<com.bxkz.OuterClass.IUnused>|null} [data] UnusedList data
                 */

                /**
                 * Constructs a new UnusedList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an UnusedList.
                 * @implements IUnusedList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IUnusedList=} [properties] Properties to set
                 */
                function UnusedList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnusedList data.
                 * @member {Array.<com.bxkz.OuterClass.IUnused>} data
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @instance
                 */
                UnusedList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new UnusedList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.UnusedList} UnusedList instance
                 */
                UnusedList.create = function create(properties) {
                    return new UnusedList(properties);
                };

                /**
                 * Encodes the specified UnusedList message. Does not implicitly {@link com.bxkz.OuterClass.UnusedList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedList} message UnusedList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.Unused.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UnusedList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.UnusedList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedList} message UnusedList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UnusedList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.UnusedList} UnusedList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.UnusedList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.Unused.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UnusedList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.UnusedList} UnusedList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UnusedList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnusedList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.Unused.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an UnusedList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.UnusedList} UnusedList
                 */
                UnusedList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.UnusedList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.UnusedList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.UnusedList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.UnusedList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.Unused.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UnusedList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {com.bxkz.OuterClass.UnusedList} message UnusedList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnusedList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.Unused.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this UnusedList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnusedList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UnusedList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.UnusedList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnusedList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.UnusedList";
                };

                return UnusedList;
            })();

            OuterClass.UnusedRealTime = (function() {

                /**
                 * Constructs a new UnusedRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an UnusedRealTime
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function UnusedRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (UnusedRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UnusedRealTime;

                /**
                 * Creates new UnusedRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.UnusedRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {UnusedRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                UnusedRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.UnusedRealTime#service}.
                 * @memberof com.bxkz.OuterClass.UnusedRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.UnusedList} [response] UnusedList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.UnusedRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.UnusedRealTime.serviceCallback} callback Node-style callback called with the error, if any, and UnusedList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UnusedRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.UnusedList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.UnusedRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.UnusedList>} Promise
                 * @variation 2
                 */

                return UnusedRealTime;
            })();

            OuterClass.UnusedWithTime = (function() {

                /**
                 * Properties of an UnusedWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IUnusedWithTime
                 * @property {com.bxkz.OuterClass.IUnusedList|null} [data] UnusedWithTime data
                 * @property {string|null} [dateTime] UnusedWithTime dateTime
                 */

                /**
                 * Constructs a new UnusedWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an UnusedWithTime.
                 * @implements IUnusedWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IUnusedWithTime=} [properties] Properties to set
                 */
                function UnusedWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnusedWithTime data.
                 * @member {com.bxkz.OuterClass.IUnusedList|null|undefined} data
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @instance
                 */
                UnusedWithTime.prototype.data = null;

                /**
                 * UnusedWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @instance
                 */
                UnusedWithTime.prototype.dateTime = "";

                /**
                 * Creates a new UnusedWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.UnusedWithTime} UnusedWithTime instance
                 */
                UnusedWithTime.create = function create(properties) {
                    return new UnusedWithTime(properties);
                };

                /**
                 * Encodes the specified UnusedWithTime message. Does not implicitly {@link com.bxkz.OuterClass.UnusedWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTime} message UnusedWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.com.bxkz.OuterClass.UnusedList.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified UnusedWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.UnusedWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTime} message UnusedWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UnusedWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.UnusedWithTime} UnusedWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.UnusedWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.data = $root.com.bxkz.OuterClass.UnusedList.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UnusedWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.UnusedWithTime} UnusedWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UnusedWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnusedWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        let error = $root.com.bxkz.OuterClass.UnusedList.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates an UnusedWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.UnusedWithTime} UnusedWithTime
                 */
                UnusedWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.UnusedWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.UnusedWithTime();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".com.bxkz.OuterClass.UnusedWithTime.data: object expected");
                        message.data = $root.com.bxkz.OuterClass.UnusedList.fromObject(object.data);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from an UnusedWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.UnusedWithTime} message UnusedWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnusedWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.data = null;
                        object.dateTime = "";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.com.bxkz.OuterClass.UnusedList.toObject(message.data, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this UnusedWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnusedWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UnusedWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.UnusedWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnusedWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.UnusedWithTime";
                };

                return UnusedWithTime;
            })();

            OuterClass.UnusedWithTimeList = (function() {

                /**
                 * Properties of an UnusedWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IUnusedWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IUnusedWithTime>|null} [data] UnusedWithTimeList data
                 */

                /**
                 * Constructs a new UnusedWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an UnusedWithTimeList.
                 * @implements IUnusedWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IUnusedWithTimeList=} [properties] Properties to set
                 */
                function UnusedWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnusedWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IUnusedWithTime>} data
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @instance
                 */
                UnusedWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new UnusedWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.UnusedWithTimeList} UnusedWithTimeList instance
                 */
                UnusedWithTimeList.create = function create(properties) {
                    return new UnusedWithTimeList(properties);
                };

                /**
                 * Encodes the specified UnusedWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.UnusedWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTimeList} message UnusedWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.UnusedWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UnusedWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.UnusedWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IUnusedWithTimeList} message UnusedWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UnusedWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UnusedWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.UnusedWithTimeList} UnusedWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.UnusedWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.UnusedWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UnusedWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.UnusedWithTimeList} UnusedWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UnusedWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UnusedWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnusedWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.UnusedWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an UnusedWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.UnusedWithTimeList} UnusedWithTimeList
                 */
                UnusedWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.UnusedWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.UnusedWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.UnusedWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.UnusedWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.UnusedWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UnusedWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.UnusedWithTimeList} message UnusedWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnusedWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.UnusedWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this UnusedWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnusedWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UnusedWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.UnusedWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UnusedWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.UnusedWithTimeList";
                };

                return UnusedWithTimeList;
            })();

            OuterClass.UnusedHistory = (function() {

                /**
                 * Constructs a new UnusedHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents an UnusedHistory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function UnusedHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (UnusedHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UnusedHistory;

                /**
                 * Creates new UnusedHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.UnusedHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {UnusedHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                UnusedHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.UnusedHistory#service}.
                 * @memberof com.bxkz.OuterClass.UnusedHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.UnusedWithTimeList} [response] UnusedWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.UnusedHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.UnusedHistory.serviceCallback} callback Node-style callback called with the error, if any, and UnusedWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UnusedHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.UnusedWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.UnusedHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.UnusedWithTimeList>} Promise
                 * @variation 2
                 */

                return UnusedHistory;
            })();

            OuterClass.Weather = (function() {

                /**
                 * Properties of a Weather.
                 * @memberof com.bxkz.OuterClass
                 * @interface IWeather
                 * @property {string|null} [obsTime] Weather obsTime
                 * @property {string|null} [temp] Weather temp
                 * @property {string|null} [feelsLike] Weather feelsLike
                 * @property {string|null} [icon] Weather icon
                 * @property {string|null} [text] Weather text
                 * @property {string|null} [wind360] Weather wind360
                 * @property {string|null} [windDir] Weather windDir
                 * @property {string|null} [windScale] Weather windScale
                 * @property {string|null} [windSpeed] Weather windSpeed
                 * @property {string|null} [humidity] Weather humidity
                 * @property {string|null} [precip] Weather precip
                 * @property {string|null} [pressure] Weather pressure
                 * @property {string|null} [vis] Weather vis
                 * @property {string|null} [cloud] Weather cloud
                 * @property {string|null} [dew] Weather dew
                 */

                /**
                 * Constructs a new Weather.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 天气相关
                 * @implements IWeather
                 * @constructor
                 * @param {com.bxkz.OuterClass.IWeather=} [properties] Properties to set
                 */
                function Weather(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Weather obsTime.
                 * @member {string} obsTime
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.obsTime = "";

                /**
                 * Weather temp.
                 * @member {string} temp
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.temp = "";

                /**
                 * Weather feelsLike.
                 * @member {string} feelsLike
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.feelsLike = "";

                /**
                 * Weather icon.
                 * @member {string} icon
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.icon = "";

                /**
                 * Weather text.
                 * @member {string} text
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.text = "";

                /**
                 * Weather wind360.
                 * @member {string} wind360
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.wind360 = "";

                /**
                 * Weather windDir.
                 * @member {string} windDir
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.windDir = "";

                /**
                 * Weather windScale.
                 * @member {string} windScale
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.windScale = "";

                /**
                 * Weather windSpeed.
                 * @member {string} windSpeed
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.windSpeed = "";

                /**
                 * Weather humidity.
                 * @member {string} humidity
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.humidity = "";

                /**
                 * Weather precip.
                 * @member {string} precip
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.precip = "";

                /**
                 * Weather pressure.
                 * @member {string} pressure
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.pressure = "";

                /**
                 * Weather vis.
                 * @member {string} vis
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.vis = "";

                /**
                 * Weather cloud.
                 * @member {string} cloud
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.cloud = "";

                /**
                 * Weather dew.
                 * @member {string} dew
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 */
                Weather.prototype.dew = "";

                /**
                 * Creates a new Weather instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {com.bxkz.OuterClass.IWeather=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.Weather} Weather instance
                 */
                Weather.create = function create(properties) {
                    return new Weather(properties);
                };

                /**
                 * Encodes the specified Weather message. Does not implicitly {@link com.bxkz.OuterClass.Weather.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {com.bxkz.OuterClass.IWeather} message Weather message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Weather.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.obsTime != null && Object.hasOwnProperty.call(message, "obsTime"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.obsTime);
                    if (message.temp != null && Object.hasOwnProperty.call(message, "temp"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.temp);
                    if (message.feelsLike != null && Object.hasOwnProperty.call(message, "feelsLike"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.feelsLike);
                    if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.icon);
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.text);
                    if (message.wind360 != null && Object.hasOwnProperty.call(message, "wind360"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.wind360);
                    if (message.windDir != null && Object.hasOwnProperty.call(message, "windDir"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.windDir);
                    if (message.windScale != null && Object.hasOwnProperty.call(message, "windScale"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.windScale);
                    if (message.windSpeed != null && Object.hasOwnProperty.call(message, "windSpeed"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.windSpeed);
                    if (message.humidity != null && Object.hasOwnProperty.call(message, "humidity"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.humidity);
                    if (message.precip != null && Object.hasOwnProperty.call(message, "precip"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.precip);
                    if (message.pressure != null && Object.hasOwnProperty.call(message, "pressure"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.pressure);
                    if (message.vis != null && Object.hasOwnProperty.call(message, "vis"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.vis);
                    if (message.cloud != null && Object.hasOwnProperty.call(message, "cloud"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.cloud);
                    if (message.dew != null && Object.hasOwnProperty.call(message, "dew"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.dew);
                    return writer;
                };

                /**
                 * Encodes the specified Weather message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.Weather.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {com.bxkz.OuterClass.IWeather} message Weather message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Weather.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Weather message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.Weather} Weather
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Weather.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.Weather();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.obsTime = reader.string();
                                break;
                            }
                        case 2: {
                                message.temp = reader.string();
                                break;
                            }
                        case 3: {
                                message.feelsLike = reader.string();
                                break;
                            }
                        case 4: {
                                message.icon = reader.string();
                                break;
                            }
                        case 5: {
                                message.text = reader.string();
                                break;
                            }
                        case 6: {
                                message.wind360 = reader.string();
                                break;
                            }
                        case 7: {
                                message.windDir = reader.string();
                                break;
                            }
                        case 8: {
                                message.windScale = reader.string();
                                break;
                            }
                        case 9: {
                                message.windSpeed = reader.string();
                                break;
                            }
                        case 10: {
                                message.humidity = reader.string();
                                break;
                            }
                        case 11: {
                                message.precip = reader.string();
                                break;
                            }
                        case 12: {
                                message.pressure = reader.string();
                                break;
                            }
                        case 13: {
                                message.vis = reader.string();
                                break;
                            }
                        case 14: {
                                message.cloud = reader.string();
                                break;
                            }
                        case 15: {
                                message.dew = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Weather message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.Weather} Weather
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Weather.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Weather message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Weather.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.obsTime != null && message.hasOwnProperty("obsTime"))
                        if (!$util.isString(message.obsTime))
                            return "obsTime: string expected";
                    if (message.temp != null && message.hasOwnProperty("temp"))
                        if (!$util.isString(message.temp))
                            return "temp: string expected";
                    if (message.feelsLike != null && message.hasOwnProperty("feelsLike"))
                        if (!$util.isString(message.feelsLike))
                            return "feelsLike: string expected";
                    if (message.icon != null && message.hasOwnProperty("icon"))
                        if (!$util.isString(message.icon))
                            return "icon: string expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.wind360 != null && message.hasOwnProperty("wind360"))
                        if (!$util.isString(message.wind360))
                            return "wind360: string expected";
                    if (message.windDir != null && message.hasOwnProperty("windDir"))
                        if (!$util.isString(message.windDir))
                            return "windDir: string expected";
                    if (message.windScale != null && message.hasOwnProperty("windScale"))
                        if (!$util.isString(message.windScale))
                            return "windScale: string expected";
                    if (message.windSpeed != null && message.hasOwnProperty("windSpeed"))
                        if (!$util.isString(message.windSpeed))
                            return "windSpeed: string expected";
                    if (message.humidity != null && message.hasOwnProperty("humidity"))
                        if (!$util.isString(message.humidity))
                            return "humidity: string expected";
                    if (message.precip != null && message.hasOwnProperty("precip"))
                        if (!$util.isString(message.precip))
                            return "precip: string expected";
                    if (message.pressure != null && message.hasOwnProperty("pressure"))
                        if (!$util.isString(message.pressure))
                            return "pressure: string expected";
                    if (message.vis != null && message.hasOwnProperty("vis"))
                        if (!$util.isString(message.vis))
                            return "vis: string expected";
                    if (message.cloud != null && message.hasOwnProperty("cloud"))
                        if (!$util.isString(message.cloud))
                            return "cloud: string expected";
                    if (message.dew != null && message.hasOwnProperty("dew"))
                        if (!$util.isString(message.dew))
                            return "dew: string expected";
                    return null;
                };

                /**
                 * Creates a Weather message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.Weather} Weather
                 */
                Weather.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.Weather)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.Weather();
                    if (object.obsTime != null)
                        message.obsTime = String(object.obsTime);
                    if (object.temp != null)
                        message.temp = String(object.temp);
                    if (object.feelsLike != null)
                        message.feelsLike = String(object.feelsLike);
                    if (object.icon != null)
                        message.icon = String(object.icon);
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.wind360 != null)
                        message.wind360 = String(object.wind360);
                    if (object.windDir != null)
                        message.windDir = String(object.windDir);
                    if (object.windScale != null)
                        message.windScale = String(object.windScale);
                    if (object.windSpeed != null)
                        message.windSpeed = String(object.windSpeed);
                    if (object.humidity != null)
                        message.humidity = String(object.humidity);
                    if (object.precip != null)
                        message.precip = String(object.precip);
                    if (object.pressure != null)
                        message.pressure = String(object.pressure);
                    if (object.vis != null)
                        message.vis = String(object.vis);
                    if (object.cloud != null)
                        message.cloud = String(object.cloud);
                    if (object.dew != null)
                        message.dew = String(object.dew);
                    return message;
                };

                /**
                 * Creates a plain object from a Weather message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {com.bxkz.OuterClass.Weather} message Weather
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Weather.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.obsTime = "";
                        object.temp = "";
                        object.feelsLike = "";
                        object.icon = "";
                        object.text = "";
                        object.wind360 = "";
                        object.windDir = "";
                        object.windScale = "";
                        object.windSpeed = "";
                        object.humidity = "";
                        object.precip = "";
                        object.pressure = "";
                        object.vis = "";
                        object.cloud = "";
                        object.dew = "";
                    }
                    if (message.obsTime != null && message.hasOwnProperty("obsTime"))
                        object.obsTime = message.obsTime;
                    if (message.temp != null && message.hasOwnProperty("temp"))
                        object.temp = message.temp;
                    if (message.feelsLike != null && message.hasOwnProperty("feelsLike"))
                        object.feelsLike = message.feelsLike;
                    if (message.icon != null && message.hasOwnProperty("icon"))
                        object.icon = message.icon;
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.wind360 != null && message.hasOwnProperty("wind360"))
                        object.wind360 = message.wind360;
                    if (message.windDir != null && message.hasOwnProperty("windDir"))
                        object.windDir = message.windDir;
                    if (message.windScale != null && message.hasOwnProperty("windScale"))
                        object.windScale = message.windScale;
                    if (message.windSpeed != null && message.hasOwnProperty("windSpeed"))
                        object.windSpeed = message.windSpeed;
                    if (message.humidity != null && message.hasOwnProperty("humidity"))
                        object.humidity = message.humidity;
                    if (message.precip != null && message.hasOwnProperty("precip"))
                        object.precip = message.precip;
                    if (message.pressure != null && message.hasOwnProperty("pressure"))
                        object.pressure = message.pressure;
                    if (message.vis != null && message.hasOwnProperty("vis"))
                        object.vis = message.vis;
                    if (message.cloud != null && message.hasOwnProperty("cloud"))
                        object.cloud = message.cloud;
                    if (message.dew != null && message.hasOwnProperty("dew"))
                        object.dew = message.dew;
                    return object;
                };

                /**
                 * Converts this Weather to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.Weather
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Weather.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Weather
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.Weather
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Weather.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.Weather";
                };

                return Weather;
            })();

            OuterClass.WeatherRealTime = (function() {

                /**
                 * Constructs a new WeatherRealTime service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 实时天气信息接口
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function WeatherRealTime(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (WeatherRealTime.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WeatherRealTime;

                /**
                 * Creates new WeatherRealTime service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.WeatherRealTime
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {WeatherRealTime} RPC service. Useful where requests and/or responses are streamed.
                 */
                WeatherRealTime.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.WeatherRealTime#service}.
                 * @memberof com.bxkz.OuterClass.WeatherRealTime
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.Weather} [response] Weather
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.WeatherRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {com.bxkz.OuterClass.WeatherRealTime.serviceCallback} callback Node-style callback called with the error, if any, and Weather
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WeatherRealTime.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.google.protobuf.Empty, $root.com.bxkz.OuterClass.Weather, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.WeatherRealTime
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.Weather>} Promise
                 * @variation 2
                 */

                return WeatherRealTime;
            })();

            OuterClass.WeatherWithTime = (function() {

                /**
                 * Properties of a WeatherWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @interface IWeatherWithTime
                 * @property {com.bxkz.OuterClass.IWeather|null} [weather] WeatherWithTime weather
                 * @property {string|null} [dateTime] WeatherWithTime dateTime
                 */

                /**
                 * Constructs a new WeatherWithTime.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 带时间的天气信息数据
                 * @implements IWeatherWithTime
                 * @constructor
                 * @param {com.bxkz.OuterClass.IWeatherWithTime=} [properties] Properties to set
                 */
                function WeatherWithTime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WeatherWithTime weather.
                 * @member {com.bxkz.OuterClass.IWeather|null|undefined} weather
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @instance
                 */
                WeatherWithTime.prototype.weather = null;

                /**
                 * WeatherWithTime dateTime.
                 * @member {string} dateTime
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @instance
                 */
                WeatherWithTime.prototype.dateTime = "";

                /**
                 * Creates a new WeatherWithTime instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTime=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.WeatherWithTime} WeatherWithTime instance
                 */
                WeatherWithTime.create = function create(properties) {
                    return new WeatherWithTime(properties);
                };

                /**
                 * Encodes the specified WeatherWithTime message. Does not implicitly {@link com.bxkz.OuterClass.WeatherWithTime.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTime} message WeatherWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeatherWithTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.weather != null && Object.hasOwnProperty.call(message, "weather"))
                        $root.com.bxkz.OuterClass.Weather.encode(message.weather, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateTime);
                    return writer;
                };

                /**
                 * Encodes the specified WeatherWithTime message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.WeatherWithTime.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTime} message WeatherWithTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeatherWithTime.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WeatherWithTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.WeatherWithTime} WeatherWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeatherWithTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.WeatherWithTime();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.weather = $root.com.bxkz.OuterClass.Weather.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.dateTime = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WeatherWithTime message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.WeatherWithTime} WeatherWithTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeatherWithTime.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WeatherWithTime message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WeatherWithTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.weather != null && message.hasOwnProperty("weather")) {
                        let error = $root.com.bxkz.OuterClass.Weather.verify(message.weather);
                        if (error)
                            return "weather." + error;
                    }
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        if (!$util.isString(message.dateTime))
                            return "dateTime: string expected";
                    return null;
                };

                /**
                 * Creates a WeatherWithTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.WeatherWithTime} WeatherWithTime
                 */
                WeatherWithTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.WeatherWithTime)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.WeatherWithTime();
                    if (object.weather != null) {
                        if (typeof object.weather !== "object")
                            throw TypeError(".com.bxkz.OuterClass.WeatherWithTime.weather: object expected");
                        message.weather = $root.com.bxkz.OuterClass.Weather.fromObject(object.weather);
                    }
                    if (object.dateTime != null)
                        message.dateTime = String(object.dateTime);
                    return message;
                };

                /**
                 * Creates a plain object from a WeatherWithTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {com.bxkz.OuterClass.WeatherWithTime} message WeatherWithTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WeatherWithTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.weather = null;
                        object.dateTime = "";
                    }
                    if (message.weather != null && message.hasOwnProperty("weather"))
                        object.weather = $root.com.bxkz.OuterClass.Weather.toObject(message.weather, options);
                    if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                        object.dateTime = message.dateTime;
                    return object;
                };

                /**
                 * Converts this WeatherWithTime to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WeatherWithTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WeatherWithTime
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.WeatherWithTime
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WeatherWithTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.WeatherWithTime";
                };

                return WeatherWithTime;
            })();

            OuterClass.WeatherWithTimeList = (function() {

                /**
                 * Properties of a WeatherWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @interface IWeatherWithTimeList
                 * @property {Array.<com.bxkz.OuterClass.IWeatherWithTime>|null} [data] WeatherWithTimeList data
                 */

                /**
                 * Constructs a new WeatherWithTimeList.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc Represents a WeatherWithTimeList.
                 * @implements IWeatherWithTimeList
                 * @constructor
                 * @param {com.bxkz.OuterClass.IWeatherWithTimeList=} [properties] Properties to set
                 */
                function WeatherWithTimeList(properties) {
                    this.data = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WeatherWithTimeList data.
                 * @member {Array.<com.bxkz.OuterClass.IWeatherWithTime>} data
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @instance
                 */
                WeatherWithTimeList.prototype.data = $util.emptyArray;

                /**
                 * Creates a new WeatherWithTimeList instance using the specified properties.
                 * @function create
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTimeList=} [properties] Properties to set
                 * @returns {com.bxkz.OuterClass.WeatherWithTimeList} WeatherWithTimeList instance
                 */
                WeatherWithTimeList.create = function create(properties) {
                    return new WeatherWithTimeList(properties);
                };

                /**
                 * Encodes the specified WeatherWithTimeList message. Does not implicitly {@link com.bxkz.OuterClass.WeatherWithTimeList.verify|verify} messages.
                 * @function encode
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTimeList} message WeatherWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeatherWithTimeList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && message.data.length)
                        for (let i = 0; i < message.data.length; ++i)
                            $root.com.bxkz.OuterClass.WeatherWithTime.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified WeatherWithTimeList message, length delimited. Does not implicitly {@link com.bxkz.OuterClass.WeatherWithTimeList.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.IWeatherWithTimeList} message WeatherWithTimeList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WeatherWithTimeList.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WeatherWithTimeList message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.bxkz.OuterClass.WeatherWithTimeList} WeatherWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeatherWithTimeList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.bxkz.OuterClass.WeatherWithTimeList();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.data && message.data.length))
                                    message.data = [];
                                message.data.push($root.com.bxkz.OuterClass.WeatherWithTime.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WeatherWithTimeList message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.bxkz.OuterClass.WeatherWithTimeList} WeatherWithTimeList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WeatherWithTimeList.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WeatherWithTimeList message.
                 * @function verify
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WeatherWithTimeList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        if (!Array.isArray(message.data))
                            return "data: array expected";
                        for (let i = 0; i < message.data.length; ++i) {
                            let error = $root.com.bxkz.OuterClass.WeatherWithTime.verify(message.data[i]);
                            if (error)
                                return "data." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a WeatherWithTimeList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.bxkz.OuterClass.WeatherWithTimeList} WeatherWithTimeList
                 */
                WeatherWithTimeList.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.bxkz.OuterClass.WeatherWithTimeList)
                        return object;
                    let message = new $root.com.bxkz.OuterClass.WeatherWithTimeList();
                    if (object.data) {
                        if (!Array.isArray(object.data))
                            throw TypeError(".com.bxkz.OuterClass.WeatherWithTimeList.data: array expected");
                        message.data = [];
                        for (let i = 0; i < object.data.length; ++i) {
                            if (typeof object.data[i] !== "object")
                                throw TypeError(".com.bxkz.OuterClass.WeatherWithTimeList.data: object expected");
                            message.data[i] = $root.com.bxkz.OuterClass.WeatherWithTime.fromObject(object.data[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WeatherWithTimeList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {com.bxkz.OuterClass.WeatherWithTimeList} message WeatherWithTimeList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WeatherWithTimeList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.data = [];
                    if (message.data && message.data.length) {
                        object.data = [];
                        for (let j = 0; j < message.data.length; ++j)
                            object.data[j] = $root.com.bxkz.OuterClass.WeatherWithTime.toObject(message.data[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this WeatherWithTimeList to JSON.
                 * @function toJSON
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WeatherWithTimeList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WeatherWithTimeList
                 * @function getTypeUrl
                 * @memberof com.bxkz.OuterClass.WeatherWithTimeList
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WeatherWithTimeList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.bxkz.OuterClass.WeatherWithTimeList";
                };

                return WeatherWithTimeList;
            })();

            OuterClass.WeatherHistory = (function() {

                /**
                 * Constructs a new WeatherHistory service.
                 * @memberof com.bxkz.OuterClass
                 * @classdesc 天气历史信息接口
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function WeatherHistory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (WeatherHistory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WeatherHistory;

                /**
                 * Creates new WeatherHistory service using the specified rpc implementation.
                 * @function create
                 * @memberof com.bxkz.OuterClass.WeatherHistory
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {WeatherHistory} RPC service. Useful where requests and/or responses are streamed.
                 */
                WeatherHistory.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link com.bxkz.OuterClass.WeatherHistory#service}.
                 * @memberof com.bxkz.OuterClass.WeatherHistory
                 * @typedef serviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {com.bxkz.OuterClass.WeatherWithTimeList} [response] WeatherWithTimeList
                 */

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.WeatherHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @param {com.bxkz.OuterClass.WeatherHistory.serviceCallback} callback Node-style callback called with the error, if any, and WeatherWithTimeList
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WeatherHistory.prototype.service = function service(request, callback) {
                    return this.rpcCall(service, $root.com.bxkz.OuterClass.HistoryParam, $root.com.bxkz.OuterClass.WeatherWithTimeList, request, callback);
                }, "name", { value: "service" });

                /**
                 * Calls service.
                 * @function service
                 * @memberof com.bxkz.OuterClass.WeatherHistory
                 * @instance
                 * @param {com.bxkz.OuterClass.IHistoryParam} request HistoryParam message or plain object
                 * @returns {Promise<com.bxkz.OuterClass.WeatherWithTimeList>} Promise
                 * @variation 2
                 */

                return WeatherHistory;
            })();

            return OuterClass;
        })();

        return bxkz;
    })();

    return com;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Empty instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             * @returns {google.protobuf.Empty} Empty instance
             */
            Empty.create = function create(properties) {
                return new Empty(properties);
            };

            /**
             * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Empty message, length delimited. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Empty message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Empty();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Empty message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Empty message.
             * @function verify
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Empty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Empty)
                    return object;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} message Empty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Empty
             * @function getTypeUrl
             * @memberof google.protobuf.Empty
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Empty";
            };

            return Empty;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && Object.hasOwnProperty.call(message, "type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type_url = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                let message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Any
             * @function getTypeUrl
             * @memberof google.protobuf.Any
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Any";
            };

            return Any;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
