<template>
  <div id="app">
    <!-- 地图 -->
    <div id="container"></div>
    <!-- 时间选择模块 -->
    <div style="
                                                                  position: absolute;
                                                                  top: 5px;
                                                                  left: 548px;
                                                                  font-size: 16px;
                                                                  font-weight: 700;
                                                                " v-if="globalTime">
      历史时间：{{ globalTime }}
    </div>
    <div class="times" style="z-index: 110">
      <times-view @dataChange="historyRequest" @stepChange="step" :msg="operationSlider" :judge="operationJudge"
        :enable="timeModuleControl" ref="timeSelect"></times-view>
    </div>

    <!-- 骑行数据统计 -->
    <div class="freeData_total" v-show="riding_total" style="z-index: 110; height: 170px; background-size: 315px 170px">
      <div class="noOrder_data">骑行车辆数据统计</div>
      <div class="noOrder_type">
        <div @click="ridingClick">
          <p style="border-color: #95f0f3" class="data_type">{{ allCars }}</p>
          <div>全部车辆</div>
        </div>
        <div @click="partCars">
          <p style="border-color: #cfaf4f" class="data_type">
            {{ ridingCars }}
          </p>
          <div>骑行中</div>
        </div>
        <div>
          <p style="border-color: #cf4846" class="data_type">{{ freeCars }}</p>
          <div>未骑行</div>
        </div>
      </div>
    </div>

    <!-- 闲置数据统计 -->
    <div class="freeData_total" v-show="data_total" style="z-index: 110">
      <div class="noOrder_data">
        无订单数据
        <span style="font-size: 14px">(总数：{{ this.freeObj.noOrderAll }})</span>
        <span style="font-size: 14px; font-weight: 500">（时长>{{ beginFree }}）</span>
      </div>

      <div class="noOrder_type">
        <div>
          <div class="data_type" style="border-color: #95f0f3"
            @click="typeClick($event, { type: 'noOrder', range: [0, 48] })">
            {{ freeObj.noOrder24 }}
          </div>
          <div>24-48h</div>
        </div>
        <div>
          <div class="data_type" style="border-color: #cfaf4f"
            @click="typeClick($event, { type: 'noOrder', range: [48, 72] })">
            {{ freeObj.noOrder48 }}
          </div>
          <div>48-72h</div>
        </div>
        <div>
          <div class="data_type" style="border-color: #cf4846" @click="
            typeClick($event, { type: 'noOrder', range: [72, Infinity] })
          ">
            {{ freeObj.noOrder72 }}
          </div>
          <div>>72h</div>
        </div>
      </div>

      <div class="noOrder_data">
        闲置数据
        <span style="font-size: 14px">(总数：{{ this.freeObj.noUseAll }})</span>
      </div>
      <!-- 屏蔽故障 -->
      <div style="
                                                                    position: absolute;
                                                                    bottom: 132px;
                                                                    right: 67px;
                                                                    font-size: 14px;
                                                                    font-weight: 700;
                                                                    color: #f4b844;
                                                                  ">
        屏蔽故障:
      </div>
      <div style="position: absolute; bottom: 198px; right: 0">
        <div class="hideBtn_left" :class="[closeFailure ? 'closeColor' : 'openColor']"></div>
        <div class="hideBtn_right" :class="[closeFailure ? 'closeColor' : 'openColor']"></div>
        <div :class="[closeFailure ? 'moveHidePoint' : 'moveHidePointing ']" @click="hideFailure"></div>
      </div>

      <div class="noOrder_type">
        <div>
          <div class="data_type" style="border-color: #95f0f3"
            @click="typeClick($event, { type: 'noUse', range: [0, 48] })">
            {{ freeObj.noUse24 }}
          </div>
          <div>24-48h</div>
        </div>
        <div>
          <div class="data_type" style="border-color: #cfaf4f"
            @click="typeClick($event, { type: 'noUse', range: [48, 72] })">
            {{ freeObj.noUse48 }}
          </div>
          <div>48-72h</div>
        </div>
        <div>
          <div class="data_type" style="border-color: #cf4846"
            @click="typeClick($event, { type: 'noUse', range: [72, Infinity] })">
            {{ freeObj.noUse72 }}
          </div>
          <div>>72h</div>
        </div>
      </div>
    </div>
    <div v-show="data_total" class="noUseList">
      <div style="
                                                                    display: flex;
                                                                    text-align: center;
                                                                    padding-top: 6px;
                                                                    font-weight: 700;
                                                                  ">
        <div style="width: 33%">车辆编号</div>
        <div style="width: 33%">闲置时长</div>
        <div style="width: 34%">无订单时长</div>
      </div>
      <ul style="overflow: auto; height: 300px">
        <li style="display: flex; text-align: center" v-for="item in noUseList" :key="item.index"
          @click="noUseCar($event, item)" :class="activeCarNum === item.userCode ? 'active' : ''">
          <div style="width: 33%">{{ item.userCode }}</div>
          <div style="width: 33%">{{ item.noUseHour }}</div>
          <div style="width: 34%">{{ item.noOrderHour }}</div>
        </li>
      </ul>
    </div>
    <!-- 亏电数据统计 -->
    <div class="freeData_total" v-show="feed_total" style="z-index: 110; height: 170px; background-size: 315px 170px">
      <div class="noOrder_data">亏电车辆数据统计</div>
      <div class="noOrder_type">
        <div>
          <div style="border-color: #95f0f3" class="data_type">
            {{ allFeed }}
          </div>
          <div>全部</div>
        </div>
        <div>
          <div style="border-color: #cfaf4f" class="data_type">
            {{ feed40 }}
          </div>
          <div>15%-40%</div>
        </div>
        <div>
          <div style="border-color: #cf4846" class="data_type">
            {{ feed15 }}
          </div>
          <div>&lt;15%</div>
        </div>
      </div>
    </div>
    <!-- 异常数据统计 -->
    <div class="freeData_total" v-show="fault_total" style="z-index: 110">
      <div class="noOrder_data">
        异常车辆数据统计
        <span style="cursor: pointer" @click="malCount">(总数：{{ malfunctionObj.all }})</span>
      </div>
      <div class="noOrder_type">
        <div>
          <p style="border-color: #cf4846" class="data_type" @click="malTypeClick($event, { type: '故障' })">
            {{ malfunctionObj.fault }}
          </p>
          <div>故障</div>
        </div>
        <div>
          <p style="border-color: #cf4846" class="data_type" @click="malTypeClick($event, { type: '无信号' })">
            {{ malfunctionObj.noSignal }}
          </p>
          <div>无信号</div>
        </div>
        <div>
          <p style="border-color: #cf4846" class="data_type" @click="malTypeClick($event, { type: '电池异常' })">
            {{ malfunctionObj.powerAbnormality }}
          </p>
          <div>电池异常</div>
        </div>
      </div>
      <div class="noOrder_type" style="margin-top: 29px">
        <div class="type24">
          <p style="border-color: #95f0f3" class="data_type" @click="malTypeClick($event, { type: '站外' })">
            {{ malfunctionObj.outside }}
          </p>
          <div>站外</div>
        </div>
        <div class="type48">
          <p style="border-color: #cf4846" class="data_type" @click="malTypeClick($event, { type: '禁区停车' })">
            {{ malfunctionObj.prohibit }}
          </p>
          <div>禁区停车</div>
        </div>
        <div class="type72">
          <p style="border-color: #ffa202" class="data_type" @click="malTypeClick($event, { type: '超区' })">
            {{ malfunctionObj.zone }}
          </p>
          <div>超区</div>
        </div>
      </div>
    </div>
    <!-- 运维数据统计 -->
    <div class="operationContainer" v-show="operation_total">
      <div class="operationData_total" v-show="operationChart">
        <div class="noOrder_data">运维人员事件数据统计</div>
        <div>
          <div class="noOrder_type">
            <div>
              <div class="data_type" style="border-color: #95f0f3">
                {{ operationObj.all }}
              </div>
              <div>全部运维</div>
            </div>
            <div>
              <div class="data_type" style="border-color: #cfaf4f">
                {{ operationObj.work }}
              </div>
              <div>在岗数量</div>
            </div>
            <div>
              <div class="data_type" style="border-color: #cf4846">
                <img src="./assets/images/operation_arrow.png" alt="" style="width: 20px; height: 20px" />
              </div>
              <div style="
                                                                            width: 70px;
                                                                            text-overflow: ellipsis;
                                                                            overflow: hidden;
                                                                            white-space: nowrap;
                                                                          ">
                {{ operationObj.selected }}
              </div>
            </div>
          </div>
          <div class="noOrder_type" style="margin-top: 30px">
            <div>
              <div class="data_type" style="border-color: #95f0f3">
                {{ operationObj.num.powerChange }}
              </div>
              <div>换电次数</div>
            </div>
            <div>
              <div class="data_type" style="border-color: #cfaf4f">
                {{ operationObj.num.dispatch }}
              </div>
              <div>调度次数</div>
            </div>
            <div>
              <div class="data_type" style="border-color: #cf4846">
                {{ operationObj.num.move }}
              </div>
              <div>挪车次数</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 折线图 -->
      <div class="chartContainer" v-show="chartData">
        <div class="chart_title">
          <div style="font-size: 14px">全部事件数据统计图</div>
          <span style="
                                                                        position: absolute;
                                                                        top: 38px;
                                                                        left: 8px;
                                                                        z-index: 100;
                                                                        font-size: 12px;
                                                                      ">单位：次数</span>
          <div style="display: flex">
            <div class="chart_type">
              <img src="https://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/bluePoint.png" alt="" />
              换电
            </div>
            <div class="chart_type">
              <img src="https://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/yellowPoint.png" alt="" />
              调度
            </div>
            <div class="chart_type">
              <img src="https://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/redPoint.png" alt="" />
              挪车
            </div>
          </div>
        </div>
        <div id="lineChart"></div>
      </div>
      <!-- 运维人员选择列表 -->
      <div class="person">
        <div class="person_title" style="
                                                                      display: flex;
                                                                      justify-content: space-between;
                                                                      padding: 0 20px;
                                                                      font-weight: 700;
                                                                    ">
          <span style="font-size: 16px">全部运维人员</span><span @click="personClick"
            style="cursor: pointer; padding-top: 5px"><img src="./assets/images/operation_person_arrow.png" alt=""
              style="width: 15px; height: 15px" /></span>
        </div>
        <ul class="people_list" v-show="operation_list">
          <li v-for="(item, i) in operationObj.name" :key="item" style="
                                                                        list-style: none;
                                                                        height: 25px;
                                                                        font-size: 14px;
                                                                        padding: 0 20px;
                                                                        cursor: pointer;
                                                                        width: 315px;
                                                                      " @click="personChange(i, item)"
            :class="activeNum === i ? 'active' : ''">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>

    <!-- 运维事件列表 -->
    <div class="eventList" v-show="personnelList">
      <div class="eventList_title">
        <div style="width: 42%">日期</div>
        <div style="width: 22%">
          事件
          <div class="eventBtn_left" :class="[closeEventBtn ? 'closeColor' : 'openColor']"></div>
          <div class="eventBtn_right" :class="[closeEventBtn ? 'closeColor' : 'openColor']"></div>
          <!-- 切换地图类型 -->
          <div :class="[closeEventBtn ? 'moveEventPoint' : 'moveEventPointing ']" @click="selectEvent"></div>
        </div>
        <div style="width: 22%">数量</div>
        <div @click="closeList" style="
                                                                      position: absolute;
                                                                      top: 1px;
                                                                      right: 5px;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      cursor: pointer;
                                                                    ">
          <img src="./assets/images/listClose.png" alt="" style="width: 16px; height: 16px" />
        </div>
      </div>
      <ul style="margin-top: 27px">
        <li v-for="(item, i) in operationObj.event" :class="EventNum === i ? 'active' : ''" :key="i"
          @click="positioningEvent(i, item)" style="cursor: pointer; border-bottom: 1px dashed rgb(149, 240, 243)">
          <span style="width: 42%"> {{ item.dateTime }}</span><span class="list_span">{{ item.typeStr }}</span>
          <span class="list_span">{{ item.num }}</span>
        </li>
        <span v-if="!operationObj.event[0]" style="
                                                                      font-size: 20px;
                                                                      font-weight: 700;
                                                                      position: absolute;
                                                                      top: 50%;
                                                                      left: 50%;
                                                                      transform: translate(-50%);
                                                                    ">无数据</span>
      </ul>
    </div>

    <!-- 功能选择模块 -->
    <div class="selection" style="z-index: 110">
      <choose-view @freeChange="freeClick" @parkChange="parkClick" @sliderChange="sliderClick" @ridingChange="ridingClick"
        @malfunctionChange="malfunctionClick" @feedChange="feedClick" @operationChange="operationClick"
        ref="child"></choose-view>
    </div>
    <!-- 全局天气，时间，日期 -->
    <div class="dayTime" style="z-index: 109">
      <day-view :date="closeBtn"></day-view>
      <weather-view :date="closeBtn" :token="token"></weather-view>
    </div>
    <div class="btn_left" :class="[closeBtn ? 'closeColor' : 'openColor']"></div>
    <div class="btn_right" :class="[closeBtn ? 'closeColor' : 'openColor']"></div>
    <!-- 切换地图类型 -->
    <div :class="[closeBtn ? 'movePoint' : 'movePointing ']" @click="changeSkin"></div>
    <!-- 骑行标注 -->
    <div class="noUse" ref="ridingData" v-show="riding">
      <div class="noUse-top">
        车辆编号 :
        <span class="useCode" style="font-size: 18px; display: inline-block; margin-left: 5px">
          {{ devInfo.userCode }}</span>
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      margin-left: 172px;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      vertical-align: baseline;
                                                                    " />
      </div>
      <div class="noUse-bottom">
        <div class="img" style="display: flex; justify-content: center; align-items: center">
          <div class="circle" style="border: 3px solid #3fd572">
            <img src="./assets/images/ridingCar.png" alt="" style="width: 50px; height: 40px" />
          </div>
          <div class="status" style="background-color: #3fd572; width: 66px" v-show="devInfo.phone">
            <span>骑行中</span>
          </div>
          <div class="status" style="width: 66px" v-show="!devInfo.phone">
            <span>未骑行</span>
          </div>
        </div>
        <div class="text" style="width: 236px">
          <div class="text-position" style="margin-top: 24px">骑行人:</div>
          <div class="text-address" style="height: 20px">
            {{ devInfo.phone }}
          </div>
          <div class="text-date">
            今日骑行次数: <span>{{ devInfo.times }}次</span>
          </div>
          <div class="text-date">
            今日骑行时长：<span>{{ devInfo.minutes }}分钟</span>
          </div>
        </div>
        <div class="electric">
          <div class="ele-inner" style="
                                                                        width: 20px;
                                                                        height: 15px;
                                                                        position: absolute;
                                                                        top: 15px;
                                                                        left: 287px;
                                                                        background-color: #3ed471;
                                                                      " :style="electri"></div>
          <img src="./assets/images/ele-green.png" alt="" style="
                                                                        width: 46px;
                                                                        height: 23px;
                                                                        position: absolute;
                                                                        top: 11px;
                                                                        right: 51px;
                                                                      " /><span style="
                                                                        color: #3ed471;
                                                                        font-size: 20px;
                                                                        font-weight: 700;
                                                                        margin-top: 6px;
                                                                        display: inline-block;
                                                                        position: absolute;
                                                                        right: 8px;
                                                                      ">{{ devInfo.soc }}%</span>
        </div>
        <div class="rightAngle">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="obtuseAngle">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 闲置点标注 -->
    <div class="noUse" v-show="noUse" ref="infoData">
      <div class="noUse-top">
        车辆编号 :
        <span class="useCode" style="font-size: 18px; display: inline-block; margin-left: 5px">
          {{ devInfo.userCode }}</span>
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      margin-left: 172px;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      vertical-align: baseline;
                                                                    " />
      </div>
      <div class="noUse-bottom">
        <div class="img" style="display: flex; justify-content: center; align-items: center">
          <div class="circle">
            <img src="./assets/images/carLogo.png" alt="" style="width: 50px; height: 40px" />
          </div>
          <div style="font-size: 16px">
            无订单 <span>{{ devInfo.noOrderHour }}小时</span>
          </div>
          <div class="status" :style="statusBgc" style="margin-top: 0">
            闲置 <span>{{ devInfo.noUseHour }}小时</span>
          </div>
        </div>

        <div class="text" style="width: 236px">
          <div class="text-position" style="margin-top: 9px">所在地:</div>
          <div class="text-address" :title="devInfo.parkPoint">
            {{ devInfo.parkPoint }}
          </div>
          <div class="text-date">
            上次骑行: <span>{{ devInfo.lastUsedDateTime }}</span>
          </div>
          <div class="text-date">
            信号时间: <span>{{ devInfo.batDt }}</span>
          </div>
          <div class="text-date">
            定位时间: <span>{{ devInfo.posDt }}</span>
          </div>
        </div>
        <div class="electric">
          <div class="ele-inner" style="
                                                                        width: 20px;
                                                                        height: 15px;
                                                                        position: absolute;
                                                                        top: 15px;
                                                                        left: 287px;
                                                                        background-color: #3ed471;
                                                                      " :style="electri"></div>
          <img src="./assets/images/ele-green.png" alt="" style="
                                                                        width: 46px;
                                                                        height: 23px;
                                                                        position: absolute;
                                                                        top: 11px;
                                                                        right: 51px;
                                                                      " />
          <span style="
                                                                        font-size: 20px;
                                                                        font-weight: 700;
                                                                        margin-top: 6px;
                                                                        display: inline-block;
                                                                        position: absolute;
                                                                        right: 8px;
                                                                        color: #3ed471;
                                                                      ">{{ devInfo.soc }}%</span>
        </div>
        <div class="rightAngle">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="obtuseAngle">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 故障点标注 -->

    <div class="noUse" ref="malfunctionData" v-show="malfunction">
      <div class="noUse-top">
        车辆编号 :
        <span class="useCode" style="font-size: 18px; display: inline-block; margin-left: 5px">
          {{ devInfo.userCode }}</span>
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      margin-left: 172px;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      vertical-align: baseline;
                                                                    " />
      </div>

      <div class="noUse-bottom">
        <div class="img" style="display: flex; justify-content: center; align-items: center">
          <div class="circle" style="border: 3px solid #ff3a35">
            <img src="./assets/images/redCar.png" alt="" style="width: 50px; height: 40px" />
          </div>
          <div class="status" style="background-color: #ff3a35; width: 50px">
            <span v-show="devInfo.typeStr == 'fault'">故障</span>
            <span v-show="devInfo.typeStr == 'batteryAbnormal'">亏电</span>
            <span v-show="devInfo.typeStr == 'outOfArea'">超区</span>
            <span v-show="devInfo.typeStr == 'offSite'">站外</span>
            <span v-show="devInfo.typeStr == 'noSignal'">无信号</span>
            <span v-show="devInfo.typeStr == 'prohibitArea'">禁区停车</span>
          </div>
        </div>

        <div class="text" style="width: 236px">
          <div class="text-position" style="margin-top: 9px">故障原因：</div>
          <div class="text-address" :title="devInfo.reason" v-show="devInfo.reason">
            {{ devInfo.reason }}
          </div>
          <div class="text-address" v-show="devInfo.typeStr == 'batteryAbnormal'">
            亏电
          </div>
          <div class="text-address" v-show="devInfo.typeStr == 'outOfArea'">
            超区
          </div>
          <div class="text-address" v-show="devInfo.typeStr == 'offSite'">
            站外
          </div>
          <div class="text-address" v-show="devInfo.typeStr == 'noSignal'">
            无信号
          </div>
          <div class="text-address" v-show="devInfo.typeStr == 'prohibitArea'">
            禁区停车
          </div>
          <div class="text-date">
            上次骑行: <span>{{ devInfo.lastUsedDateTime }}</span>
          </div>
          <div class="text-date">
            信号时间: <span>{{ devInfo.batDt }}</span>
          </div>
          <div class="text-date">
            定位时间: <span>{{ devInfo.posDt }}</span>
          </div>
        </div>
        <div class="electric">
          <div class="ele-inner" style="
                                                                        width: 20px;
                                                                        height: 15px;
                                                                        position: absolute;
                                                                        top: 15px;
                                                                        left: 287px;
                                                                        background-color: #3ed471;
                                                                      " :style="electri"></div>
          <img src="./assets/images/ele-green.png" alt="" style="
                                                                        width: 46px;
                                                                        height: 23px;
                                                                        position: absolute;
                                                                        top: 11px;
                                                                        right: 51px;
                                                                      " />
          <span style="
                                                                        font-size: 20px;
                                                                        font-weight: 700;
                                                                        margin-top: 6px;
                                                                        display: inline-block;
                                                                        position: absolute;
                                                                        right: 8px;
                                                                        color: #3ed471;
                                                                      ">{{ devInfo.soc }}%</span>
        </div>
        <div class="rightAngle">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="obtuseAngle">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 亏电点标注 -->
    <div class="noUse" ref="feedData" v-show="feed">
      <div class="noUse-top">
        车辆编号 :
        <span class="useCode" style="font-size: 18px; display: inline-block; margin-left: 5px">
          {{ devInfo.userCode }}</span>
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      margin-left: 172px;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      vertical-align: baseline;
                                                                    " />
      </div>

      <div class="noUse-bottom">
        <div class="img" style="display: flex; justify-content: center; align-items: center">
          <div class="circle" style="border: 3px solid #ffa202">
            <img src="./assets/images/yellowCar.png" alt="" style="width: 50px; height: 40px" />
          </div>
          <div class="status" style="background-color: #ffa202; width: 50px">
            <span>亏电</span>
          </div>
        </div>

        <div class="text" style="width: 236px">
          <div class="text-position" style="margin-top: 9px">最近站点：</div>
          <div class="text-address" :title="devInfo.parkPoint">
            {{ devInfo.lastParkPointName }}
          </div>
          <div class="text-date">
            上次骑行: <span>{{ devInfo.lastUsedDateTime }}</span>
          </div>
          <div class="text-date">
            信号时间: <span>{{ devInfo.batDt }}</span>
          </div>
          <div class="text-date">
            定位时间: <span>{{ devInfo.posDt }}</span>
          </div>
        </div>
        <div class="electric">
          <div class="ele-inner" style="
                                                                        width: 20px;
                                                                        height: 15px;
                                                                        position: absolute;
                                                                        top: 15px;
                                                                        left: 287px;
                                                                        background-color: #ffa202;
                                                                      " :style="electri"></div>
          <img src="./assets/images/ele-yellow.png" alt="" style="
                                                                        width: 46px;
                                                                        height: 23px;
                                                                        position: absolute;
                                                                        top: 11px;
                                                                        right: 51px;
                                                                      " />
          <span style="
                                                                        font-size: 20px;
                                                                        font-weight: 700;
                                                                        margin-top: 6px;
                                                                        display: inline-block;
                                                                        position: absolute;
                                                                        right: 8px;
                                                                        color: #ffa202;
                                                                      ">{{ devInfo.soc }}%</span>
        </div>
        <div class="rightAngle">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="obtuseAngle">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 站点点标注 -->
    <div class="noUse" ref="parkData" v-show="parkPoint" style="positon: raletive; height: 237px">
      <div class="noUse-top">
        站点编号: {{ devInfo.parkPointId
        }}
        <!-- 关闭窗体按钮 -->
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      position: absolute;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      top: 10px;
                                                                      right: 13px;
                                                                    " />
      </div>
      <!-- 窗体数据 -->
      <div class="noUse-bottom" style="height: 192px; background-size: 100% 192px">
        <!-- logo -->
        <div class="img" style="
                                                                      display: flex;
                                                                      justify-content: center;
                                                                      align-items: center;
                                                                      padding: 0;
                                                                      height: 197px;
                                                                    ">
          <div class="circle">
            <img src="./assets/images/parkLogo.png" alt="" style="width: 50px; height: 47px" />
          </div>
          <div style="
                                                                        width: 100px;
                                                                        height: 40px;
                                                                        font-size: 32px;
                                                                        font-weight: 700;
                                                                        text-align: center;
                                                                      ">
            {{ devInfo.parkNum }}
          </div>
        </div>
        <!-- 内容 -->
        <div class="text">
          <!-- 地址 -->
          <div class="text-address" style="margin-top: 20px" :title="devInfo.name">
            {{ devInfo.name }}
          </div>
          <!-- 今天 -->
          <div class="text-date" style="
                                                                        display: flex;
                                                                        margin-top: 15px;
                                                                        width: 205px;
                                                                        justify-content: space-between;
                                                                      ">
            <div class="div" style="display: flex">
              <div class="borrow" :style="today_lend">{{ lendNums[0] }}</div>
              <div class="return" :style="today_return">
                {{ returnNums[0] }}
              </div>
            </div>
            今天
          </div>
          <!-- 昨天 -->
          <div class="text-date" style="
                                                                        display: flex;
                                                                        margin-top: 10px;
                                                                        width: 205px;
                                                                        justify-content: space-between;
                                                                      ">
            <div class="div" style="display: flex">
              <div class="borrow" :style="yesterday_lend">
                {{ lendNums[1] }}
              </div>
              <div class="return" :style="yesterday_return">
                {{ returnNums[1] }}
              </div>
            </div>
            昨日
          </div>
          <!-- 前天 -->
          <div class="text-date" style="
                                                                        display: flex;
                                                                        margin-top: 10px;
                                                                        width: 205px;
                                                                        justify-content: space-between;
                                                                      ">
            <div class="div" style="display: flex">
              <div class="borrow" :style="before_lend">{{ lendNums[2] }}</div>
              <div class="return" :style="before_return">
                {{ returnNums[2] }}
              </div>
            </div>
            前日
          </div>
        </div>
        <!-- 窗体装饰 -->
        <div class="rightAngle">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="obtuseAngle">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 运维 工作点标注-->
    <div class="operation_point" ref="workData" v-show="workEvent">
      <div class="operation-top">
        运维人员: {{ operationObj.selected }}
        <!-- 关闭窗体按钮 -->
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      position: absolute;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      top: 10px;
                                                                      right: 13px;
                                                                    " />
      </div>

      <!-- 窗体数据 -->
      <div class="operation-bottom">
        <!-- logo -->
        <div class="operation_img">
          <img :src="operation_imgs" alt="" style="width: 75px; height: 75px" />
          <div style="margin-top: 5px; font-size: 16px" v-show="devInfo.num">
            数量：{{ devInfo.num }}
          </div>
          <div style="font-size: 16px">
            {{ operationObj.type }}
          </div>
        </div>

        <!-- 内容 -->
        <div class="operation_text">
          <div class="workingProcedure" style="margin-top: 9px">
            {{ devInfo.typeStr }}
          </div>
          <div class="workingText">{{ operationObj.userCode }}</div>
          <div class="workingText">时间：{{ operationObj.time }}</div>
          <div class="workingText" :title="operationObj.changeBefore">
            {{ operationObj.changeBefore }}
          </div>
          <div class="workingText" :title="operationObj.changeAfter">
            {{ operationObj.changeAfter }}
          </div>
        </div>

        <!-- 窗体装饰 -->
        <div class="operation_right">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="operation_obtuse">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 运维 人员点标注 -->
    <div class="operation_point" ref="operationData" v-show="operation">
      <div class="operation-top">
        运维ID: {{ devInfo.id }}
        <!-- 关闭窗体按钮 -->
        <img @click="closeInfoWindow" src="./assets/images/close.png" style="
                                                                      position: absolute;
                                                                      width: 21px;
                                                                      height: 21px;
                                                                      top: 10px;
                                                                      right: 13px;
                                                                    " />
      </div>

      <!-- 窗体数据 -->
      <div class="operation-bottom">
        <!-- logo -->
        <div class="operation_img">
          <div>
            <img src="./assets/images/operation_detail.png" alt="" style="width: 75px; height: 75px" />
          </div>
          <div style="
                                                                        background-color: #0ea9d8;
                                                                        margin-top: 5px;
                                                                        width: 46px;
                                                                        text-align: center;
                                                                      ">
            工作中
          </div>
        </div>
        <!-- 内容 -->
        <div class="operation_text">
          <div class="workingProcedure" style="margin-top: 19px">
            {{ devInfo.name }}
          </div>
          <div class="workingText" style="margin-top: 2px">
            手机：{{ devInfo.phone }}
          </div>
          <div class="workingText">上班打卡：{{ operationDate }}</div>
          <div class="workingText" style="margin-left: 80px">
            {{ operationTime }}
          </div>
        </div>
        <!-- 窗体装饰 -->
        <div class="operation_right">
          <img src="./assets/images/rightAngle.png" alt="" />
        </div>
        <div class="operation_obtuse">
          <img src="./assets/images/obtuseAngle.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./assets/css/app.css";
@import url("//unpkg.com/element-ui@2.15.10/lib/theme-chalk/index.css");

html,
body,
#app {
  height: 100%;
  width: 100%;
  position: relative;
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  background: hsl(183, 97%, 27%);
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background: #06b0b9;
  -webkit-box-shadow: none;
}

/* 站点hover */
.borrow,
.return {
  width: 80px;
  text-align: center;
  background-color: #f39800;
}

.return {
  background-color: #0ea9d8;
}

/* 闲置hover */
.noUse {
  width: 380px;
  height: 200px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noUse-top {
  height: 41px;
  /* background-color: #1D444D; */
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 0 0 10px;
  background-image: url("./assets/images/bgc-top.png");
  background-size: 100% 41px;
}

.noUse-bottom {
  height: 157px;
  /* background-color: #051113; */
  /* opacity: 0.7; */
  display: flex;
  background-image: url("./assets/images/bgc.png");
  background-size: 100% 157px;
  position: relative;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #00b1ce;
}

.img {
  height: 141px;
  width: 140px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-top: 18px;
  /* background-color: #fff; */
}

.status {
  width: 103px;
  height: 24px;
  font-size: 15px;
  background-color: #0ea9d8;
  /* background-color: #00b1ce; */
  /* margin-top: 11px; */
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  width: 194px;
}

.text-position {
  margin-top: 17px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.text-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.text-date,
.text-times {
  color: #fff;
  font-size: 17px;
}

.text-times {
  margin-left: 111px;
}

.rightAngle,
.rightAngle img {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.obtuseAngle,
.obtuseAngle img {
  width: 44px;
  height: 21px;
  position: absolute;
  right: 9px;
  bottom: -1px;
}

.content-window-card {
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
  /* width: auto; */
  width: 28rem;
  padding: 0;
}

.content-window-card p {
  height: 2rem;
}

.custom-info {
  border: solid 1px silver;
}

div.info-top {
  position: relative;
  background: none repeat scroll 0 0 #f9f9f9;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}

div.info-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.info-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.info-top img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}

div.info-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.info-bottom img {
  position: relative;
  z-index: 104;
}

/* span {
  margin-left: 5px;
  font-size: 11px;
} */

.info-middle img {
  float: left;
  margin-right: 6px;
}

.info-span {
  /* margin-left: 35px; */
  font-size: 11px;
}

.info-div {
  width: 140px;
  display: inline-block;
  margin-left: 10px;
}

.info-img {
  width: 40px;
  height: 40px;
}

.info-a-title {
  /* color: #000000; */
  font-size: 16px;
}

#container {
  width: 100%;
  height: 100%;
  background-color: #ccc;
}

.selection {
  width: 787px;
  height: 85px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.times {
  width: 754px;
  height: 69px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: #0b484d;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.noUseList {
  position: absolute;
  top: 425px;
  left: 30px;
  width: 315px;
  font-size: 16px;
  background-image: url("./assets/images/data_total_bgc.png");
  background-size: 315px 330px;
}

.data_total {
  width: 376px;
  height: 210px;
  padding: 5px 10px 10px 0;
  position: absolute;
  top: 104px;
  left: 30px;
  background-image: url("./assets/images/data_total_bgc.png");
  background-size: 376px 210px;
}

.data_total_title {
  width: 100%;
  height: 30px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  margin-top: 4px;
}

.data_total_type {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-evenly;
  font-weight: 700;
}

.data_total_type p {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.data_total_type div {
  color: 20px;
  width: 80px;
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}

.times::after {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  position: absolute;
  top: 0;
  right: 7px;
  background-color: #0b484d;
}

.dayTime {
  width: 831px;
  height: 50px;
  position: absolute;
  top: 28px;
  right: 20px;
  display: flex;
  justify-content: space-evenly;
}

/* 进度条 */
.el-slider__runway {
  /* width: 596px; */
  height: 19px;
  margin: 0;
  background-color: transparent;
}

.el-slider__bar {
  height: 16px;
  background-color: #3f767d;
  border-radius: 0;
  width: 10;
}

.el-slider__button-wrapper {
  top: -10px;
  margin-left: 7px;
}

.el-slider__button {
  background-color: #b5e1ea;
  width: 15px;
  border: 0;
  border-radius: 0;
  height: 18px;
}

/* 时间选择器 */
input[type="text"] {
  background-color: #3f767d;
  border: none;
  border-radius: 0;
  height: 24px;
}

.el-select-dropdown {
  background-color: #0b484d;
  color: #fff;
  border: none;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #70a3ab;
}

.input__inner {
  width: 535px;
}

.el-input__inner {
  height: 24px;
  color: #fff;
}

input[type="text"] {
  color: #fff;
}

.el-icon-time:before {
  content: "";
}

.el-range-editor,
.el-input__inner,
.el-range-input {
  background-color: #467681;
  border: 0;
  border-radius: 0;
}

.el-range-editor {
  border: 1px solid #3f767d;
}

.el-date-editor .el-range__close-icon {
  font-size: 0;
  display: none;
}

.el-date-editor .el-range-separator {
  display: inline-block;
  height: 100%;
  padding: 0 5px;
  margin: 0;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  width: 5%;
  color: #fff;
}

.el-input {
  margin-right: 5px;
}

.el-date-editor .el-range-input,
.el-date-editor,
.el-range-input {
  color: #fff;
}

.el-input__prefix,
.el-input__suffix {
  top: 9px;
}

/* 日历 */
.el-picker-panel,
.el-picker-panel__footer {
  background-color: #0b484d;
  color: #fff;
  border-top: 1px solid #b5e1ea;
}

.el-date-range-picker__content.is-left {
  border-right: 1px solid #b5e1ea;
}

.el-date-range-picker__time-header {
  border-bottom: 1px solid #b5e1ea;
}

.el-picker-panel,
.el-date-table th {
  border: none;
}

.popper__arrow::after {
  display: none;
}

.el-date-table th {
  color: #fff;
}

.el-date-table td.in-range div {
  background-color: #3f767d;
  border-radius: 0;
  padding: 0;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #194247;
  border-radius: 0;
  width: 42px;
  height: 32px;
  line-height: 32px;
}

.el-icon-d-arrow-right:before,
.el-icon-d-arrow-left:before,
.el-icon-arrow-left:before,
.el-icon-arrow-right:before {
  color: #3f767d;
  font-size: 20px;
}

.el-time-panel {
  background-color: #0b484d;
}

.el-time-spinner__item {
  color: #b5e1ea;
}

.el-time-spinner__item.active:not(.disabled) {
  color: #fff;
}

.el-time-panel__btn,
.el-time-panel__btn.confirm {
  color: #b5e1ea;
}

.el-button--mini {
  color: #b5e1ea;
}

.el-time-spinner__item:hover:not(.disabled):not(.active) {
  background-color: transparent;
}

.el-time-panel {
  width: 149px;
  border: 1px solid #b5e1ea;
  background-color: #2d676d;
}

.el-time-panel__footer {
  border: none;
}

.el-time-panel__content::after,
.el-time-panel__content::before {
  content: " ";
  border: none;
  background-color: #70a3ab;
  width: 150px;
  padding: 0;
  margin-left: 0;
  margin-top: -4px;
}
</style>
<script>
// import AMapLoader from "@amap/amap-jsapi-loader";
import axios from "axios";
import chooseView from "./components/chooseView.vue";
import timesView from "./components/timesView.vue";
import dayView from "./components/dayView.vue";
import weatherView from "./components/weatherView.vue";
import { com } from "../proto";

// import { Select } from 'element-ui';
export default {
  data() {
    return {
      //公共变量
      wsUrl: 'ws://124.70.216.117:8990/',
      // wsUrl:'ws://192.168.5.42:8989/',
      base: "http://124.70.216.117:8989/v1/", //正式服
      // base: "http://192.168.5.42:8989/v1/",//测试服
      map: null,
      closeBtn: false,
      globalTime: "",
      stepOnly: 0,
      devInfo: [], //窗体数据
      details: {},
      moduleChange: "",
      closeEventBtn: false,
      aLiYun: "https://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/", //阿里云基础地址

      // 骑行模块
      ridingJudge: false,
      ridingData: [], //骑行数据
      riding_total: false, //骑行数据统计
      ridingLayer: [], //骑行层
      ridingLayerf: [], //骑行层
      riding: false, //骑行点标注
      ridingCars: 0, //骑行车辆
      allCars: 0, //全部车辆

      //闲置模块
      isAbnormalFromAdmin: [],
      freeJudge: false, //闲置模块是否显示
      freeData: [], //闲置数据
      data_total: false, //闲置数据统计
      labelsLayer: [], //闲置层
      labelsMarker24: [], //闲置点标记
      noUse: false, //闲置点标注
      beginFree: 0, //闲置进度条数据
      freeCars: 0, //闲置车辆数量
      freeObj: {
        noOrder24: 0,
        noOrder48: 0,
        noOrder72: 0,
        noUse24: 0,
        noUse48: 0,
        noUse72: 0,
        noUseAll: 0,
        noOrderAll: 0,
      },
      type24: 0,
      noUseList: [],
      noUserList24: [],
      noUserList48: [],
      noUserList72: [],
      noOrderList24: [],
      noOrderList48: [],
      noOrderList72: [],
      activeCarNum: "",
      noUsecircle: null,
      closeFailure: false,//屏蔽运维故障开关

      // 异常模块
      malfunctionJudge: false, //异常模块显示
      malfunction: false, //故障点标注
      malfunctionData: [], //异常数据
      fault_total: false, //异常数据统计显示
      malfunctionLayer: [], //异常层
      faultsMarkers: [], //异常点标记数据
      malfunctionObj: {
        all: 0,
        zone: 0,
        outside: 0,
        fault: 0,
        noSignal: 0,
        powerAbnormality: 0,
        prohibit: 0,
      },

      //亏电模块
      feedJudge: false, //亏电模块是否显示
      feedData: [], //亏电数据
      feed_total: false, //亏电数据统计
      feedLayer: [], //亏电层
      feedMarkers: [], //亏电点标记数据
      feed: false, //亏电点标注
      allFeed: 0, //所有电量
      feed40: 0, //亏电15-40
      feed15: 0, //亏电小于15
      feeds: 0, //亏电数量

      //站点模块
      parkData: [], //站点数据
      prohibitData: [], //禁停站点数据
      parkLayer: [], //站点层
      parkPoint: false, //站点点标注
      lendNums: [], //站点借出数据
      returnNums: [], //站点归还数据
      parkJudge: false, //站点模块是否显示
      parkPolyline: [], //站点关系数据

      //运维模块
      operationData: [], //运维数据
      operationLayer: [], //运维人员层
      historyPersonLayer: [],
      historyPositionLayer: [], //运维position标记
      personLayer: [], //运维事件层
      operationLayerOne: [],
      operationHistoryData: [], //运维轨迹数据
      historyTime: [],
      chartData: false,
      operationChart: false,
      historyRoadOnly: [],
      operationSlider: 0,
      chart_changePower: [],
      chart_dispatch: [],
      chart_moveCar: [],
      operationObj: {
        all: 0, //全部运维人数
        work: 0, //在岗运维人数
        name: [], //运维人员名单
        num: {}, //运维事件数量
        selected: "选择运维",
        event: [], //运维事件表格数据
        type: "有效调度",
        changeBefore: "",
        changeAfter: "",
        time: "",
        userCode: "",
      },
      polyline: [], //运维人员轨迹
      operation_imgs: "", //运维点标注图片
      operation: false, //运维人员点标注
      workEvent: false, //运维事件点标记
      operation_total: false, //运维数据统计
      personnelList: false, //运维事件表格是否显示
      operation_list: false, //控制运维人员列表显示
      operationJudge: false, //运维模块是否显示
      chartTime: "00:00",
      activeNum: 0,
      EventNum: 0,
      personEvent: [], //人员轨迹备份
    };
  },

  created() {
    this.token = window.location.href.split("=")[1].split("#")[0]; //获取token
  },

  components: {
    chooseView, //功能选择模块
    timesView, //时间选择模块
    dayView, //时间，日期
    weatherView, //天气
  },

  computed: {
    //电量显示
    electri() {
      const style = {};
      if (this.devInfo.soc == 0) {
        style.width = "0px";
      } else if (this.devInfo.soc > 0 && this.devInfo.soc <= 10) {
        style.width = "3px";
      } else if (this.devInfo.soc > 10 && this.devInfo.soc <= 20) {
        style.width = "6px";
      } else if (this.devInfo.soc > 20 && this.devInfo.soc <= 30) {
        style.width = "9px";
      } else if (this.devInfo.soc > 30 && this.devInfo.soc <= 40) {
        style.width = "12px";
      } else if (this.devInfo.soc > 40 && this.devInfo.soc <= 50) {
        style.width = "15px";
      } else if (this.devInfo.soc > 50 && this.devInfo.soc <= 60) {
        style.width = "20px";
      } else if (this.devInfo.soc > 60 && this.devInfo.soc <= 70) {
        style.width = "23px";
      } else if (this.devInfo.soc > 70 && this.devInfo.soc <= 80) {
        style.width = "26px";
      } else if (this.devInfo.soc > 80 && this.devInfo.soc <= 90) {
        style.width = "29px";
      } else if (this.devInfo.soc > 90 && this.devInfo.soc <= 95) {
        style.width = "32px";
      } else if (this.devInfo.soc > 95 && this.devInfo.soc <= 100) {
        style.width = "35px";
      }
      return style;
    },
    today_lend() {
      if (!this.returnNums[0] && !this.lendNums[0]) {
        return;
      }
      const style = {};
      let todayLend =
        this.lendNums[0] / (this.lendNums[0] + this.returnNums[0]);
      style.width = todayLend * 160 + "px";
      return style;
    },
    today_return() {
      if (!this.returnNums[0] && !this.lendNums[0]) {
        return;
      }
      const style = {};
      let today_lend =
        this.lendNums[0] / (this.lendNums[0] + this.returnNums[0]);
      let today_return = 160 - today_lend * 160;
      style.width = today_return + "px";
      return style;
    },
    yesterday_lend() {
      if (!this.returnNums[1] && !this.lendNums[1]) {
        return;
      }
      const style = {};
      let yesterdayLend =
        this.lendNums[1] / (this.lendNums[1] + this.returnNums[1]);
      style.width = yesterdayLend * 160 + "px";

      return style;
    },
    yesterday_return() {
      if (!this.returnNums[1] && !this.lendNums[1]) {
        return;
      }
      const style = {};
      let yesterdayLend =
        this.lendNums[1] / (this.lendNums[1] + this.returnNums[1]);
      let yesterdayReturn = 160 - yesterdayLend * 160;
      style.width = yesterdayReturn + "px";
      return style;
    },
    before_lend() {
      if (!this.returnNums[2] && !this.lendNums[2]) {
        return;
      }
      const style = {};
      let beforeLend =
        this.lendNums[2] / (this.lendNums[2] + this.returnNums[2]);
      style.width = beforeLend * 160 + "px";
      return style;
    },
    before_return() {
      if (!this.returnNums[2] && !this.lendNums[2]) {
        return;
      }
      const style = {};
      let beforeLend =
        this.lendNums[2] / (this.lendNums[2] + this.returnNums[2]);
      let beforeReturn = 160 - beforeLend * 160;
      style.width = beforeReturn + "px";
      return style;
    },
    statusBgc() {
      const style = {};
      if (this.devInfo.noUseHour > 48 && this.devInfo.noUseHour <= 72) {
        style.backgroundColor = "#F4B844";
      } else if (this.devInfo.noUseHour > 72) {
        style.backgroundColor = "#FF3A35";
      }
      return style;
    },
    operationDate() {
      if (this.devInfo.clockTime && this.devInfo.clockTime != undefined) {
        let date = this.devInfo.clockTime.split(" ");
        return date[0];
      }
    },
    operationTime() {
      if (this.devInfo.clockTime && this.devInfo.clockTime != undefined) {
        let date = this.devInfo.clockTime.split(" ");
        return date[1];
      }
    },
    timeModuleControl() {
      let enable =
        this.freeJudge ||
        this.malfunctionJudge ||
        this.feedJudge ||
        this.operationJudge ||
        this.ridingJudge;
      return enable;
    },
  },

  mounted() {
    this.map = this.initMap(); //初始化地图
    this.map.clearMap();
    this.addArea(); //绘制运营区
    this.map.on("zoomchange", this.getZoom);
    let param = {
      start: "2023-03-30 19:00",
      end: "2023-03-30 23:59",
      hour: "0",
      minute: "30",
    };

    // 骑行
    this.wsRequest(this.wsUrl + "realTime", 2).then((res) => {
      //长链接请求
      console.log(res, "长连接实时数据--骑行");
      this.ridingData = res.data;
    })

    //闲置
    this.wsRequest(this.wsUrl + "realTime", 1).then((res) => {
      //长链接请求
      console.log(res, "长连接实时数据--闲置");
      this.freeData = res.data;
    })

    //异常
    this.wsRequest(this.wsUrl + "realTime", 11).then((res) => {
      //长链接请求
      console.log(res, "长连接实时数据--异常");
      this.malfunctionData = res;
    })

    this.wsRequest(this.wsUrl + "realTime", 10).then((res) => {
      //长链接请求
      console.log(res, "长连接实时数据--异常统计");
      if (res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          switch (element.name) {
            case "站外":
              this.malfunctionObj.outside = element.num;
              break;

            case "超出区域":
              this.malfunctionObj.zone = element.num;

              break;
            case "无信号":
              this.malfunctionObj.noSignal = element.num;

              break;
            case "电池异常":
              this.malfunctionObj.powerAbnormality = element.num;

              break;
            case "禁停区停车":
              this.malfunctionObj.prohibit = element.num;

              break;
            case "故障":
              this.malfunctionObj.fault = element.num;

              break;

            default:
              break;
          }
        }
      }
    })

    //馈电
    this.wsRequest(this.wsUrl + "realTime", 6).then((res) => {
      //长链接请求
      console.log(res, "长连接实时数据--馈电");
      this.feedData = res.data;
    })

    // 禁停区
    this.wsRequest(this.wsUrl + "realTime", 3).then((res) => {
      //长链接请求
      this.prohibitData = res.data;
    });

    //站点
    this.wsRequest(this.wsUrl + "realTime", 4).then((res) => {
      //长链接请求
      console.log(res, "站点");
      this.parkData = res.data;
    });

    // 站点进出数量
    // this.wsRequest(this.wsUrl+"realTime", 4, param).then((res) => {
    //   //长链接请求
    //   console.log(res, "长连接请求--站点进出数量");
    // });

    //运维轨迹
    this.wsRequest(this.wsUrl + "realTime", 7).then((res) => {
      //长链接请求
      this.operationHistoryData = res.data;
      console.log(res, "长连接请求--运维轨迹");
    });

    //运维工作统计
    this.wsRequest(this.wsUrl + "realTime", 8).then((res) => {
      //长链接请求
      console.log(res, "长连接请求--运维工作统计");
      this.operationObj.num = res;
    });

    //运维基本信息
    this.wsRequest(this.wsUrl + "realTime", 9).then((res) => {
      //长链接请求
      this.operationData = res.data;
      console.log(res, "长连接请求--运维");
    });


    // http请求
    // 1.骑行
    // this.request("ridingBikeController", "get", "ridingData");
    // 2.闲置
    // this.request("unused/bar", "get", "freeData");
    // 3.异常
    // this.request("abnormal", "get", "malfunctionNum");
    // this.request("abnormal/allInfo", "get", "malfunctionData");
    // 4.亏电
    // this.request("noPower", "get", "feedData");
    // 5.站点
    // this.request("parkPoint/parkBikeInfoBase", "get", "parkData");
    // this.request("prohibitArea", "get", "prohibitData");
    // 站点关系
    // this.request("pointRelation/listUsedFuncProgram", "get", "relationData");
    // 6.运维
    // this.request("maintenanceWorker", "get", "operationData");
    // this.request(
    //   "maintenanceWorker/historyByLog",
    //   "get",
    //   "operationHistoryData"
    // );
    // this.request("maintenanceWorker/statistics", "get", "operationNum");
  },

  watch: {
    moduleChange: function (newVal, oldVal) {
      this.operationSlider = 0;
      this.$refs.timeSelect.initData();
    },
  },

  methods: {
    // 初始化地图
    initMap() {
      let map = new AMap.Map("container", {
        zoom: 15,
        zooms: [3, 20],
        expandZoomRange: true,
        center: [120.202991, 30.185332],
        mapStyle: "amap://styles/blue", //设置地图的显示样式
        plugins: [
          "AMap.DistrictSearch",
          "AMap.Polygon",
          "AMap.Size",
          "AMap.Pixel",
          "AMap.MassMarks",
          "AMap.InfoWindow",
          "AMap.Marker",
          "AMap.MouseTool",
          "AMap.Driving",
          "AMap.Text",
        ],
      });
      return map;
    },

    // 请求
    request(url, method, type) {
      axios({
        url,
        method,
        baseURL: this.base,
        // baseURL: "",
        params: {
          token: this.token,
        },
      })
        .then((res) => {
          switch (type) {
            case "freeData":
              this.freeData = res.data.data;
              break;
            case "parkData":
              this.parkData = res.data.data;
              console.log(this.parkData, ">>>>>>>>>>>>>>>>>>>>>");
              break;
            case "prohibitData":
              this.prohibitData = res.data.data;
              break;
            case "ridingData":
              this.ridingData = res.data.data;
              break;
            case "feedData":
              this.feedData = res.data.data;
              break;
            case "malfunctionData":
              this.malfunctionData = res.data.data;
              break;
            case "malfunctionNum":
              this.malfunctionObj.zone = res.data.data[0].num;
              this.malfunctionObj.outside = res.data.data[1].num;
              this.malfunctionObj.noSignal = res.data.data[2].num;
              this.malfunctionObj.powerAbnormality = res.data.data[3].num;
              this.malfunctionObj.prohibit = res.data.data[4].num;
              this.malfunctionObj.fault = res.data.data[5].num;
              break;
            case "relationData":
              this.relationData = res.data.data;
              console.log(this.relationData, "relationData");
              break;
            case "operationData":
              this.operationData = res.data.data;
              break;
            case "operationHistoryData":
              this.operationHistoryData = res.data.data;
              break;
            case "operationNum":
              this.operationObj.num = res.data.data;
              break;
          }
        })
        .catch((error) => {
          console.log(error, "请求");
        });
    },

    // 长链接请求
    wsRequest(url, service, params) {
      console.log(url, service, params);
      let that = this;
      return new Promise(function (resolve, rej) {
        let test = com.bxkz.OuterClass.Request.create(); //参数
        test.service = service;
        if (params) {
          test.params = params;
        }
        let uint8Array = com.bxkz.OuterClass.Request.encode(test).finish();
        // let s = new WebSocket(url, ["524dee0a-c3c8-4b59-aaa6-bb30e9ed3dee"]); //建立连接
        let s = new WebSocket(url, that.token); //建立连接
        s.addEventListener("open", function () {
          // 发送参数，解码，关闭连接
          s.send(uint8Array);
        });
        s.addEventListener("message", function (a) {
          //接收数据
          a.data.arrayBuffer().then((res) => {
            const bytes = new Uint8Array(res);
            const decodeObj = com.bxkz.OuterClass.Resp.decode(bytes);
            let type = decodeObj.data.type_url.split(".")[5];
            let weather = com.bxkz.OuterClass[type].decode(
              decodeObj.data.value
            );
            // console.log(weather,"weather");
            resolve(weather);
            // s.close();
          });
        });
        // s.close();
      });
    },

    // 获取地图缩放倍数
    getZoom() {
      this.zoom = this.map.getZoom(); //获取当前地图级别
      // var zoom = this.map.getZoom(); //获取当前地图级别
      console.log(this.zoom, "zoom");
      if (this.ridingJudge) {
        console.log("骑行点标注");
        this.ridingClick(this.ridingJudge);
      } else if (this.feedClick) {
        this.feedClick(this.feedJudge);
        console.log("闲置点标注");
      }
    },

    // 绘制运营区域
    addArea() {
      axios({
        // url: "http://192.168.5.42/geo/getGeo?accountId=100201",
        url: "http://push.bxkuaizu.com/geo/getGeo?accountId=100201",
        method: "get", //
      })
        .then((res) => {
          let polygon = res.data.data.point[0];
          let path = [];
          polygon = polygon.split(";");
          polygon.forEach((e) => {
            e = e.split(",");
            e = new AMap.LngLat(Number(e[0]), Number(e[1]));
            path.push(e);
          });
          // 创建折线实例
          var Polygon = new AMap.Polygon({
            path: path,
            strokeWeight: 5,
            strokeColor: "#FFB702",
            strokeStyle: "dashed",
            lineJoin: "round",
            // fillOpacity: 0.3,
            fillColor: "transparent",
          });

          // 将折线添加至地图实例
          this.map.add(Polygon);
        })
        .catch((error) => {
          console.log("地图", error);
        });
    },

    // 折线图
    getLineChart() {
      var chartDom = document.getElementById("lineChart");
      var myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        title: {
          textStyle: {
            fontWeight: "normal",
            color: "#A3FFFC", //标题颜色
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: 30,
          top: 35,
          left: 40,
          right: 25,
          // containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          silent: true,
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          //  改变x轴颜色
          axisLine: {
            lineStyle: {
              color: "transparent",
            },
          },
          //  改变x轴字体颜色和大小
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: "14",
            },
          },
          axisPointer: {
            type: "line",
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              //y轴分隔线样式设置
              color: "#707070", //颜色
            },
          },
          //  改变y轴颜色
          axisLine: {
            show: false,
          },
          //  改变y轴字体颜色和大小
          axisLabel: {
            textStyle: {
              color: "#fff",
              fontSize: "14",
            },
          },
        },

        series: [
          {
            name: "换电",
            type: "line",
            data: this.chart_changePower,
            itemStyle: {
              normal: {
                color: "#95F0F3", //  给折线添加颜色
              },
            },
            symbol:
              "image://http://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/bluePoint.png", //标记的图形（拐点）
            //标记的图形（拐点）
            symbolSize: 10,
            markLine: {
              silent: true,
              data: [
                {
                  silent: false,
                  xAxis: this.chartTime,
                },
              ],
            },
          },
          {
            name: "调度",
            type: "line",
            data: this.chart_dispatch,
            itemStyle: {
              normal: {
                color: "#FFB701", //  给折线添加颜色
              },
            },
            symbol:
              "image://http://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/yellowPoint.png", //标记的图形（拐点）
            //标记的图形（拐点）
            symbolSize: 10,
          },
          {
            name: "挪车",
            type: "line",
            data: this.chart_moveCar,
            itemStyle: {
              normal: {
                color: "#CF4846", //  给折线添加颜色
              },
            },
            symbol:
              "image://http://kanban-bxkz.oss-cn-hangzhou.aliyuncs.com/icons/redPoint.png", //标记的图形（拐点）
            symbolSize: 10,
          },
        ],
      };
      option && myChart.setOption(option);
    },

    //进度滑块
    sliderClick(value, judge, show) {
      this.beginFree = value; //滑块数据
      this.show = show;
      if (judge == "freeFlag") {
        this.freeClick(show);
      } else if (judge == "feedFlag") {
        this.feedClick(show);
      }
    },

    //更改地图样式
    changeSkin() {
      console.log(this.closeBtn);
      this.closeBtn = !this.closeBtn;
      if (this.closeBtn) {
        this.map.setMapStyle("amap://styles/normal");
      } else {
        this.map.setMapStyle("amap://styles/blue");
      }
    },

    // 隐藏闲置运维故障
    hideFailure() {
      this.closeFailure = !this.closeFailure;
      this.freeClick(this.freeJudge)
      console.log(this.closeFailure, "屏蔽运维故障");

    },

    //历史回溯
    historyRequest(e) {
      console.log(e, "eeeeeeeeeeeee");

      if (!e.hour) {
        e.hour = 0;
      }
      if (!e.min) {
        e.min = 0;
      }
      // let option =
      //   "/logs?start=" +
      //   e.time[0] +
      //   "&end=" +
      //   e.time[1] +
      //   "&hour=" +
      //   e.hour +
      //   "&minute=" +
      //   e.min +
      //   "&token=" +
      //   this.token;
      // console.log(option, "option");
      // let optionOperation =
      // "?start=" + e.time[0] + "&end=" + e.time[1] + "&token=" + this.token;
      console.log(e, "eeeeeeeeeeeee");
      let params = {
        start: e.time[0],
        end: e.time[1],
        hour: e.hour + '',
        minute: e.min + '',
      };
      // 骑行
      if (this.ridingJudge) {
        // axios({
        //   url: this.base + "ridingBikeController" + option,
        // }).then((res) => {
        //   console.log(res, "riding");
        //   if (res.data.data.length != 0) {
        //     this.historyRiding = [];
        //     this.historyRiding = res.data.data;
        //     this.ridingData = this.historyRiding[0].data;
        //     this.globalTime = this.historyRiding[0].dt;
        //     this.ridingClick(this.ridingJudge);
        //     alert("查询骑行历史数据成功，请拖动滑块查看！");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });
        this.wsRequest(this.wsUrl +"history", 2, params).then((res) => {
          console.log(res, "骑行历史数据");
          if (res.data.length != 0) {
            this.historyRiding = [];
            this.historyRiding = res.data;
            this.ridingData = this.historyRiding[0].data.data;
            console.log(this.historyRiding, this.ridingData, "长连接历史数据--骑行");
            this.globalTime = this.historyRiding[0].dateTime;
            this.ridingClick(this.ridingJudge);
            alert("查询骑行历史数据成功，请拖动滑块查看！");
          } else {
            alert("请求的数据为空");
          }
        })
      }

      // 闲置
      if (this.freeJudge) {
        // axios({
        //   url: this.base + "unused/bar" + option,
        // }).then((res) => {
        //   if (res.data.data.length != 0) {
        //     this.historyFree = [];
        //     this.historyFree = res.data.data;
        //     this.freeData = this.historyFree[0].data;
        //     this.globalTime = this.historyFree[0].dt;
        //     this.freeClick(this.freeJudge);
        //     console.log(this.freeData, "free");
        //     alert("查询闲置历史数据成功，请拖动滑块查看！");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });

        this.wsRequest(this.wsUrl +"history", 1, params).then((res) => {
          //长链接请求
          console.log(res, "长连接历史数据--闲置");
          if (res.data.length != 0) {
            this.historyFree = [];
            this.historyFree = res.data;
            this.freeData = this.historyFree[0].data.data;
            this.globalTime = this.historyFree[0].dateTime;
            this.freeClick(this.freeJudge);
            console.log(this.freeData, "free");
            alert("查询闲置历史数据成功，请拖动滑块查看！");
          } else {
            alert("请求的数据为空");
          }
        })
      }

      // 异常
      if (this.malfunctionJudge) {
        // axios({
        //   url: this.base + "abnormal/allInfo" + option,
        // }).then((res) => {
        //   if (res.data.data.length != 0) {
        //     this.historyMalfunction = [];
        //     this.historyMalfunction = res.data.data;
        //     this.malfunctionData = this.historyMalfunction[0].data;
        //     this.globalTime = this.historyMalfunction[0].dt;
        //     console.log(this.malfunctionData, "malfunction");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });

        // axios({
        //   url: this.base + "abnormal" + option,
        // }).then((res) => {
        //   if (res.data.data.length != 0) {
        //     this.historyMalfunctionNum = [];
        //     this.historyMalfunctionNum = res.data.data;
        //     let Num = this.historyMalfunctionNum[0].data;
        //     this.malfunctionObj.zone = Num[0].num;
        //     this.malfunctionObj.outside = Num[1].num;
        //     this.malfunctionObj.noSignal = Num[2].num;
        //     this.malfunctionObj.powerAbnormality = Num[3].num;
        //     this.malfunctionObj.prohibit = Num[4].num;
        //     this.malfunctionObj.fault = Num[5].num;
        //     this.malfunctionClick(this.malfunctionJudge);
        //     alert("查询异常历史数据成功，请拖动滑块查看！");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });

        this.wsRequest(this.wsUrl +"history", 11, params).then((res) => {
          //长链接请求
          console.log(res, "长连接历史数据--异常");
          if (res.data.length != 0) {
            this.historyMalfunction = [];
            this.historyMalfunction = res.data;
            this.malfunctionData = this.historyMalfunction[0].data;
            this.globalTime = this.historyMalfunction[0].dateTime;
            console.log(this.malfunctionData, "malfunction");
          } else {
            alert("请求的数据为空");
          }
        })

        this.wsRequest(this.wsUrl +"history", 10, params).then((res) => {
          //长链接请求
          console.log(res, "长连接历史数据--异常统计");
          if (res.data.length != 0) {
            this.historyMalfunctionNum = [];
            this.historyMalfunctionNum = res.data;
            let Num = this.historyMalfunctionNum[0];
            this.malfunctionObj.zone = Num.abnormalStatistics.data[0].num;
            this.malfunctionObj.outside = Num.abnormalStatistics.data[1].num;
            this.malfunctionObj.noSignal = Num.abnormalStatistics.data[2].num;
            this.malfunctionObj.powerAbnormality = Num.abnormalStatistics.data[3].num;
            this.malfunctionObj.prohibit = Num.abnormalStatistics.data[4].num;
            this.malfunctionObj.fault = Num.abnormalStatistics.data[5].num;
            this.malfunctionClick(this.malfunctionJudge);
            alert("查询异常历史数据成功，请拖动滑块查看！");
          } else {
            alert("请求的数据为空");
          }
        })
      }

      // 亏电
      if (this.feedJudge) {
        console.log(this.feedJudge);
        // axios({
        //   url: this.base + "noPower" + option,
        // }).then((res) => {
        //   if (res.data.data.length != 0) {
        //     this.historyFeed = [];
        //     this.historyFeed = res.data.data;
        //     this.feedData = this.historyFeed[0].data;
        //     this.globalTime = this.historyFeed[0].dt;
        //     this.feedClick(this.feedJudge);
        //     alert("查询亏电历史数据成功，请拖动滑块查看！");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });

        this.wsRequest(this.wsUrl +"history", 6, params).then((res) => {
          //长链接请求
          console.log(res, "长连接实时数据--馈电");
          if (res.data.length != 0) {
            this.historyFeed = [];
            this.historyFeed = res.data;
            this.feedData = this.historyFeed[0].data.data;
            this.globalTime = this.historyFeed[0].dateTime;
            this.feedClick(this.feedJudge);
            alert("查询亏电历史数据成功，请拖动滑块查看！");
          } else {
            alert("请求的数据为空");
          }
        })
      }

      // 运维
      if (this.operationJudge) {
        console.log(this.operationJudge);
        // 工作轨迹
        // axios({
        //   url:
        //     this.base +
        //     "maintenanceWorker/historyByTimeInterval" +
        //     optionOperation,
        // }).then((res) => {
        //   if (res.data.data.length != 0) {
        //     this.historyOperation = [];
        //     this.operationHistoryData = res.data.data;
        //     this.operationClick(this.operationJudge);
        //     console.log(this.operationHistoryData, "maintenanceWorker");
        //   } else {
        //     alert("请求的数据为空");
        //   }
        // });

        // 事件统计
        // axios({
        //   url: this.base + "maintenanceWorker/statistics" + option,
        // }).then((res) => {
        //   console.log(res, "历史数据");
        //   if (res.data.data.length != 0) {
        //     this.chart_changePower = [];
        //     this.chart_dispatch = [];
        //     this.chart_moveCar = [];
        //     this.historyMalfunctionNum = [];
        //     this.historyMalfunctionNum = res.data.data.slice(0, 24);
        //     this.historyMalfunctionNum.forEach((x) => {
        //       this.chart_changePower.push(x.data.powerChangeNum);
        //       this.chart_dispatch.push(x.data.disNum);
        //       this.chart_moveCar.push(x.data.moveNum);
        //     });
        //     this.getLineChart(); //折线图
        //   } else {
        //   }
        // });

        //运维轨迹
        this.wsRequest(this.wsUrl +"history", 7, params).then((res) => {
          //长链接请求
          console.log(res, "长连接请求--运维轨迹历史");

          if (res.data.length != 0) {
            this.historyOperation = [];
            this.operationHistoryData = res.data;
            console.log(res.details, "detailsdetailsdetails");
            this.operationClick(this.operationJudge);
          } else {
            alert("请求的数据为空");
          }
        });

        //运维工作统计
        this.wsRequest(this.wsUrl +"history", 8, params).then((res) => {
          //长链接请求
          console.log(res, "长连接请求--运维工作统计历史");
          if (res.data.length != 0) {
            this.chart_changePower = [];
            this.chart_dispatch = [];
            this.chart_moveCar = [];
            this.historyMalfunctionNum = [];
            this.historyMalfunctionNum = res.data.slice(0, 24);
            this.historyMalfunctionNum.forEach((x) => {
              this.chart_changePower.push(x.data.powerChange);
              this.chart_dispatch.push(x.data.dispatch);
              this.chart_moveCar.push(x.data.move);
            });
            this.getLineChart(); //折线图
          } else {
          }
        });

        this.chartData = true;
        this.operationChart = false;
        alert("查询运维历史数据成功，请选择运维人员后拖动滑块查看！");
      }
    },

    //历史记录查看
    step(step) {
      this.stepOnly = step;
      console.log(step, "step");

      // 骑行
      if (this.ridingJudge) {
        if (this.historyRiding && this.historyRiding.length > step) {
          this.ridingData = this.historyRiding[step].data.data;
          this.operationSlider = this.historyRiding.length;
          this.globalTime = this.historyRiding[step].dateTime;
          this.ridingClick(this.ridingJudge);
        } else {
          // alert("已无数据，请重新查询！");
        }
      }

      // 闲置
      if (this.freeJudge) {
        if (this.historyFree && this.historyFree.length > step) {
          this.freeData = this.historyFree[step].data.data;
          this.operationSlider = this.historyFree.length;
          this.globalTime = this.historyFree[step].dateTime;
          this.freeClick(this.freeJudge);
        } else {
          // alert("已无数据，请重新查询！");
        }
      }

      // 异常
      if (this.malfunctionJudge) {
        if (this.historyMalfunction && this.historyMalfunction.length > step) {
          this.malfunctionData = this.historyMalfunction[step].data;
          this.operationSlider = this.historyMalfunction.length;
          let Num = this.historyMalfunctionNum[step];
          this.malfunctionObj.zone = Num.abnormalStatistics.data[0].num;
          this.malfunctionObj.outside = Num.abnormalStatistics.data[1].num;
          this.malfunctionObj.noSignal = Num.abnormalStatistics.data[2].num;
          this.malfunctionObj.powerAbnormality = Num.abnormalStatistics.data[3].num;
          this.malfunctionObj.prohibit = Num.abnormalStatistics.data[4].num;
          this.malfunctionObj.fault = Num.abnormalStatistics.data[5].num;
          this.globalTime = this.historyMalfunction[step].dateTime;
          this.malfunctionClick(this.malfunctionJudge);
        } else {
          // alert("已无数据，请重新查询！");
        }
      }
      // 亏电
      if (this.feedJudge) {
        if (this.historyFeed && this.historyFeed.length > step) {
          this.feedData = this.historyFeed[step].data.data;
          this.operationSlider = this.historyFeed.length;
          this.globalTime = this.historyFeed[step].dateTime;
          this.feedClick(this.feedJudge);
        } else {
          // alert("已无数据，请重新查询！");
        }
      }

      // 运维
      if (this.operationJudge) {
        if (this.operationHistoryData && this.historyRoadOnly.length > step) {
          const self = this;
          this.globalTime = this.historyTime[step];
          console.log(this.globalTime, "shijian");
          this.chartTime = this.globalTime.split(" ")[1].split(":")[0] + ":00";
          this.getLineChart();
          console.log(this.historyRoadOnly.length, "11111");

          this.personLayer.remove(this.historyMarker);
          this.map.remove(this.historyPersonLayer);
          this.histroyMarker = {};

          let icon = {
            image: this.aLiYun + "operation_point.png",
            type: "image",
            size: [36, 50],
          };

          this.historyMarker = new AMap.LabelMarker({
            position: [
              this.historyRoadOnly[step][0],
              this.historyRoadOnly[step][1],
            ],
            zIndex: 16,
            icon,
          });

          //添加图层
          this.historyPersonLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.personLayer.add(this.historyMarker);
          this.map.add(this.historyPersonLayer);

          //绘制运维人员轨迹
          if (this.polylineHistory != undefined) {
            this.map.remove(this.polylineHistory);
          }

          let route = this.historyRoadOnly;
          this.stepHistory = route.slice(0, step + 1);
          console.log(this.stepHistory, step, 'sliceslice');
          this.polylineHistory = new AMap.Polyline({
            map: this.map,
            path: this.stepHistory,
            showDir: true,
            strokeColor: "#FFF",
            strokeWeight: 6,
            lineJoin: "round",
          });
        } else {
          // alert("已无数据，请重新查询！");
        }
      }
    },

    // 运维人员轨迹筛选（去除position）
    selectEvent() {
      this.closeEventBtn = !this.closeEventBtn;
      if (this.closeEventBtn) {
        let personEvent = this.personEvent.filter((x) => x.flag != "position");
        this.operationObj.event = personEvent;
        console.log(personEvent, "eventevent");
      } else {
        this.operationObj.event = this.personEvent;
      }
    },

    //亏电总数据模块点击事件
    freeNum(value) {
      this.beginFree = value;
      this.$refs.child.slider(value, "fixed");
    },

    //骑行
    ridingClick(judge) {
      this.ridingJudge = judge;
      if (judge) {
        console.log("骑行函数");
        // let url = "ws://192.168.5.42:8989/realTime";
        // this.wsRequest(url, 2).then((res) => {
        //长链接请求
        // console.log(res, "22222222222222");
        // this.ridingData = res.data;
        let self = this;
        let freeNum = [];
        let feedMarkers = []; //骑行中
        let ridingMarkers = []; //闲置中
        this.moduleChange = "this.riding";
        const ref = this.$refs.ridingData;
        this.riding_total = true;
        let data = this.ridingData;
        let feedArr = []; //关联点标记和点标注
        let ridingArr = [];
        for (let i = 0; i < feedMarkers.length; i++) {
          this.ridingLayer.remove(feedMarkers[i]);
        }
        for (let i = 0; i < ridingMarkers.length; i++) {
          this.ridingLayerf.remove(ridingMarkers[i]);
        }
        this.map.remove(feedMarkers);
        this.map.remove(ridingMarkers);
        this.map.remove(this.ridingLayer);
        this.map.remove(this.ridingLayerf);
        feedMarkers = [];
        freeNum = [];
        if (this.ridingData.length > 0) {
          data.forEach((item) => {
            let sizeX = this.zoom < 15 ? Number(this.zoom) * 2 : 36;
            let sizeY = this.zoom < 15 ? Number(this.zoom) * 2 : 50;
            let icon = {
              image: this.aLiYun + "freeStatus.png",
              type: "image",
              size: new AMap.Size(sizeX, sizeY),
              // size: [36, 50],
            };

            if (item.phone) {
              icon.image = this.aLiYun + "ridingStatus.png";
              let ridingMarker = new AMap.LabelMarker({
                name: item.userCode,
                position: [item.lonC, item.latC],
                zIndex: 16,
                icon,
              });

              feedMarkers.push(ridingMarker); //点标记数组
              feedArr.push(
                Object.assign(item, {
                  mapId: ridingMarker._amap_id,
                })
              );
              ridingMarker.on("click", function (e) {
                self.markerClick(feedArr, ridingMarker, ref, [
                  item.lonC,
                  item.latC,
                ]);
              });
            } else {
              freeNum.push(item);
              let ridingMarker = new AMap.LabelMarker({
                name: item.userCode,
                position: [item.lonC, item.latC],
                zIndex: 16,
                icon: icon,
              });

              ridingMarkers.push(ridingMarker); //点标记数组
              ridingArr.push(
                Object.assign(item, {
                  mapId: ridingMarker._amap_id,
                })
              );
              ridingMarker.on("click", function (e) {
                self.markerClick(ridingArr, ridingMarker, ref, [
                  item.lonC,
                  item.latC,
                ]);
              });
            }
          });

          this.allCars = data.length;
          this.freeCars = freeNum.length;
          this.ridingCars = this.allCars - this.freeCars;

          //添加图层
          this.ridingLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.ridingLayerf = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.ridingLayer.add(feedMarkers);
          this.ridingLayerf.add(ridingMarkers);
          this.map.add(this.ridingLayer);
          this.map.add(this.ridingLayerf);
        } else {
          alert("未请求到数据");
        }
        // });
      } else {
        if (this.ridingLayer.length != 0) {
          this.ridingLayer.hide();
          this.ridingLayerf.hide();
        }
        this.riding = false;
        this.riding_total = false;
      }
    },

    // 隐藏未骑行车辆
    partCars() {
      this.ridingLayerf.hide();
    },

    // 闲置类型点击事件
    typeClick(e, type) {
      console.log(type, "闲置类型");
      if (this.noUsecircle) {
        this.map.remove(this.noUsecircle);
      }
      this.freeClick(this.freeJudge, type);
      this.noUseList = [];
      if (type.type === "noOrder") {
        if (type.range[0] == 0) {
          this.noUseList = this.noOrderList24;
          this.noUseList = this.listSortvar(this.noUseList, "noOrderHour");//按时间排序
        } else if (type.range[0] == 48) {
          this.noUseList = this.noOrderList48;
          this.noUseList = this.listSortvar(this.noUseList, "noOrderHour");
        } else {
          this.noUseList = this.noOrderList72;
          this.noUseList = this.listSortvar(this.noUseList, "noOrderHour");
        }
      } else {
        if (type.range[0] == 0) {
          this.noUseList = this.noUseList24;
          this.noUseList = this.listSortvar(this.noUseList, "noUseHour");
        } else if (type.range[0] == 48) {
          this.noUseList = this.noUseList48;
          this.noUseList = this.listSortvar(this.noUseList, "noUseHour");
        } else {
          this.noUseList = this.noUseList72;
          this.noUseList = this.listSortvar(this.noUseList, "noUseHour");
        }
      }
    },

    //闲置时长列表
    noUseCar(e, item) {
      if (this.noUsecircle) {
        this.map.remove(this.noUsecircle);
      }
      this.activeCarNum = item.userCode;
      let position = [item.lon, item.lat];
      this.map.setZoomAndCenter(20, position);
      this.noUsecircle = new AMap.Circle({
        center: new AMap.LngLat(item.lon, item.lat), // 圆心位置
        radius: 1, // 圆半径
        fillColor: "#fff", // 圆形填充颜色
        strokeColor: "#fff", // 描边颜色
        strokeWeight: 2, // 描边宽度
      });

      this.map.add(this.noUsecircle);
    },

    //闲置
    freeClick(judge, type) {
      this.freeJudge = judge;
      if (judge) {
        // this.wsRequest("ws://192.168.5.42:8989/realTime", 1).then((res) => {
        //长链接请求
        // this.freeData = res.data;
        this.moduleChange = "this.free";
        if (this.freeData) {
          const self = this;
          let ref = self.$refs.infoData;
          this.data_total = true; //总数据统计
          let arr24 = [];

          // 清除数据
          for (let i = 0; i < this.labelsMarker24.length; i++) {
            if (this.labelsLayer.length != 0) {
              this.labelsLayer.remove(this.labelsMarker24[i]);
            }
          }
          this.map.remove(this.labelsMarker24);
          this.map.remove(this.labelsLayer);
          this.labelsMarker24 = [];

          //获取闲置数据
          let data = this.freeData;
          let text_hour = [];
          let type24 = 0;
          let type48 = 0;
          let type72 = 0;
          let noOrder24 = 0;
          let noOrder48 = 0;
          let noOrder72 = 0;
          this.noUseList24 = [];
          this.noUseList48 = [];
          this.noUseList72 = [];
          this.noOrderList24 = [];
          this.noOrderList48 = [];
          this.noOrderList72 = [];
          data.forEach((item) => {
            let listObj = {
              lat: item.lat,
              lon: item.lon,
              noOrderHour: item.noOrderHour,
              noUseHour: item.noUseHour,
              userCode: item.userCode,
            };

            if (item.noUseHour >= 24 && item.noUseHour < 48) {
              this.noUseList24.push(listObj);
              type24++;
            } else if (item.noUseHour >= 48 && item.noUseHour < 72) {
              this.noUseList48.push(listObj);
              type48++;
            } else if (item.noUseHour >= 72) {
              this.noUseList72.push(listObj);
              type72++;
            }

            if (item.noOrderHour >= 24 && item.noOrderHour < 48) {
              this.noOrderList24.push(listObj);
              noOrder24++;
            } else if (item.noOrderHour >= 48 && item.noOrderHour < 72) {
              this.noOrderList48.push(listObj);
              noOrder48++;
            } else if (item.noOrderHour >= 72) {
              this.noOrderList72.push(listObj);
              noOrder72++;
            }
          });
          this.freeObj.noUse24 = type24;
          this.freeObj.noUse48 = type48;
          this.freeObj.noUse72 = type72;
          this.freeObj.noOrder24 = noOrder24;
          this.freeObj.noOrder48 = noOrder48;
          this.freeObj.noOrder72 = noOrder72;
          this.freeObj.noUseAll = type24 + type48 + type72;
          this.freeObj.noOrderAll = noOrder24 + noOrder48 + noOrder72;
          data = data.filter((x) => x.noOrderHour >= this.beginFree); //进度条筛选数据（根据无订单时间）

          data.forEach((item) => {
            // 点标记文字居中
            if (item.noOrderHour >= 0 && item.noOrderHour <= 99) {
              text_hour = [-30, -8];
            } else if (item.noOrderHour > 99 && item.noOrderHour <= 999) {
              text_hour = [-35, -8];
            } else if (item.noOrderHour > 999 && item.noOrderHour <= 9999) {
              text_hour = [-40, -8];
            }
            var icon = {
              type: "image",
              size: [36, 50],
            };

            var text = {
              content: item.noOrderHour,
              direction: "right",
              offset: text_hour,
              style: {
                fontSize: 14,
                fillColor: "#ddd",
                fontWeight: 900,
              },
            };

            // 点标记文字颜色
            if (item.noOrderHour >= 48 && item.noOrderHour < 72) {
              text.style.fillColor = "#ffa202";
            } else if (item.noOrderHour >= 72) {
              text.style.fillColor = "red";
            }

            // 点标记样式
            if (item.noUseHour >= 0 && item.noUseHour < 48) {
              icon.image = this.aLiYun + "0-24.png";
            } else if (item.noUseHour >= 48 && item.noUseHour < 72) {
              icon.image = this.aLiYun + "24-48.png";
            } else if (item.noUseHour >= 72) {
              icon.image = this.aLiYun + "48%E4%BB%A5%E4%B8%8A.png";
            }

            if (type) {//有type分类
              let range = type.range;
              if (type.type == "noOrder") {//type为无订单时
                if (
                  item.noOrderHour >= range[0] &&
                  item.noOrderHour < range[1]
                ) {
                  // let labelMarker24 = new AMap.LabelMarker({
                  //   name: item.userCode,
                  //   position: [item.lon, item.lat],
                  //   zIndex: 16,
                  //   icon,
                  //   text,
                  // });
                  // arr24.push(
                  //   Object.assign(item, {
                  //     mapId: labelMarker24._amap_id,
                  //   })
                  // );
                  // this.labelsMarker24.push(labelMarker24); //点标记数组
                  // labelMarker24.on("click", function (e) {
                  //   self.markerClick(arr24, labelMarker24, ref, [
                  //     item.lon,
                  //     item.lat,
                  //   ]);
                  // });
                  if (this.closeFailure) {//未开启屏蔽故障
                    let labelMarker24 = new AMap.LabelMarker({
                      name: item.userCode,
                      position: [item.lon, item.lat],
                      zIndex: 16,
                      icon,
                      text,
                    });

                    arr24.push(
                      Object.assign(item, {
                        mapId: labelMarker24._amap_id,
                      })
                    );
                    this.labelsMarker24.push(labelMarker24); //点标记数组
                    labelMarker24.on("click", function (e) {
                      self.markerClick(arr24, labelMarker24, ref, [
                        item.lon,
                        item.lat,
                      ]);
                    });
                  } else {//开启屏蔽故障
                    if (!item.isAbnormalFromAdmin) {//属性存在且值为false时是要渲染的数据
                      console.log("值为false的数据");
                      let labelMarker24 = new AMap.LabelMarker({
                        name: item.userCode,
                        position: [item.lon, item.lat],
                        zIndex: 16,
                        icon,
                        text,
                      });

                      arr24.push(
                        Object.assign(item, {
                          mapId: labelMarker24._amap_id,
                        })
                      );

                      this.labelsMarker24.push(labelMarker24); //点标记数组
                      labelMarker24.on("click", function (e) {
                        self.markerClick(arr24, labelMarker24, ref, [
                          item.lon,
                          item.lat,
                        ]);
                      });
                    }
                  }
                }



              } else if (type.type == "noUse") {//type为无使用时
                if (item.noUseHour >= range[0] && item.noUseHour < range[1]) {
                  // let labelMarker24 = new AMap.LabelMarker({
                  //   name: item.userCode,
                  //   position: [item.lon, item.lat],
                  //   zIndex: 16,
                  //   icon,
                  //   text,
                  // });

                  // arr24.push(
                  //   Object.assign(item, {
                  //     mapId: labelMarker24._amap_id,
                  //   })
                  // );
                  // this.labelsMarker24.push(labelMarker24); //点标记数组
                  // labelMarker24.on("click", function (e) {
                  //   self.markerClick(arr24, labelMarker24, ref, [
                  //     item.lon,
                  //     item.lat,
                  //   ]);
                  // });
                  if (this.closeFailure) {//未开启屏蔽故障
                    let labelMarker24 = new AMap.LabelMarker({
                      name: item.userCode,
                      position: [item.lon, item.lat],
                      zIndex: 16,
                      icon,
                      text,
                    });

                    arr24.push(
                      Object.assign(item, {
                        mapId: labelMarker24._amap_id,
                      })
                    );
                    this.labelsMarker24.push(labelMarker24); //点标记数组
                    labelMarker24.on("click", function (e) {
                      self.markerClick(arr24, labelMarker24, ref, [
                        item.lon,
                        item.lat,
                      ]);
                    });
                  } else {//开启屏蔽故障
                    if (!item.isAbnormalFromAdmin) {//属性存在且值为false时是要渲染的数据
                      console.log("值为false的数据");
                      let labelMarker24 = new AMap.LabelMarker({
                        name: item.userCode,
                        position: [item.lon, item.lat],
                        zIndex: 16,
                        icon,
                        text,
                      });

                      arr24.push(
                        Object.assign(item, {
                          mapId: labelMarker24._amap_id,
                        })
                      );

                      this.labelsMarker24.push(labelMarker24); //点标记数组
                      labelMarker24.on("click", function (e) {
                        self.markerClick(arr24, labelMarker24, ref, [
                          item.lon,
                          item.lat,
                        ]);
                      });
                    }
                  }
                }
              }
            } else {//没有type分类
              console.log("没有type分类");
              if (this.closeFailure) {//未开启屏蔽故障
                let labelMarker24 = new AMap.LabelMarker({
                  name: item.userCode,
                  position: [item.lon, item.lat],
                  zIndex: 16,
                  icon,
                  text,
                });

                arr24.push(
                  Object.assign(item, {
                    mapId: labelMarker24._amap_id,
                  })
                );
                this.labelsMarker24.push(labelMarker24); //点标记数组
                labelMarker24.on("click", function (e) {
                  self.markerClick(arr24, labelMarker24, ref, [
                    item.lon,
                    item.lat,
                  ]);
                });
              } else {//开启屏蔽故障
                if (!item.isAbnormalFromAdmin) {//属性存在且值为false时是要渲染的数据
                  console.log("值为false的数据");
                  let labelMarker24 = new AMap.LabelMarker({
                    name: item.userCode,
                    position: [item.lon, item.lat],
                    zIndex: 16,
                    icon,
                    text,
                  });

                  arr24.push(
                    Object.assign(item, {
                      mapId: labelMarker24._amap_id,
                    })
                  );

                  this.labelsMarker24.push(labelMarker24); //点标记数组
                  labelMarker24.on("click", function (e) {
                    self.markerClick(arr24, labelMarker24, ref, [
                      item.lon,
                      item.lat,
                    ]);
                  });
                }
              }

            }
          });

          //添加图层
          this.labelsLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.type24 = this.labelsMarker24.length; //24小时总数据体
          this.labelsLayer.add(this.labelsMarker24);
          this.map.add(this.labelsLayer);
        } else {
          alert("未请求到数据");
        }
        // });
      } else {
        if (this.labelsLayer.length != 0) {
          this.labelsLayer.hide();
        }
        if (this.noUsecircle) {
          this.map.remove(this.noUsecircle);
        }
        this.noUse = false;
        this.data_total = false;
      }
    },

    // 全部异常
    malCount() {
      console.log("malCount");
      this.malfunctionClick(this.malfunctionJudge);
    },

    //异常类别筛选
    malTypeClick(e, type) {
      console.log(e, type, "e type");
      this.malfunctionClick(this.malfunctionJudge, type.type);
    },

    //异常
    malfunctionClick(judge, type) {
      console.log(judge, "malfunctionClick");
      this.malfunctionJudge = judge;
      if (judge) {
        // this.wsRequest("ws://192.168.5.42:8989/realTime", 11).then((res) => {
        //长链接请求
        // console.log(res, "aaaaaaaaaaaaaa");
        // this.malfunctionData = res.data;
        this.moduleChange = "this.malfunction";
        if (this.malfunctionData) {
          let ref = this.$refs.malfunctionData;
          let self = this;
          this.fault_total = true;
          let data = [];
          let dataArr = [];
          console.log(this.malfunctionData, "异常模块的数据");
          data[0] = this.malfunctionData.Fault;
          data[1] = this.malfunctionData.outOfArea;
          data[2] = this.malfunctionData.BatteryException;
          data[3] = this.malfunctionData.NoSignal;
          data[4] = this.malfunctionData.OffSite;
          data[5] = this.malfunctionData.ProhibitArea;

          let faultsArr = [];
          for (let i = 0; i < this.faultsMarkers.length; i++) {
            this.malfunctionLayer.remove(this.faultsMarkers[i]);
          }
          this.map.remove(this.faultsMarkers);
          this.map.remove(this.malfunctionLayer);
          this.faultsMarkers = [];

          if (type == "故障") {
            data[0].forEach((x) => {
              let restorex = x;
              x = x.base;
              console.log(restorex, x, "故障");

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);
                let icon = {
                  type: "image",
                  size: [36, 50],
                };
                if (x.from) {
                  icon.image = this.aLiYun + "operationCar.png";
                } else {
                  icon.image = this.aLiYun + "userCar.png";
                }

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else if (type == "超区") {
            data[1].forEach((x) => {
              let restorex = x;
              x = x.base;

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  type: "image",
                  size: [36, 50],
                };
                if (x.borrow) {
                  icon.image = this.aLiYun + "zoneRiding.png";
                } else {
                  icon.image = this.aLiYun + "zoneFree.png";
                }
                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else if (type == "电池异常") {
            data[2].forEach((x) => {
              let restorex = x;
              x = x.base;

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "feed.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else if (type == "无信号") {
            data[3].forEach((x) => {
              let restorex = x
              x = x.base;

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "noSignal.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else if (type == "站外") {
            data[4].forEach((x) => {
              let restorex = x
              x = x.base;

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "outSite.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else if (type == "禁区停车") {
            data[5].forEach((x) => {
              let restorex = x
              x = x.base;

              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "prohibitArea.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          } else {
            data[0].forEach((x) => {
              console.log(x.base, "未分类数据0");
              let restorex = x;
              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);
                let icon = {
                  type: "image",
                  size: [36, 50],
                };
                if (x.from == "admin") {
                  icon.image = this.aLiYun + "operationCar.png";
                } else {
                  icon.image = this.aLiYun + "userCar.png";
                }
                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });

            data[1].forEach((x) => {
              console.log(x.base, "未分类数据");
              let restorex = x
              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  type: "image",
                  size: [36, 50],
                };
                if (x.borrow) {
                  icon.image = this.aLiYun + "zoneRiding.png";
                } else {
                  icon.image = this.aLiYun + "zoneFree.png";
                }
                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });

            data[2].forEach((x) => {
              console.log(x.base, "未分类数据");
              let restorex = x;
              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "feed.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });

            data[3].forEach((x) => {
              console.log(x.base, "未分类数据");
              let restorex = x;

              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "noSignal.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });

            data[4].forEach((x) => {
              console.log(x.base, "未分类数据xxxxxxxxxxxx");
              let restorex = x;
              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "outSite.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });

            data[5].forEach((x) => {
              console.log(x.base, "未分类数据xxxxxxxxxxx");
              let restorex = x
              x = x.base;
              if (dataArr.indexOf(x.userCode) == -1) {
                dataArr.push(x.userCode);

                let icon = {
                  image: this.aLiYun + "prohibitArea.png",
                  type: "image",
                  size: [36, 50],
                };

                let faultsMarker = new AMap.LabelMarker({
                  name: x.userCode,
                  position: [x.lonC, x.latC],
                  zIndex: 16,
                  icon,
                });

                this.faultsMarkers.push(faultsMarker); //点标记数组
                faultsArr.push(
                  Object.assign(x, {
                    mapId: faultsMarker._amap_id,
                    from: restorex.from,
                    creator: restorex.creator,
                    reason: restorex.reason,
                  })
                );
                faultsMarker.on("click", function (e) {
                  self.markerClick(faultsArr, faultsMarker, ref, [
                    x.lonC,
                    x.latC,
                  ]);
                });
              }
            });
          }
          this.malfunctionObj.all = dataArr.length;
          //添加图层
          this.malfunctionLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.malfunctionLayer.add(this.faultsMarkers);
          this.map.add(this.malfunctionLayer);
        } else {
          alert("未请求到数据");
        }
        // })
      } else {
        if (this.malfunctionLayer.length != 0) {
          this.malfunctionLayer.hide();
        }
        this.malfunction = false;
        this.fault_total = false;
      }
    },

    //亏电
    feedClick(judge) {
      console.log(judge, "feedClick");
      this.feedJudge = judge;
      if (judge) {
        // this.wsRequest("ws://192.168.5.42:8989/realTime", 6).then((res) => {
        //   //长链接请求
        //   console.log(res, "aaaaaaaaaaaaaa");
        //   this.feedData = res.data;
        this.moduleChange = "this.feed";
        if (this.feedData) {
          let ref = this.$refs.feedData;
          let self = this;
          let data = this.feedData;
          this.feed_total = true;
          let feedMarkers = [];
          let feedArr = [];

          for (let i = 0; i < feedMarkers.length; i++) {
            this.feedLayer.remove(feedMarkers[i]);
          }
          this.map.remove(feedMarkers);
          this.map.remove(this.feedLayer);
          feedMarkers = [];

          data = data.filter((x) => x.soc <= 100 - this.beginFree); //进度条筛选数据
          this.allFeed = data.length;
          let redArr = [];
          let orangeArr = [];
          console.log(data.length, "nopower==============");

          data.forEach((item) => {
            let sizeX = this.zoom < 15 ? Number(this.zoom) * 2 : 36;
            let sizeY = this.zoom < 15 ? Number(this.zoom) * 2 : 50;
            let icon = {
              image: this.aLiYun + "greenFeed.png",
              type: "image",
              size: new AMap.Size(sizeX, sizeY),
              // size: [36, 50],
            };
            if (item.soc >= 15 && item.soc < 40) {
              orangeArr.push(item);
              icon.image = this.aLiYun + "orangeFeed.png";
            }
            if (item.soc < 15) {
              redArr.push(item);
              icon.image = this.aLiYun + "redFeed.png";
            }
            let text_hour = [];
            if (item.soc >= 0 && item.soc <= 9) {
              text_hour = this.zoom < 15 ? [-25, -5] : [-25, -8];
            } else if (item.soc > 9) {
              text_hour = this.zoom < 15 ? [-25, -5] : [-30, -8];
            }
            var text = {
              content: item.soc,
              direction: "right",
              offset: text_hour,
              style: {
                fontSize: this.zoom < 15 ? 12 : 14,
                fillColor: "#ddd",
                strokeColor: "transparent",
                fontWeight: 900,
              },
            };
            let feedMarker = new AMap.LabelMarker({
              name: item.userCode,
              position: [item.lonC, item.latC],
              zIndex: 16,
              icon,
              text,
            });
            feedMarkers.push(feedMarker); //点标记数组
            feedArr.push(
              Object.assign(item, {
                mapId: feedMarker._amap_id,
              })
            );
            feedMarker.on("click", function (e) {
              self.markerClick(feedArr, feedMarker, ref, [
                item.lonC,
                item.latC,
              ]);
            });
          });
          this.feed15 = redArr.length;
          this.feed40 = orangeArr.length;
          //添加图层
          this.feedLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.feedLayer.add(feedMarkers);
          this.map.add(this.feedLayer);
        } else {
          alert("未请求到数据");
        }
        // })
      } else {
        if (this.feedLayer.length != 0) {
          this.feedLayer.hide();
        }
        this.feed = false;
        this.feed_total = false;
      }
    },

    //站点
    parkClick(judge) {
      if (!judge) {
        for (let i = 0; i < this.parkPolyline.length; i++) {
          this.map.remove(this.parkPolyline[i]);
        }
        this.parkPolyline = [];
      }
      if (this.parkData && this.prohibitData) {
        console.log("app-站点");
        // 站点定时器
        this.parkJudge = judge;
        const self = this;
        const ref = self.$refs.parkData; //站点绑定的窗体
        let polygonArr = [];
        //可停区站点
        console.log(this.parkData, "站点信息>>>>>>>");
        this.parkData.forEach((item) => {
          console.log(item, "站点信息1111111111111")
          var parkArr = item.parkPoint.points.split(";");
          for (let i = 0; i < parkArr.length; i++) {
            parkArr[i] = parkArr[i].split(",");
          }
          var path = [];
          // 多边形轮廓线的节点坐标数组
          for (let i = 0; i < parkArr.length; i++) {
            path.push(new AMap.LngLat(parkArr[i][0], parkArr[i][1]));
          }

          //绘制可停区站点
          var polygon = new AMap.Polygon({
            path: path,
            fillColor: "transparent", // 多边形填充颜色
            borderWeight: 2, // 线条宽度，默认为 1
            strokeColor: "#0AD3DD", // 线条颜色
          });

          //将可停区站点添加到地图
          this.parkLayer.push(polygon);
          this.map.add(polygon);

          polygonArr.push(
            Object.assign(item, {
              mapId: polygon._amap_id,
              parkPointId:item.parkPoint.parkPointId,
              name:item.parkPoint.name,
            })
          );

          polygon.on("click", function (e) {
            //点击事件
            self.markerClick(polygonArr, polygon, ref, [item.parkPoint.lonC, item.parkPoint.latC]);
            console.log([item.parkPoint.lonC, item.parkPoint.latC], "站点坐标");
          });
        });

        // 禁停区
        this.prohibitData.forEach((item) => {
          var parkArr = item.pointsC.split(";");
          if (parkArr.length <= 1) {
            //圆
            parkArr = parkArr[0].split(",");
            var circle = new AMap.Circle({
              center: new AMap.LngLat(parkArr[0], parkArr[1]), // 圆心位置
              radius: item.allowRange, // 圆半径
              fillColor: "transparent", // 圆形填充颜色
              strokeColor: "red", // 描边颜色
              strokeWeight: 2, // 描边宽度
            });
            this.parkLayer.push(circle);
            this.map.add(circle);
          } else {
            //多边形
            for (let i = 0; i < parkArr.length; i++) {
              parkArr[i] = parkArr[i].split(",");
            }
            var path = [];
            for (let i = 0; i < parkArr.length; i++) {
              let items = parkArr[i];
              for (let j = 0; j < items.length; j++) {
                path.push(new AMap.LngLat(items[0], items[1]));
              }
            }

            //站点绘制
            var polygon = new AMap.Polygon({
              path: path,
              fillColor: "transparent", // 多边形填充颜色
              borderWeight: 2, // 线条宽度，默认为 1
              strokeColor: "red", // 线条颜色
            });
            this.parkLayer.push(polygon);
            this.map.add(polygon);
          }
        });

        if (!judge) {
          this.map.remove(this.parkLayer);
          this.parkPoint = false;
        }
      } else {
        alert("未请求到数据");
      }
      // 站点关系显示
      // if (this.relationData) {
      //   console.log(this.relationData, "zhandiannei");
      //   let data = this.relationData;
      //   data.forEach((x) => {
      //     // console.log(x, "xxxxxxxxx");
      //     if (x != undefined) {
      //       let ownSite = [x.loC, x.laC];
      //       let otherSite = x.data;
      //       console.log(ownSite, otherSite);
      //       otherSite.forEach((x) => {
      //         let position = [x.loC, x.laC];
      //         this.parkPolyline.push(
      //           new AMap.Polyline({
      //             map: this.map,
      //             path: [ownSite, position],
      //             showDir: true,
      //             strokeColor: "yellow",
      //             strokeWeight: x.value,
      //             lineJoin: "round",
      //           })
      //         );
      //       });
      //     }
      //   });
      // }
    },

    //运维
    operationClick(judge) {
      console.log(judge, "operationClick");
      this.operationJudge = judge;
      if (judge) {
        this.moduleChange = "this.operation";
        if (this.operationData) {
          let self = this;
          this.operation_total = true;
          this.operationChart = this.chartData ? false : true;
          let ref = this.$refs.operationData;
          let data = this.operationData; //maintenanceWorker数据
          if (this.personLayer.length != 0) {
            this.personLayer.hide();
            this.map.remove(this.polyline);
            this.personnelList = false;
            this.operationObj.event = [];
          }

          let operationMarkers = [];
          let operationArr = [];

          for (let i = 0; i < operationMarkers.length; i++) {
            this.operationLayer.remove(operationMarkers[i]);
          }
          this.map.remove(operationMarkers);
          this.map.remove(this.operationLayer);
          operationMarkers = [];

          let personName = [];
          personName.push("全部运维人员");
          this.operationObj.all = data.length; //全部运维人员
          let operationWorks = 0;
          data.forEach((item) => {
            personName.push(item.name);
            if (item.state == "work") {
              operationWorks++;

              let icon = {
                image: this.aLiYun + "operation_point.png",
                type: "image",
                size: [36, 50],
              };
              let text = {
                content: item.name,
                direction: "right",
                offset: [-40, -40],
                style: {
                  fontSize: 14,
                  fillColor: "#ddd",
                  strokeColor: "transparent",
                  fontWeight: 900,
                },
              };

              let operationMarker = new AMap.LabelMarker({
                name: item.userCode,
                position: [item.lon, item.lat],
                zIndex: 16,
                icon,
                text,
              });
              operationMarkers.push(operationMarker);
              operationArr.push(
                Object.assign(item, {
                  mapId: operationMarker._amap_id,
                })
              );
              operationMarker.on("click", function (e) {
                self.markerClick(operationArr, operationMarker, ref, [
                  item.lon,
                  item.lat,
                ]);
              });
            }
          });
          this.operationObj.name = personName;
          this.operationObj.work = operationWorks;

          //添加图层
          this.operationLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.operationLayer.add(operationMarkers);
          this.map.add(this.operationLayer);
        } else {
          alert("未请求到数据");
        }
      } else {
        if (this.operationLayer.length != 0) {
          this.operationLayer.hide();
        }
        if (this.personLayer.length != 0) {
          this.personLayer.hide();
        }
        if (this.polylineHistory != undefined) {
          this.map.remove(this.polylineHistory);
        }
        this.map.remove(this.polyline);
        this.operation_total = false;
        this.operationChart = false;
        this.chartData = false;
        this.operation = false;
        this.workEvent = false;
        this.personnelList = false;
      }
    },

    // 控制运维人员列表显示
    personClick() {
      this.operation_list = !this.operation_list;
    },

    //关闭事件列表
    closeList() {
      console.log("关闭列表");
      this.personnelList = false;
    },

    // 选择运维人员（显示其轨迹）
    personChange(i, item) {
      this.$refs.timeSelect.initData();
      this.activeNum = i;
      this.operationObj.event = [];
      this.workEvent = false;
      this.operation = false;
      this.parkPoint = false;
      this.closeEventBtn = false;
      this.map.remove(this.personLayer); //消除路径
      const ref = this.$refs.workData;
      const self = this;
      this.map.remove(this.polyline);
      this.operationObj.selected = item;
      this.historyRoad = [];
      let data = this.operationHistoryData;
      console.log(data, "this.operationHistoryData长连接运维轨迹数据");

      //清楚运维人员轨迹
      if (this.polylineHistory) {
        this.map.remove(this.polylineHistory);
      }

      if (data) {
        if (item == "全部运维人员") {
          this.personnelList = false; //关闭运维事件列表
          this.operationClick(this.operationJudge); //重绘运维人员点标记
        }

        let person = data.filter((x) => {
          //筛选相应运维人员
          return x.name == item;
        });

        //运维人员轨迹渲染
        if (person.length) {//检查该运维人员是否存在     
          this.operationLayer.hide(); //隐藏运维人员点标记
          this.personnelList = true; //打开运维事件列表
          person = person[0].data;
          console.log(person, "personperson");
          this.operationSlider = person.length; //时间进度条划分长度
          this.operationObj.event = person; //运维事件列表数据
          this.personEvent = person;
          let personMarkers = []; //创建运维事件点标记
          let personArr = [];

          for (let i = 0; i < personMarkers.length; i++) {
            this.personLayer.remove(personMarkers[i]);
          }
          this.map.remove(personMarkers);
          this.map.remove(this.personLayer);
          personMarkers = [];
          this.historyRoadOnly = [];
          this.historyRoad = [];

          person.forEach((e) => {
            this.historyRoad.push([e.lonC, e.latC]);
            this.historyRoadOnly.push([e.lonC, e.latC]);
            this.historyTime.push(e.dateTime);

            let icon = {
              type: "image",
              // size: [40, 40],
              size: [30, 30],
            };

            //点标记图标匹配
            if (e.typeStr == "换电") {
              let details = e.details;
              icon.image = details.isValid
                ? this.aLiYun + "changePower.png"
                : this.aLiYun + "invalidChangePower.png";
            } else if (e.typeStr == "挪车") {
              let details = e.details;
              icon.image = details.isValid
                ? this.aLiYun + "moveCar.png"
                : this.aLiYun + "invalidMoveCar.png";
            } else if (e.typeStr.indexOf("调度") >= 0) {
              let details = e.details;
              if (e.typeStr == "调度(入)") {
                icon.image = details.isValid
                  ? this.aLiYun + "dispatchIn.png"
                  : this.aLiYun + "invalidDispatchIn.png";
              } else if (e.typeStr == "调度(出)") {
                icon.image = details.isValid
                  ? this.aLiYun + "dispatchOut.png"
                  : this.aLiYun + "invalidDispatchOut.png";
              }
            } else if (e.typeStr == "打卡" || e.typeStr == "下班卡") {
              icon.image = this.aLiYun + "signin.png";
            }
            // let eTime = e.dt.substr(5, 11) + " "; //图标上悬浮时间
            // let text = {
            //   content: eTime,
            //   direction: "right",
            //   offset: [-30, -30],
            //   style: {
            //     fontSize: 14,
            //     fillColor: "#ddd",
            //     backgroundColor: "rgba(0,0,0,0.9)",
            //     opcity: 0.5,
            //     fontWeight: 900,
            //   },
            // };

            let personMarker = new AMap.LabelMarker({
              name: e.userCode,
              position: [e.lonC, e.latC],
              zIndex: 16,
              icon,
              // text,
            });
            personMarkers.push(personMarker);
            personArr.push(
              Object.assign(e, {
                mapId: personMarker._amap_id,
              })
            );

            //点标记点击事件
            personMarker.on("click", function (event) {
              self.markerClick(personArr, personMarker, ref, [e.lonC, e.latC]);
            });
          });

          //添加图层
          this.personLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false,
            allowCollision: false,
          });
          this.personLayer.add(personMarkers);
          this.map.add(this.personLayer);


          //绘制运维人员轨迹
          this.polyline = new AMap.Polyline({
            map: this.map,
            path: this.historyRoad,
            showDir: true,
            strokeColor: "#28F",
            strokeWeight: 6,
            lineJoin: "round",
          });
        } else {
          alert('无运维轨迹数据')
        }
      }
    },

    //定位事件图标
    positioningEvent(i, item) {
      this.EventNum = i;
      let position = [item.lonC, item.latC];

      this.personLayer.remove(this.positionMarker);
      this.map.remove(this.historyPositionLayer);
      this.positionMarker = {};

      let icon = {
        image: this.aLiYun + "operationPosition.png",
        type: "image",
        size: [36, 50],
      };
      this.positionMarker = new AMap.LabelMarker({
        position,
        zIndex: 16,
        icon,
      });

      //添加图层
      this.historyPositionLayer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: false,
        allowCollision: false,
      });
      this.personLayer.add(this.positionMarker);
      this.map.add(this.historyPositionLayer);
      this.map.setZoomAndCenter(15, position);
    },

    //点标记点击事件
    markerClick(arr, marker, ref, point) {
      console.log(arr, marker, ref, point, "点击事件数据");
      let arrNew = arr.filter((x) => x.mapId == marker._amap_id);
      this.devInfo = arrNew && arrNew[0]; //所点击点标记的数据
      console.log(this.devInfo, "骑行数据");

      //点击显示站点关系
      // if (ref == this.$refs.parkData) {
      //   console.log(this.parkPolyline, "xiantiao");
      //   for (let i = 0; i < this.parkPolyline.length; i++) {
      //     this.map.remove(this.parkPolyline[i]);
      //   }
      //   this.parkPolyline = [];

      //   console.log(this.devInfo.parkPointId, "zhandianguanxi");
      //   let relationData = this.relationData.filter(
      //     (x) => x.id == this.devInfo.parkPointId
      //   )[0];
      //   console.log(relationData, "relationData");
      //   if (relationData != undefined) {
      //     let ownSite = [relationData.loC, relationData.laC];
      //     let otherSite = relationData.data;
      //     console.log(ownSite, otherSite, "othersite");
      //     otherSite.forEach((x) => {
      //       console.log(x, "othersite");
      //       let position = [x.loC, x.laC];
      //       this.parkPolyline.push(
      //         new AMap.Polyline({
      //           map: this.map,
      //           path: [ownSite, position],
      //           showDir: true,
      //           strokeColor: "yellow",
      //           strokeWeight: x.value * 2,
      //           lineJoin: "round",
      //         })
      //       );
      //     });
      //   }
      // }

      //运维
      if (this.devInfo.typeStr) {
        switch (this.devInfo.typeStr) {
          case "换电": {
            this.operation_imgs = this.aLiYun + "changePower.png";
            let details = this.devInfo.details[0];
            this.operationObj.time = details.powerChangeTime;
            this.operationObj.userCode = "车辆编号：" + details.userCode;
            this.operationObj.changeBefore = "换前电量：" + details.powerOldSoc;
            this.operationObj.changeAfter = "换后电量：" + details.powerNewSoc;
            if (details.isValid) {
              this.operationObj.type = "有效换电";
            } else {
              this.operationObj.type = "无效换电";
              this.operation_imgs = this.aLiYun + "invalidChangePower.png";
            }
            break;
          }
          case "挪车":
            let details = this.devInfo.details[0];
            console.log(details, "挪车details");
            this.operationObj.time = details.moveStartTime;
            this.operationObj.userCode = "车辆编号：" + details.userCode;
            this.operationObj.changeBefore = "";
            this.operationObj.changeAfter = "";
            if (details.isValid) {
              this.operationObj.type = "有效挪车";
              this.operation_imgs = this.aLiYun + "moveCar.png";
            } else {
              this.operationObj.type = "无效挪车";
              this.operation_imgs = this.aLiYun + "invalidMoveCar.png";
            }
            break;
          case "打卡": {
            this.operation_imgs = this.aLiYun + "signin.png";
            this.operationObj.time = this.devInfo.dateTime;
            this.operationObj.changeBefore = "";
            this.operationObj.changeAfter = "";
            this.operationObj.userCode = "";
            this.operationObj.type = "";
            break;
          }
          case "下班卡": {
            this.operation_imgs = this.aLiYun + "signin.png";
            this.operationObj.time = this.devInfo.dateTime;
            this.operationObj.changeBefore = "";
            this.operationObj.changeAfter = "";
            this.operationObj.userCode = "";
            this.operationObj.type = "";
            break;
          }
          case "调度(入)": {
            this.operation_imgs = this.aLiYun + "dispatchIn.png";
            let details = this.devInfo.details[0];
            console.log(details, "调度入");
            this.operationObj.time = details.dispatchFinishTime;
            this.operationObj.userCode = "";
            this.operationObj.changeBefore = "出站点：" + details.dispatchOldParkPoint;
            this.operationObj.changeAfter = "入站点：" + details.dispatchNewParkPoint;
            if (details.isValid) {
              this.operationObj.type = "有效调度";
            } else {
              this.operationObj.type = "无效调度";
              this.operation_imgs = this.aLiYun + "invalidDispatchIn.png";
            }
            break;
          }
          case "调度(出)": {
            this.operation_imgs = this.aLiYun + "dispatchOut.png";
            let details = this.devInfo.details[0];
            console.log(details, "调度出");
            this.operationObj.time = details.dispatchDispatchTime;
            this.operationObj.userCode = "车辆编号：" + details.userCode;
            this.operationObj.changeBefore = "出站点：" + details.dispatchOldParkPoint;
            this.operationObj.changeAfter = "入站点：" + details.dispatchNewParkPoint;
            if (details.isValid) {
              this.operationObj.type = "有效调度";
            } else {
              this.operationObj.type = "无效调度";
              this.operation_imgs = this.aLiYun + "invalidDispatchOut.png";
            }
            break;
          }
        }
      }

      //站点进出数据
      if (this.devInfo.returnNum) {
        console.log("xxxxxxxxxxxxxx");
        this.returnNums = this.devInfo.returnNum;
        this.lendNums = this.devInfo.lendNum;
      }
      let infoWindow = this.createInfoWindow(ref);
      this.openInfoWindow(infoWindow, point, ref);
    },

    //创建窗体
    createInfoWindow(ref) {
      console.log("createInfoWindow");
      let infoWindowData = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: ref, //窗体内容
        offset: new AMap.Pixel(200, -15),
      });
      return infoWindowData;
    },

    //打开窗体
    openInfoWindow(infoWindow, point, ref) {
      console.log("openInfoWindow", ref);
      switch (ref) {
        case this.$refs.infoData:
          this.noUse = true;
          break;
        case this.$refs.parkData:
          this.parkPoint = true;
          break;
        case this.$refs.ridingData:
          this.riding = true;
          break;
        case this.$refs.feedData:
          this.feed = true;
          break;
        case this.$refs.malfunctionData:
          this.malfunction = true;
          break;
        case this.$refs.operationData:
          this.operation = true;
          break;
        case this.$refs.workData:
          this.workEvent = true;
          break;
      }
      infoWindow.open(this.map, point);
      // infoWindow.open(this.map, marker.getPosition());
    },

    // 关闭窗体
    closeInfoWindow() {
      console.log("closeInfoWindow");
      this.map.clearInfoWindow();
    },

    // 排序
    listSortvar(list, type) {
      let id = type;
      let arr = [];
      // 将需要排序的 key, 按照 "从小到大" 进行排列
      let sortKeys = Object.keys(list).sort((a, b) => {
        console.log(a);
        return list[b][id] - list[a][id];
      });
      console.log(sortKeys);

      // 循环排列好的 key, 重新组成一个新的数组
      for (var sortIndex in sortKeys) {
        arr.push(list[sortKeys[sortIndex]]);
      }
      console.log(arr);
      return arr;
    },
  },
};
</script>
