<template>
  <!-- 右上角天气 -->
  <div class="box" :class="[date ? 'close_Color' : 'open_Color']">
    <p class="boxTemperature">
      <img src="../assets/images/grayLeft.png" alt="" class="bracketLeft" />
      {{ weatcherData.temp }}° {{ weatcherData.text }} 杭州市
      <img src="../assets/images/grayRight.png" alt="" class="bracketRight" />
    </p>
  </div>
</template>

<script>
// import axios from "axios";
import { com } from "../../proto";
export default {
  data() {
    return {
      weatcherData: {},
      base: "ws://124.70.216.117:8989/",
      // base:"ws://192.168.5.42:8989/", 
      wsUrl:"ws://124.70.216.117:8990/",
    };
  },
  props: ['date'],
  created() {
    this.token = window.location.href.split("=")[1].split("#")[0];
  },
  mounted() {
    this.getWeather();
    this.timer = setInterval(() => {
      this.getWeather();
    }, 60000); //每分钟调用一次

  },
  methods: {
    getWeather() {
      // 天气api接口
      // let url = this.base+"weather/getWeather?token="+this.token
      //   axios
      //     .get(url)
      //     .then((res) => {
      //       if (res.data) {
      //         console.log(res.data,"res.data",this.token);
      //         this.weatcherData = res.data;
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err,"weather");
      //     });
      let that = this;
      let weatherPromise = new Promise(function (resolve, rej) {
        let test = com.bxkz.OuterClass.Request.create(); //参数
        test.service = 0;
        let uint8Array = com.bxkz.OuterClass.Request.encode(test).finish();

        let s = new WebSocket(that.wsUrl+"realTime", that.token); //建立连接

        s.addEventListener("open", function () {
          // 发送参数，解码，关闭连接
          s.send(uint8Array);
        });

        s.addEventListener("message", function (a) {
          //接收数据
          a.data.arrayBuffer().then((res) => {
            const bytes = new Uint8Array(res);
            const decodeObj = com.bxkz.OuterClass.Resp.decode(bytes);
            let type = decodeObj.data.type_url.split(".")[5];
            let weather = com.bxkz.OuterClass[type].decode(
              decodeObj.data.value
            );
            resolve(weather);
          });
        });
      });

      weatherPromise.then(res => {
        this.weatcherData = res;
      }
      )
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  /* color: #fff; */
  margin-right: 20px;
}

.boxTemperature {
  font-size: 24px;
  font-weight: 700;
}

.open_Color {
  color: #fff;
}

.close_Color {
  color: #0B484D;
}

.bracketLeft {
  width: 5px;
  height: 36px;
  margin-right: 18px;
  vertical-align: middle;
}

.bracketRight {
  width: 5px;
  height: 36px;
  margin-left: 18px;
  vertical-align: middle;
}
</style>
