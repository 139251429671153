<template>
  <div class="hello">
    <div class="timesTop">
      <!-- <div class="bigDot">
        <div class="smallDot"></div>
      </div> -->
      <img
        src="@/assets/images/left3x.png"
        alt=""
        class="dot"
        v-on:click="progress_minus"
        style="cursor: pointer"
      />
      <!-- 滑块 -->
      <div class="slider">
        <div class="block">
          <el-slider
            v-model="value3"
            :show-tooltip="true"
            style="width: 596px"
            :min="0"
            :max="per || msg"
            :show-stops="true"
            @change="slider(value3)"
          ></el-slider>
        </div>
      </div>
      <img
        src="@/assets/images/right3x.png"
        alt=""
        class="dot"
        v-on:click="progress_add"
        style="cursor: pointer"
      />
      <div class="quart" @click="sliderTimer">
        <img
          src="../assets/images/off.png"
          alt=""
          style="width: 10px; height: 10px"
          v-show="queryBtn && enable && !sliderJudge"
        />
        <img
          src="../assets/images/on.png"
          alt=""
          style="width: 10px; height: 10px"
          v-show="queryBtn && enable && sliderJudge"
        />
      </div>
      <div class="quart" v-show="!enable"></div>
    </div>
    <div class="timesBottom">
      <div class="block">
        <el-date-picker
          v-model="value_time"
          type="datetimerange"
          range-separator="—"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <button class="queryBtn">
        <span style="display: inline-block; margin-right: 3px"
          ><img
            src="../assets/images/gap.png"
            alt=""
            style="
              width: 22px;
              height: 18px;
              margin-top: -4px;
              vertical-align: middle;
            "
          />
        </span>
        <span>间隔</span>
      </button>
      <!-- 时间间隔小时 -->
      <div style="width: 80px; height: 22px; margin-bottom: 2px">
        <el-select v-model="valueHour" placeholder="0h">
          <el-option
            v-for="item in optionHour"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 时间间隔分钟 -->
      <div style="width: 80px">
        <el-select v-model="valueMin" placeholder="0min">
          <el-option
            v-for="item in optionMin"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <button class="queryBtn" v-on:click="queryTime">查询</button>
    </div>
    <div @click="speedFun" class="fastForward">
      x{{ speed }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["msg", "judge", "enable"],
  data() {
    return {
      //滑块
      per: 0,
      value3: 0,
      speed: 1,
      speedArr: [1, 2, 4, 8],
      speedTimer: 0,
      // 时间
      value_time: [
        new Date().toLocaleString().substr(0, 10) + " 00:00:00",
        new Date().toLocaleString().substr(0, 10) + " 23:59:59",
      ],
      //选择器
      optionsHour: [],
      optionsMin: [],
      // 下拉框绑定数据
      valueHour: "",
      valueMin: "",
      time: [],
      sliderJudge: false, //播放控件
      queryBtn: false,
    };
  },
  mounted() {},
  computed: {
    optionHour() {
      for (let i = 0; i <= 72; i++) {
        if (i == 0) {
          this.optionsHour.push({
            value: i,
            label: "0h",
          });
        } else {
          this.optionsHour.push({
            value: i,
            label: i + "h",
          });
        }
      }
      return this.optionsHour;
    },
    optionMin() {
      this.optionsMin.push({
        value: 0,
        label: 0 + "min",
      });
      for (let i = 30; i < 60; i++) {
        this.optionsMin.push({
          value: i,
          label: i + "min",
        });
      }
      return this.optionsMin;
    },
  },
  methods: {
    // 清除数据
    initData() {
      console.log("清除数据");
      this.per = 0;
      this.value3 = 0;
      this.speed = 1;
      this.speedTimer = 0;
      this.value_time = [
        new Date().toLocaleString().substr(0, 10) + " 00:00:00",
        new Date().toLocaleString().substr(0, 10) + " 23:59:59",
      ];
      this.sliderJudge = false;
      this.valueHour = "";
      this.valueMin = "";
    },
    // 进度条倍速
    speedFun() {
      this.speedTimer++;
      console.log(this.speedTimer, "timer");
      this.speed = this.speedArr[this.speedTimer];
      if (this.speedTimer > 3) {
        this.sliderJudge = false;
        clearInterval(this.timer);
        this.speedTimer = 0;
        this.speed = 1;
      }
      console.log(this.speed, "进度条倍速");
    },
    // 进度条定时器
    sliderTimer() {
      this.sliderJudge = !this.sliderJudge;
      if (this.judge) {
        console.log("运维模块");
        if (this.sliderJudge) {
          this.timer = setInterval(() => {
            if (this.value3 >= this.msg) {
              this.$emit("stepChange", this.msg - 1);
              clearInterval(this.timer);
              this.sliderJudge = false;
              console.log("超过值，关闭定时器");
            } else {
              this.$emit("stepChange", this.value3);
              console.log(this.value3, ">>>>>>value3");
              this.value3 += this.speed;
            }
          }, 1000);
        } else {
          clearInterval(this.timer);
        }
      } else {
        console.log("非运维模块");
        if (this.sliderJudge) {
          this.timer = setInterval(() => {
            if (this.value3 < this.per) {
              if (this.value3 > this.msg) {
                this.sliderJudge = false;
                clearInterval(this.timer);
                alert("已无数据");
              }
              this.value3++;
              this.$emit("stepChange", this.value3);
            } else {
              clearInterval(this.timer);
              this.sliderJudge = false;
              console.log("超过值，关闭定时器");
            }
          }, 1000);
        } else {
          clearInterval(this.timer);
        }
      }
    },
    progress_add() {
      if (this.enable) {
        console.log("add");
        this.value3++;
        this.$emit("stepChange", this.value3);
      }
    },
    progress_minus() {
      if (this.enable) {
        console.log("minus");
        if (this.value3 > 0) {
          this.value3--;
          this.$emit("stepChange", this.value3);
        }
      }
    },
    queryTime() {
      if (this.enable) {
        this.queryBtn = true;
        console.log("queryTime");
        let time_begin = Date.parse(
          this.value_time.toLocaleString().split(",")[0]
        ); //开始时间
        let time_end = Date.parse(
          this.value_time.toLocaleString().split(",")[1]
        ); //结束时间
        let gap = time_end - time_begin; //一段时间
        let date_end = jutils
          .formatDate(new Date(time_end), "YYYY-MM-DD HH:ii:ss")
          .substr(0, 16);
        let date_start = jutils
          .formatDate(new Date(time_begin), "YYYY-MM-DD HH:ii:ss")
          .substr(0, 16);
        this.time = [date_start, date_end]; //请求参数时间

        if (this.judge) {
          this.per = this.msg;
          // this.valueHour = 1;
          if (gap > 24 * 3600 * 1000) {
            alert("查询时间不能大于1天");
          } else {

            console.log(this.judge,this.valueHour,"valueHour");

            this.$emit("dataChange", {
              time: this.time,
              hour: this.valueHour,
              min: this.valueMin,
            });
            this.value3 = 0;
          }
        } else {
          if (this.valueHour || this.valueMin) {
            //间隔
            let valueHour = this.valueHour * 3600 * 1000;
            let valueMin = this.valueMin * 60 * 1000;
            let total = valueHour + valueMin;
            this.per = gap / total;
            this.per = Math.ceil(this.per);
            this.max = this.per;
          }
          let valueHour = this.valueHour * 3600 * 1000;
          let valueMin = this.valueMin * 1000;
          let total = valueHour + valueMin;
          if (gap > 24 * 3600 * 1000) {
            alert("查询时间不能大于1天");
          } else {
            if (total < 30000) {
              alert("时间间隔不能小于30分钟");
            } else {
              this.$emit("dataChange", {
                time: this.time,
                hour: this.valueHour,
                min: this.valueMin,
              });
              this.value3 = 0;
            }
          }
        }
      }
    },

    slider(value3) {
      if (this.enable) {
        console.log(value3);
        this.$emit("stepChange", this.value3);
      }
    },
  },
};
</script>

<style scoped>
.fastForward {
  color: #000;
  background-color: #1f8f8e;
  position: fixed;
  top: 38px;
  left: 744px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  user-select: none;
  opacity: 0.5;
  font-size: 16px;
}

.times {
  width: 754px;
  height: 69px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: #0b484d;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.timesTop {
  border: 1px solid #1f8f8e;
  border-top: none;
  width: 737px;
  height: 33px;
  display: flex;
}

/* .bigDot {
  height: 16px;
  width: 16px;
  border-left: 1px solid #1f8f8e;
  border-bottom: 1px solid #1f8f8e;
  margin-left: 8px;
  margin-top: 11px;
} */
.smallDot {
  width: 12px;
  height: 12px;
  background-color: #1f8f8e;
  float: right;
}
.dot {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px;
}
.slider {
  border: 2px solid #8bdcd9;
  margin-top: 7px;
}
.slider,
/* .block, */
.el-slider {
  width: 600px;
  height: 20px;
}

.quart {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 48px;
  background-color: #1f8f8e;
  text-align: center;
  line-height: 21px;
  opacity: 0.5;
  border-radius: 50%;
}
/* .timesTop::after {
  content: "";
  display: inline-block;
  height: 0px;
  width: 0px;
  border-top: 0px solid #0b484d;
  border-left: 9px solid #0b484d;
  border-right: 5px solid #1f8f8e;
  border-bottom: 9px solid #1f8f8e;
  position: absolute;
  top: 5px;
  right: 24px;
  background-color: #1f8f8e;
} */

/* 时间选择器 */
.timesBottom {
  height: 48px;
  border-top: none;
  width: 738px;
  display: flex;
  margin-top: 6px;
  justify-content: space-between;
}

.queryBtn {
  width: 76px;
  height: 24px;
  line-height: 24px;
  border: none;
  background-color: #3f767d;
  color: #fff;
  font-size: 16px;
}
.el-select-dropdown__item {
  color: #fff;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none;
}
</style>
