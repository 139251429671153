import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Slider,DatePicker,Select,Option,Button} from 'element-ui';
// import "element-ui/lib/theme-chalk/index.css"
// 引入echarts
import *as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(Slider).use(DatePicker).use(Select).use(Option).use(Button);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



